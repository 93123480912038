import { useCallback, useMemo } from "react";
import { useData } from "../../context/data";
import { useRouter } from "../../context/router";
import { Pages } from "../../types";
import { parseJwt } from "../../utils";

const useNextPage = () => {
    const {
        data: {
            onboardingSteps,
            onboardingMethod,
            devices,
            accessToken,
            debug,
            desktopTest
        },
    } = useData();
    const {
        state: { page },
        goto,
    } = useRouter();

    const decodedJwt: { exp: number; iat: number; payload: string } = useMemo(
        () => accessToken && !desktopTest && parseJwt(accessToken, debug),
        [accessToken, debug]
    );

    const filteredSteps = useMemo(
        () =>
            onboardingSteps.filter((step) => {
                if (!debug && step.page === Pages.MEDIA_SUMMARY_PAGE) {
                    return false;
                }
                if (onboardingMethod === "document") {
                    return step.onboardingMethod !== "liveness";
                }
                if (onboardingMethod === "liveness") {
                    return step.onboardingMethod !== "document";
                }
                return true;
            }),
        [onboardingMethod, onboardingSteps]
    );

    // Do not check the settings again if we have already done so before
    if (Boolean(devices.length) && page !== Pages.CHECK_PHONE_SETTINGS_PAGE) {
        const hasPhoneSettingsInArray = filteredSteps.some(
            (step) => step.page === Pages.CHECK_PHONE_SETTINGS_PAGE
        );
        if (hasPhoneSettingsInArray) {
            const phoneSettingsPageIndex = filteredSteps.findIndex(
                (step) => step.page === Pages.CHECK_PHONE_SETTINGS_PAGE
            );
            filteredSteps.splice(phoneSettingsPageIndex, 1);
        }
    }

    const next = useCallback(() => {
        if (decodedJwt?.exp * 1000 < Date.now()) {
            goto({ page: Pages.SESSION_EXPIRED_PAGE });
        } else {
            const currentIndexPage = filteredSteps.findIndex(
                (step) => step.page === page
            );
            const updatedIndex = currentIndexPage + 1;
            if (updatedIndex >= 0 && updatedIndex < filteredSteps.length) {
                goto({ page: filteredSteps[updatedIndex].page });
            } else {
                goto({ page: Pages.LETS_VERIFY_PAGE });
            }
        }
    }, [decodedJwt, filteredSteps, page]);

    const prev = useCallback(() => {
        if (decodedJwt?.exp * 1000 < Date.now()) {
            goto({ page: Pages.SESSION_EXPIRED_PAGE });
        } else {
            const currentIndexPage = filteredSteps.findIndex(
                (step) => step.page === page
            );
            const updatedIndex = currentIndexPage - 1;
            if (updatedIndex >= 0 && updatedIndex < filteredSteps.length) {
                goto({ page: filteredSteps[updatedIndex].page });
            } else {
                goto({ page: Pages.LETS_VERIFY_PAGE });
            }
        }
    }, [decodedJwt, filteredSteps, page]);

    return { next, prev };
};

export default useNextPage;
