import { useMemo, useState } from "react";
import { Button, WithBackNavigation } from "../../components";
import { useData } from "../../context/data";
import { useMultilangue } from "../../context/multilangue";
import { DocumentsType } from "../../types";
import { filter_document, manageCountryCode } from "./helper";
import { updateDocumentType } from "../../context/data/action";
import {
    CheckCircleFill,
    DriverLicense,
    IdCard,
    Passport,
    ResidencyPermit,
    SocialSecurityCard,
} from "../../icons";
import { useNextPage } from "../../hooks";

const DocCard = ({
    onClick,
    selectedDoc,
    doc,
}: {
    onClick: React.MouseEventHandler<HTMLDivElement>;
    selectedDoc: DocumentsType | null;
    doc: DocumentsType;
}) => {
    const {
        t: {
            words: { common },
        },
    } = useMultilangue();

    const icons = {
        passport: <Passport />,
        id_card: <IdCard />,
        driver_permit: <DriverLicense />,
        residency_permit: <ResidencyPermit />,
        social_security_card: <SocialSecurityCard />,
    };

    return (
        <div
            onClick={onClick}
            className={`flex w-[95%] h-16 min-h-[4rem] ${
                selectedDoc === doc
                    ? "ring-2 ring-blue-600"
                    : "ring-1 ring-gray-300"
            } rounded-lg cursor-pointer`}
        >
            <div className="flex-center flex-1 max-h-full">{icons[doc]}</div>
            <div className="flex items-center justify-start flex-[2] text-subtitle font-medium">
                {common[doc]}
            </div>
            <div className="flex items-center justify-end flex-1 pr-2">
                {selectedDoc === doc && <CheckCircleFill />}
            </div>
        </div>
    );
};

const SelectDocument = () => {
    const { next, prev } = useNextPage();
    const [document, setDocument] = useState<DocumentsType | null>(null);
    const {
        data: {
            scopes,
            data: { documentCountry },
        },
        dispatch,
    } = useData();
    const {
        t: {
            words: { select_document, common },
        },
    } = useMultilangue();

    const documents: DocumentsType[] = useMemo(
        () =>
            filter_document(
                scopes.find(
                    (scope) =>
                        scope.country_code ===
                        manageCountryCode(documentCountry!)
                )!,
                scopes
            ),
        [documentCountry, scopes]
    );

    return (
        <WithBackNavigation onClick={prev}>
            <div className="flex-center h-[20%] text-center">
                <h1 className="text-title">{select_document.title}</h1>
            </div>
            <div className="flex items-center flex-col gap-4 h-[70%] w-full md:w-1/2 py-1 overflow-auto">
                {documents.map((doc) => (
                    <DocCard
                        key={doc}
                        doc={doc}
                        selectedDoc={document}
                        onClick={() => setDocument(doc)}
                    />
                ))}
            </div>
            <Button
                text={common.continue}
                onClick={() => {
                    if (document) {
                        dispatch(
                            updateDocumentType({ documentType: document })
                        );
                        next();
                    }
                }}
                className="h-[10%]"
                active={Boolean(document)}
            />
        </WithBackNavigation>
    );
};

export default SelectDocument;
