const ResidencyPermit = ({ className }: { className?: string }) => (
    <div className={className}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="24"
            fill="currentColor"
            viewBox="0 0 39 24"
        >
            <g className="Layer" clipPath="url(#a)">
                <g className="m1E4j0.tif">
                    <g className="Group">
                        <path
                            className="fill-primary-active dark:fill-secondary-active"
                            d="m14.484 1.266 21.144.017c1.942 0 3.37 1.372 3.37 3.237.002 5.414.002 10.826 0 16.24 0 1.865-1.43 3.238-3.37 3.239H18.52l.03-1.254h17.013c1.3 0 2.134-.8 2.136-2.046.002-5.373.002-10.745 0-16.117 0-1.234-.833-2.044-2.106-2.044H15.319"
                        />
                        <path
                            className="fill-primary-active dark:fill-secondary-active"
                            d="M30.532 17.682h3.737c.314 0 .59.069.74.368a.604.604 0 0 1-.378.86 1.28 1.28 0 0 1-.36.047c-2.513.003-5.025.003-7.536.001-.49 0-.777-.22-.798-.593-.022-.419.263-.68.767-.682 1.143-.005 2.288-.001 3.431-.001h.398-.002Zm-.056-8.828c-1.287 0-2.572.002-3.859-.001-.345 0-.617-.207-.674-.49-.063-.315.095-.626.39-.718.142-.044.301-.043.454-.043 1.919-.002 3.838-.001 5.757-.001.592 0 1.184-.002 1.777 0 .47.003.76.251.755.63-.006.376-.307.622-.771.622h-3.829Zm.016 5.04c-1.296 0-2.593.003-3.888-.002-.337-.001-.576-.173-.652-.438a.608.608 0 0 1 .29-.714.844.844 0 0 1 .376-.095c2.583-.006 5.166-.005 7.748-.003.412 0 .705.264.71.62.005.36-.276.629-.694.63-1.296.006-2.593.002-3.888.002h-.002Z"
                        />
                    </g>
                </g>
                <g className="bHcWbD.tif">
                    <path
                        className="fill-primary-active dark:fill-secondary-active"
                        d="M2.614 7.626c-.551.385-1.049.74-1.556 1.082-.275.186-.56.174-.794-.002-.23-.176-.338-.463-.211-.712.076-.15.213-.289.356-.39C2.688 6.008 4.973 4.42 7.255 2.828 8.273 2.12 9.29 1.407 10.308.701c1.34-.931 2.873-.938 4.21-.008 3.303 2.298 6.6 4.601 9.902 6.9.244.17.444.36.412.674-.052.501-.608.73-1.061.43-.504-.335-.993-.687-1.55-1.074v.38c0 4.261.002 8.522 0 12.782 0 1.546-1.022 2.787-2.585 3.128-.277.06-.566.081-.851.082-4.249.005-8.498.006-12.747.003-1.665-.002-2.956-.945-3.33-2.434a3.456 3.456 0 0 1-.092-.818c-.006-4.251-.003-8.5-.003-12.752v-.367l.001-.001Zm1.3 6.263c-.001 2.268-.003 4.536-.001 6.802.001 1.23.813 2.027 2.074 2.029 4.287.003 8.576.003 12.864 0 1.246 0 2.069-.796 2.07-2.002.004-4.584.004-9.168-.006-13.753a.54.54 0 0 0-.198-.385 1354.24 1354.24 0 0 0-7.02-4.89c-.826-.572-1.761-.56-2.588.017C8.794 3.32 6.48 4.936 4.16 6.542c-.19.132-.252.268-.25.487.007 2.287.004 4.575.004 6.862v-.002Z"
                    />
                </g>
                <path
                    className="fill-primary-active dark:fill-secondary-active"
                    d="M12.425 15.175c-.838 0-1.677.026-2.513-.008-1.086-.042-2.11.758-2.083 2.029.007.364.006.729-.003 1.094-.01.396-.296.677-.66.665a.633.633 0 0 1-.631-.666c.014-.649-.035-1.313.098-1.94.31-1.461 1.616-2.431 3.214-2.449a205.29 205.29 0 0 1 5.146 0c1.932.029 3.304 1.406 3.306 3.274 0 .365.003.73 0 1.095-.005.4-.274.68-.646.685-.366.004-.639-.278-.651-.676-.015-.502.011-1.008-.05-1.504-.11-.905-.893-1.577-1.831-1.594-.899-.016-1.798-.003-2.695-.003v-.004l-.001.002Zm3.25-5.674c-.008 1.748-1.47 3.148-3.281 3.142-1.798-.006-3.254-1.437-3.238-3.183.016-1.731 1.486-3.14 3.266-3.132 1.804.008 3.261 1.43 3.253 3.174Zm-1.297-.013c0-1.047-.877-1.886-1.97-1.884-1.077.003-1.948.835-1.954 1.869-.006 1.038.88 1.906 1.953 1.913 1.069.007 1.97-.862 1.971-1.898Z"
                />
            </g>
            <defs>
                <clipPath id="a" className="a">
                    <path fill="#fff" d="M0 0h39v24H0z" />
                </clipPath>
            </defs>
        </svg>
    </div>
);

export default ResidencyPermit;
