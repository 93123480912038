const Logo = () => (
    <div className="flex items-center gap-1">
        <p className="text-subtitle">Powered by</p>
        <img
            src="https://shareidassets.s3.eu-west-1.amazonaws.com/Logo.svg"
            width={"155px"}
            alt="Logo"
        />
    </div>
);

export default Logo;
