import { MultilangueReducerAction, MultilangueReducerState } from "../../types";
import {
    en_words,
    fr_words,
    es_words,
    pl_words,
} from "./words/langs/";

export const initialState: MultilangueReducerState = {
    langue: "en",
    words: en_words,
};

export const reducer = (
    state: MultilangueReducerState,
    action: MultilangueReducerAction
): MultilangueReducerState => {
    switch (action.langue) {
        case "en": {
            return { langue: "en", words: en_words };
        }

        case "fr": {
            return { langue: "fr", words: fr_words };
        }

        case "es": {
            return { langue: "es", words: es_words };
        }

        case "pl": {
            return { langue: "pl", words: pl_words };
        }

        default:
            return state;
    }
};
