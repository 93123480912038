import { Pages, RouterReducerAction, RouterReducerState } from "../../types";
import {
    LetsVerify,
    SelectCountry,
    CheckPhoneSettings,
    ErrorPage,
    FinalPage,
    SendInformation,
    ScanLiveness,
    DocumentVerification,
    SessionExpiredPage,
    Loading,
    MediaSummary,
    WrongDevice,
    SelectDocument,
} from "../../pages";

export const initialState: RouterReducerState = {
    page: Pages.LOADING_PAGE,
    component: <Loading />,
};

export const reducer = (
    state: RouterReducerState,
    action: RouterReducerAction
): RouterReducerState => {
    switch (action.page) {
        case Pages.LOADING_PAGE: {
            return { component: <Loading />, page: Pages.LOADING_PAGE };
        }

        case Pages.LETS_VERIFY_PAGE: {
            return { component: <LetsVerify />, page: Pages.LETS_VERIFY_PAGE };
        }

        case Pages.SELECT_COUNTRY_PAGE: {
            return {
                component: <SelectCountry />,
                page: Pages.SELECT_COUNTRY_PAGE,
            };
        }

        case Pages.SELECT_DOCUMENT_PAGE: {
            return {
                component: <SelectDocument />,
                page: Pages.SELECT_DOCUMENT_PAGE,
            };
        }

        case Pages.CHECK_PHONE_SETTINGS_PAGE: {
            return {
                component: <CheckPhoneSettings />,
                page: Pages.CHECK_PHONE_SETTINGS_PAGE,
            };
        }

        case Pages.SCAN_DOCUMENT_PAGE: {
            return {
                component: <DocumentVerification />,
                page: Pages.SCAN_DOCUMENT_PAGE,
            };
        }

        case Pages.SCAN_LIVENESS_PAGE: {
            return {
                component: <ScanLiveness />,
                page: Pages.SCAN_LIVENESS_PAGE,
            };
        }

        case Pages.SEND_INFORMATION_PAGE: {
            return {
                component: <SendInformation />,
                page: Pages.SEND_INFORMATION_PAGE,
            };
        }

        case Pages.FINAL_PAGE: {
            return { component: <FinalPage />, page: Pages.FINAL_PAGE };
        }

        case Pages.MEDIA_SUMMARY_PAGE: {
            return {
                component: <MediaSummary />,
                page: Pages.MEDIA_SUMMARY_PAGE,
            };
        }

        case Pages.SESSION_EXPIRED_PAGE: {
            return {
                component: <SessionExpiredPage />,
                page: Pages.SESSION_EXPIRED_PAGE,
            };
        }

        case Pages.WRONG_DEVICE_PAGE: {
            return {
                component: <WrongDevice />,
                page: Pages.WRONG_DEVICE_PAGE,
            };
        }

        case Pages.ERROR_PAGE: {
            return {
                component: <ErrorPage {...action.props} />,
                page: Pages.ERROR_PAGE,
            };
        }

        default:
            return state;
    }
};
