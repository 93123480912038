import { useEffect } from "react";
import { InputSearch } from "../../components";
import ItemsBlock, { ItemBlockType } from "./ItemsBlock";

interface SearchWrapperProps {
    title: string;
    placeholder: string;
    selectedValue: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    items: ItemBlockType[];
    children: React.ReactNode;
    resetValue: boolean;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const SearchWrapper: React.FC<SearchWrapperProps> = ({
    title,
    placeholder,
    selectedValue,
    setValue,
    items,
    children,
    resetValue,
    search,
    setSearch
}) => {
    useEffect(() => {
        if (resetValue) {
            setSearch("");
        }
    }, [resetValue]);
    return (
        <>
            {!search && (
                <div className="flex-center h-[15%] text-center">
                    <h1 className="text-title">{title}</h1>
                </div>
            )}
            <InputSearch
                value={search}
                updateValue={setSearch}
                placeholder={placeholder}
            />
            {search ? (
                <ItemsBlock
                    className="h-[90%] mt-4"
                    items={items.filter((scope) =>
                        scope.name
                            ?.toLowerCase()
                            .startsWith(search.toLowerCase())
                    )}
                    selectedValue={selectedValue}
                    onClick={(scope) => {
                        setValue(scope);
                        setSearch("");
                    }}
                    hasFlag={false}
                />
            ) : (
                children
            )}
        </>
    );
};

export default SearchWrapper;
