import { memo } from "react";
import {
    FrontFaceFrame,
    LeftFaceFrame,
    RightFaceFrame,
} from "../../../../icons";

interface ContentProps {
    done: boolean;
    frame: "front" | "left" | "right";
}

const equalProps = (
    prevProps: Readonly<ContentProps>,
    nextprops: Readonly<ContentProps>
): boolean => {
    return (
        prevProps.done === nextprops.done
    );
};

const getFrame = (props: ContentProps): JSX.Element => {
    const { done, frame } = props;
    const color = done ? "green" : "white";
    switch (frame) {
        case "front":
            return (
                <FrontFaceFrame
                    color={color}
                    className="w-4/6 md:w-2/5 h-auto max-h-full"
                />
            );

        case "left":
            return (
                <LeftFaceFrame
                    color={color}
                    className="w-4/6 md:w-2/5 h-auto max-h-full"
                />
            );

        case "right":
            return (
                <RightFaceFrame
                    color={color}
                    className="w-4/6 md:w-2/5 h-auto max-h-full"
                />
            );

        default:
            return (
                <FrontFaceFrame
                    color={color}
                    className="w-4/6 md:w-2/5 h-auto max-h-full"
                />
            );
    }
};

const Content: React.FC<ContentProps> = ({ done, frame }) => {
    return (
        <div className="flex-center gap-2 h-[65%] md:h-4/5 w-full z-10">
            {getFrame({ done, frame })}
        </div>
    );
};

export default memo(Content, equalProps);
