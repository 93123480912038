/***** GLOBAL TYPES *****/

import Webcam from "react-webcam";
import { LogProps } from "../hooks/useLog";

declare global {
    interface Window {
        mobileAndTabletCheck: () => boolean;
        opera: string;
        MSStream: any;
    }
}

export type Language = "en" | "fr" | "es" | "pl";

export type OnboardingMethodType = "all" | "document" | "liveness";

export enum Pages {
    LOADING_PAGE = "LOADING",
    WRONG_DEVICE_PAGE = "WRONG_DEVICE_PAGE",
    LETS_VERIFY_PAGE = "LETS_VERIFY",
    SELECT_COUNTRY_PAGE = "SELECT_COUNTRY",
    SELECT_DOCUMENT_PAGE = "SELECT_DOCUMENT",
    CHECK_PHONE_SETTINGS_PAGE = "CHECK_PHONE_SETTINGS_PAGE",
    SCAN_DOCUMENT_PAGE = "SCAN_DOCUMENT_PAGE",
    SCAN_LIVENESS_PAGE = "SCAN_LIVENESS_PAGE",
    FINAL_PAGE = "FINAL_PAGE",
    SEND_INFORMATION_PAGE = "SEND_INFORMATION_PAGE",
    ERROR_PAGE = "ERROR_PAGE",
    SESSION_EXPIRED_PAGE = "SESSION_EXPIRED_PAGE",
    MEDIA_SUMMARY_PAGE = "MEDIA_SUMMARY_PAGE",
}

export enum ActionTypes {
    INITIALIZATION = "INITIALIZATION",
    CLEAR_DOCUMENTS = "CLEAR_DOCUMENTS",
    UPDATE_STATUS = "UPDATE_STATUS",
    UPDATE_DOCUMENT_TYPE = "UPDATE_DOCUMENT_TYPE",
    UPDATE_DOCUMENT_COUNTRY = "UPDATE_DOCUMENT_COUNTRY",
    ADD_FRONT_DOCUMENT = "ADD_FRONT_DOCUMENT",
    ADD_BACK_DOCUMENT = "ADD_BACK_DOCUMENT",
    ADD_STREAM_IMAGE = "ADD_STREAM_IMAGE",
    ADD_LIVENESS_VIDEO = "ADD_LIVENESS_VIDEO",
    ADD_DEVICES = "ADD_DEVICES",
    RESTART = "RESTART",
}

/***** CONTEXT TYPES *****/

///// ROUTER /////
export type RouterReducerState = {
    component: JSX.Element;
    page: Pages;
};

export type RouterReducerAction = {
    page: Pages;
    props?: Record<string, string | number | boolean | string[]>;
};

export interface RouterInterface {
    state: RouterReducerState;
    goto: React.Dispatch<RouterReducerAction>;
}

///// DATA /////
export type DataReducerState = {
    accessToken: string;
    id: string;
    correlationID: string;
    status: StatusType;
    devices: DeviceType[];
    scopes: ScopesType[];
    challengeOrders: ChallengeOrdersType[];
    data: DocumentDataType;
    language: Language;
    debug: boolean;
    onboardingMethod: OnboardingMethodType | null;
    onboardingSteps: onboardingStepsType[];
    desktopTest: boolean;
    theme: ThemeType;
    redirectUrl: string;
};

export type DataReducerAction = {
    type: ActionTypes;
    payload: Record<string, any>;
};

export interface DataInterface {
    data: DataReducerState;
    dispatch: React.Dispatch<DataReducerAction>;
}

export enum DocumentsType {
    ID_CARD = "id_card",
    PASSPORT = "passport",
    DRIVER_PERMIT = "driver_permit",
    RESIDENCY_PERMIT = "residency_permit",
}

export type ScopesType = {
    country: string;
    country_code: string;
    documents: DocumentsType[];
};

export enum ChallengeOrdersType {
    BLINK = "blink",
    SMILE = "smile",
    LEFT = "left",
    RIGHT = "right",
}

export type StatusType = "checks" | "ready_to_init" | "initiated" | "done" | "error";

export type DocumentDataType = {
    documentCountry: string | null;
    documentType: DocumentsType | null;
    frontDocumentFile: Blob | null;
    pictureFrontDocument: Blob | null;
    streamFrontImg: string | null;
    backDocumentFile: Blob | null;
    pictureBackDocument: Blob | null;
    streamBackImg: string | null;
    livenessFiles: LivenessFilesType;
};

export type LivenessFilesType = {
    applicantVideo1: Blob | null;
    applicantVideo2: Blob | null;
    applicantVideo3: Blob | null;
    applicantVideo4: Blob | null;
};

export type DeviceType = {
    deviceId: string;
    groupId: string;
    kind: "videoinput" | "audioinput" | "audiooutput";
    label: string;
};

export type CssColorType = {
    active: string;
    hover: string;
    disabled: string;
    bg: string;
};

type FontWeight =
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900";

export type CssTextType = {
    size: string;
    lineHeight: string;
    weight: FontWeight;
    primaryColor: string;
    secondaryColor: string;
};

export type ThemeType = {
    colors: {
        primary: CssColorType;
        secondary: CssColorType;
    };
    text: {
        title: CssTextType;
        subtitle: CssTextType;
        paragraph: CssTextType;
    };
};

export type onboardingStepsType = {
    page: Pages;
    onboardingMethod: OnboardingMethodType;
};

export type OnboardingDemandFillInfoResponseType = {
    status: "success" | "failed";
    message: string;
    payload: {
        informations: {
            challenge_order: ChallengeOrdersType[];
            scopes: ScopesType[];
            onboarding_method: OnboardingMethodType;
            props: {
                accessToken: string;
                debug: boolean;
                dark: boolean;
                theme: ThemeType;
                redirectUrl: string;
                correlationID: string;
            };
        };
    };
};

export type DocAICodes = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type FaceAICodes = 0 | 1 | 2 | 3 | 4 | 7;

///// MULTILANGUE /////
export type MultilangueReducerState = {
    langue: Language;
    words: WordsType;
};

export type MultilangueReducerAction = {
    langue: Language;
};

export interface MultilangueInterface {
    t: MultilangueReducerState;
    change_language: React.Dispatch<MultilangueReducerAction>;
}

export type WordsType = {
    common: {
        continue: string;
        retry: string;
        try_again: string;
        id_card: string;
        passport: string;
        residency_permit: string;
        driver_permit: string;
        cancel: string;
        close: string;
        quit: string;
    };
    lets_verify: {
        title: string;
        fast_verify_identity_title: string,
        fast_verify_identity_desc: string,
        safe_secure_title: string,
        safe_secure_desc: string,
        terms: string;
    };
    select_country: {
        title: string;
        select_state: string;
        placeholder_country: string;
        placeholder_state: string;
        suggested_countries: string;
        countries_a_to_z: string;
        states_a_to_z: string;
        countries: Record<string, string>;
    };
    select_document: {
        title: string;
    };
    check_phone_settings: {
        title: string;
        keep_app_opened: string;
        check_internet: string;
        no_internet: string;
        no_internet_text: string;
        slow_internet: string;
        slow_internet_text: string;
        check_camera: string;
        no_camera: string;
    };
    scan_document: {
        no_webcam_detected: string;
        introduction: {
            doc_front: string;
            doc_back: string;
            show_doc_in_front_of_camera: string;
            move_doc_as_shown: string;
            start_scanning: string;
        };
        capture: {
            doc_far: string;
            doc_close: string;
            doc_blurry: string;
            doc_dark: string;
            doc_bright: string;
            wrong_doc: string;
            timeout_exceeded: string;
            start_moving: string;
            keep_moving: string;
            keep_document: string;
            scanning_complete: string;
            loading: string;
        };
    };
    scan_liveness: {
        title: string;
        video_selfie: string;
        lighted_place: string;
        blink_title: string;
        smile_title: string;
        right_title: string;
        left_title: string;
        errors: {
            face_to_far: string;
            face_too_close: string;
            look_towards: string;
            no_face: string;
            loading: string;
            fail_challenge: string;
        };
    };
    send_information: {
        sending: string;
    };
    final_page: {
        thanks: string;
        success_submit: string;
        verif_delay: string;
        notification: string;
        back_to_website: string;
    };
    error_page: {
        title: string;
        request: {
            slow_network_connexion: string;
            error_400: string;
            error_401: string;
            error_403: string;
            error_404: string;
            error_415: string;
            error_422: string;
            error_500: string;
            unknow: string;
        };
        errors: {
            onboarding_id_error: string;
            access_token_error: string;
            mobile_only: string;
            session_expired: string;
            session_expired_retry: string;
        };
    };
};

/***** COMPONENTS TYPES *****/

///// WITH BACK NAVIGATION /////
export type WithBackNavigationType = {
    children: React.ReactNode;
    onClick: React.DOMAttributes<HTMLDivElement>["onClick"];
};

/***** HOOKS TYPES *****/

///// USE DOCUMENT DETECTION /////
export type DocumentDetectionType = {
    webcamRef: React.MutableRefObject<Webcam> | null;
    documentConfig: {
        label: string;
        isFront: boolean;
        country: string;
    };
    hasTimeout: boolean;
    delay: number;
    setDocCode: React.Dispatch<React.SetStateAction<DocAICodes>>;
    setDocPicture: React.Dispatch<React.SetStateAction<Blob | null>>;
    setStep: React.Dispatch<React.SetStateAction<number>>;
};

export type FormDataType = {
    image: string;
    label: string;
    front: boolean;
    country: string;
};

export type DocumentDetectionRequestProps = {
    formData: FormDataType;
    accessToken: string;
    correlationID: string;
    goto: React.Dispatch<RouterReducerAction>;
    setDocCode: React.Dispatch<React.SetStateAction<DocAICodes>>;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    dispatch: React.Dispatch<DataReducerAction>;
    logRequest: (logProps: LogProps) => Promise<void>;
};

export type DocumentDetectionResponseType = {
    doc_verified: boolean;
    code: DocAICodes;
};

export type DocumentDetectionReturnedType = {
    verified: boolean;
};

export type DocumentRatioType = {
    width: number;
    height: number;
    margin_width: number;
    margin_height: number;
    w_h_ratio: number;
};

///// USE LIVENESS DETECTION /////
export type RequestResponseType = {
    user_has_blinked?: boolean;
    user_has_smiled?: boolean;
    turn?: "head_left" | "head_right";
    code: FaceAICodes;
};

export type RequestReturnType = {
    response: boolean;
};

export type LoadModelType = {
    goto: React.Dispatch<RouterReducerAction>;
    error_page: WordsType["error_page"];
    debug: DataReducerState["debug"];
};

export type LivenessRequestDetectionType = {
    data: Record<string, any>;
    accessToken: string;
    correlationID: string;
    goto: React.Dispatch<RouterReducerAction>;
    challenge: ChallengeOrdersType;
    setCode?: React.Dispatch<React.SetStateAction<FaceAICodes>>;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    dispatch: React.Dispatch<DataReducerAction>;
    logRequest: (logProps: LogProps) => Promise<void>;
};

export type UseLivenessDetectionType = {
    webcamRef: React.MutableRefObject<Webcam> | null;
    hasTimeout: boolean;
    delay: number;
    challenge: ChallengeOrdersType;
    setCode?: React.Dispatch<React.SetStateAction<FaceAICodes>>;
    setStep: React.Dispatch<React.SetStateAction<number>>
};

///// USE TIMER /////
export type TimerResponseType = {
    timer: number | null;
    start: () => void;
    stop: () => void;
};

///// USE TORCH LIGHT /////
export type UseTorchLightHook = (
    /**
     * Stream associated to the camera that contains flash
     */
    stream: MediaStream | null,
    /**
     * Options to customize the hook behaviour
     */
    options?: UseTorchLightHookOptions
) => UseTorchLightHookReturnType;

export type UseTorchLightHookReturnType = [
    () => Promise<void>,
    () => Promise<void>,
    boolean,
    boolean
];

export type UseTorchLightHookOptions = {
    /**
     * Enables debug mode
     */
    debug: boolean;
    /**
     * Quantify vibration when flash is on and off
     */
    vibrate: number;
    /**
     * Error callback, gets fired if applying media constraints results in error
     */
    onError?: (err: Error) => void;
    /**
     * Success callback, gets fired if applying media constraints results successfully
     */
    onSuccess?: (props: UseTorchLightHookOnSuccessProps) => void;
};

export type UseTorchLightHookOnSuccessProps = {
    on?: boolean;
    stream?: MediaStream;
    track?: MediaStreamTrack;
};

/***** PAGES TYPES *****/

///// SCAN DOCUMENT /////
export interface CaptureDocumentType {
    side: "front" | "back";
    next: () => void;
    deviceId: string;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

///// SCAN LIVENESS /////

export interface ChallengeComponentProps {
    next: () => void;
    index: number;
    deviceId: string;
    setStep: React.Dispatch<React.SetStateAction<number>>
}
