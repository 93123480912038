const Camera = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="61"
                height="60"
                fill="currentColor"
                viewBox="0 0 61 60"
            >
                <g className="Camera">
                    <g className="Group 17087">
                        <g className="Story">
                            <g className="Group 8698-2">
                                <g className="Path 19525-2">
                                    <path
                                        d="M16.358 46.535a6.308 6.308 0 0 1-6.208-6.327V23.243a6.281 6.281 0 0 1 6.22-6.305h3.317l.615-1.638a4.962 4.962 0 0 1 1.877-2.304 4.72 4.72 0 0 1 2.736-.913h9.736a4.818 4.818 0 0 1 2.832.967 4.867 4.867 0 0 1 1.79 2.214l.631 1.678h3.248a6.051 6.051 0 0 1 4.447 1.848 6.144 6.144 0 0 1 1.846 4.48v16.965a6.312 6.312 0 0 1-6.305 6.304H16.358v-.004Zm.029-26.697a3.38 3.38 0 0 0-3.337 3.377V40.23a3.244 3.244 0 0 0 .982 2.39 3.345 3.345 0 0 0 2.398 1.014h26.72a3.408 3.408 0 0 0 3.405-3.404V23.24a3.243 3.243 0 0 0-.983-2.39l-.03-.033a3.495 3.495 0 0 0-2.367-.982h-5.282l-1.318-3.52a2.38 2.38 0 0 0-1.951-1.334H24.94a1.907 1.907 0 0 0-1.094.386c-.378.234-.673.58-.845.99l-1.303 3.48h-5.311Z"
                                        className="fill-primary-active dark:fill-secondary-active"
                                    />
                                </g>
                                <g className="Path 19526-2">
                                    <path
                                        d="M29.922 41.685h-.257a9.638 9.638 0 0 1-6.887-2.939 9.945 9.945 0 0 1 7.034-16.957 9.937 9.937 0 0 1 7.024 16.967 9.666 9.666 0 0 1-6.914 2.929Zm-.131-2.9a6.527 6.527 0 0 0 4.97-2.052l.02-.02a7.035 7.035 0 0 0-4.965-12.022 7.044 7.044 0 0 0-4.969 12.025 6.574 6.574 0 0 0 4.944 2.067v.002Z"
                                        className="fill-primary-active dark:fill-secondary-active"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default Camera;
