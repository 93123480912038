import { createContext, useContext, useReducer } from "react";
import { MultilangueInterface } from "../../types";
import { initialState, reducer } from "./reducer";

const MultilangueContext = createContext<MultilangueInterface>({
    t: initialState,
    change_language: () => {},
});

const MultilangueProvider = ({ children }: { children: React.ReactNode; }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { t: state, change_language: dispatch };

    return (
        <MultilangueContext.Provider value={value}>
            {children}
        </MultilangueContext.Provider>
    );
};

const useMultilangue = (): MultilangueInterface => {
    const context = useContext<MultilangueInterface>(MultilangueContext);
    if (context === undefined) {
        throw new Error("useRouter must be used within a MultilangueProvider");
    }
    return context;
};

export { MultilangueProvider, useMultilangue };
