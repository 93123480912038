import { useEffect, useState } from "react";
import { useData } from "../../../../context/data";
import { addLivenessVideo } from "../../../../context/data/action";
import {
    useRecording,
    useLivenessDetection,
    useTimeout,
} from "../../../../hooks";
import {
    FaceAICodes,
    ChallengeComponentProps,
    ChallengeOrdersType,
    Pages,
} from "../../../../types";
import { isTypeSupported } from "../../../../utils";
import RightArrowsAnimation from "../../../../icons/Animations/ArrowsRight";
import { useMultilangue } from "../../../../context/multilangue";
import { Content, Footer, Header } from "../Structure";
import { HeadRight } from "../../../../icons";
import Webcam from "react-webcam";
import { supportFormatWebp } from "../../../../utils";
import { Container } from "../../../../components";
import { handleHeaderTitle } from "../Structure/Header";
import FailCard from "../Structure/FailCard";
import { useRouter } from "../../../../context/router";

const Right: React.FC<ChallengeComponentProps> = ({
    next,
    index,
    setStep,
    deviceId,
}): JSX.Element => {
    const { dispatch } = useData();
    const { goto } = useRouter();
    const {
        t: {
            words: {
                scan_liveness: { right_title, errors },
            },
        },
    } = useMultilangue();
    const {
        webcamRef,
        hasTimeout,
        aiTimer,
        setRecording,
        callbackEndTimeout,
        webcamStarted,
        timeoutTimer,
        nextPageTimer,
        handleStopCapture,
        recordingTimer,
        done,
        recordedChunks,
        setNextPageTimer,
        stopTimeoutTimer,
        setWebcamStarted,
        startTimeoutTimer,
        startAiTimer,
        stopAiTimer,
        setHasTimeout,
    } = useRecording({ step: "liveness", delay: 100 });
    const [code, setCode] = useState<FaceAICodes>(0);

    const videoConstraints: MediaTrackConstraints = {
        ...(deviceId ? { deviceId } : { facingMode: "user" }),
        height: 720,
        width: 1280,
        focusMode: { exact: "continuous" },
        frameRate: { ideal: 30, min: 25, max: 30 },
    };

    // Challenge correctly detected
    const detected = useLivenessDetection({
        webcamRef,
        hasTimeout,
        delay: aiTimer!,
        challenge: ChallengeOrdersType.RIGHT,
        setCode,
        setStep,
    });

    // Stop the timer when user challenge is detected and start recording the video
    useEffect(() => {
        if (detected && !hasTimeout) {
            setRecording(true);
            stopAiTimer();
            stopTimeoutTimer();
        }
    }, [detected, hasTimeout]);

    // reset the timer until the challenge is successful
    useTimeout(callbackEndTimeout, webcamStarted ? timeoutTimer : null);
    // timer before going to the next page
    useTimeout(next, nextPageTimer);
    // timer which represents the recording time of the video
    useTimeout(handleStopCapture, recordingTimer);

    // Waiting for the video to be recorded to create a Blob with the recorded chunks and set it to the store
    useEffect(() => {
        if (done) {
            if (recordedChunks.length) {
                const mimeType = isTypeSupported();
                const blob = new Blob(recordedChunks, {
                    type: mimeType || recordedChunks[0].type,
                });
                dispatch(addLivenessVideo({ file: blob, index }));
                setNextPageTimer(2000);
            } else {
                goto({
                    page: Pages.ERROR_PAGE,
                    props: {
                        message: "VideoRecordingError",
                        code: 702,
                        description: [
                            "Something went wrong while recording the video.",
                        ],
                    },
                });
            }
        }
    }, [done, recordedChunks]);

    return (
        <Container className={"h-full"}>
            <Webcam
                className="absolute inset-0 object-cover w-full h-full"
                ref={webcamRef}
                screenshotFormat={
                    supportFormatWebp() ? "image/webp" : "image/jpeg"
                }
                onUserMedia={() => setWebcamStarted(true)}
                mirrored={true}
                videoConstraints={videoConstraints}
                forceScreenshotSourceSize={true}
                screenshotQuality={1}
            />
            <Header
                title={handleHeaderTitle(right_title, errors, code)}
                icon={<HeadRight />}
                display={Boolean(!done && !hasTimeout)}
                code={code}
            />
            {hasTimeout ? (
                <FailCard
                    retryFn={() => {
                        startTimeoutTimer();
                        startAiTimer();
                        setHasTimeout(false);
                        setCode(0);
                    }}
                    cancelFn={() => setStep(0)}
                />
            ) : (
                <Content done={done} frame={"right"} />
            )}
            <Footer
                done={done}
                hasTimeout={hasTimeout}
                animation={RightArrowsAnimation()}
                width={100}
            />
        </Container>
    );
};

export default Right;
