import enWords from "./en_words.json";
import frWords from "./fr_words.json";
import esWords from "./es_words.json";
import plWords from "./pl_words.json";

export const en_words = {
    ...enWords,
};

export const fr_words = {
    ...frWords,
};

export const es_words = {
    ...esWords,
};

export const pl_words = {
    ...plWords,
}
