const Search = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                viewBox="0 0 22 22"
            >
                <g className="Icon_Search">
                    <g className="Group 18582">
                        <path
                            fillRule="evenodd"
                            d="M4.767 9.298a4.536 4.536 0 0 1 4.542-4.531c2.51 0 4.542 2.03 4.542 4.53a4.537 4.537 0 0 1-4.542 4.532 4.537 4.537 0 0 1-4.542-4.531Zm4.542-6.365c-3.52 0-6.376 2.848-6.376 6.365 0 3.516 2.857 6.364 6.376 6.364a6.355 6.355 0 0 0 3.796-1.251l4.396 4.386a.919.919 0 0 0 1.298 0 .914.914 0 0 0 0-1.295l-4.392-4.382a6.328 6.328 0 0 0 1.277-3.822c0-3.517-2.856-6.365-6.375-6.365Z"
                            className="fill-secondary-bg dark:fill-primary-bg"
                            clipRule="evenodd"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default Search;
