import React, { useEffect } from "react";
import { Button, Container } from "../../components";
import { ErrorCircle } from "../../icons";
import { useMultilangue } from "../../context/multilangue";
import { useData } from "../../context/data";
import axios from "axios";
import config from "../../config.json";
import { useLog } from "../../hooks";
import { LogLevels } from "../../hooks/useLog";

interface ErrorProps {
    message?: string;
    code?: number;
    description?: string[];
    displayMsg?: boolean;
}

const ErrorPage: React.FC<ErrorProps> = ({
    message,
    code,
    description,
    displayMsg = false,
}) => {
    const {
        data: { debug, id, accessToken, redirectUrl, correlationID },
    } = useData();
    const {
        t: {
            words: { error_page, common },
        },
    } = useMultilangue();
    const { logRequest } = useLog();

    useEffect(() => {
        const updateOnboardingStatus = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const onboardingDemandId = queryParams.get("onboarding_demand_id");

            try {
                await axios.request({
                    baseURL: config.VAR_SHAREID_API_URL,
                    url: `/v1/data/sdk/onboarding_demand/${
                        id ? id : onboardingDemandId
                    }/failed`,
                    method: "post",
                    headers: {
                        "content-type": "application/json",
                        accept: "application/json",
                        // "X-CID": correlationID,
                        Authorization: `Bearer ${accessToken}`,
                    },
                    data: {
                        code: code || 666,
                        message: message || "UnknownError",
                        description: description || [
                            "Request failed because of an unknown error",
                        ],
                    },
                });
            } catch (error: any) {
                logRequest({
                    level: LogLevels.ERROR,
                    payload: {
                        message:
                            "The /failed request, used to warn the desktop SDK that an error has occurred on the mobile part, has failed.",
                        id: id ?? onboardingDemandId,
                        error: error,
                    },
                });
            }
        };
        if (code) {
            updateOnboardingStatus();
        }
    }, []);

    return (
        <Container className={"h-full"}>
            <div className="flex items-end justify-center text-center text-title h-[20%]">
                <h3>{error_page.title}</h3>
            </div>
            <div className="flex-center h-2/5">
                <ErrorCircle />
            </div>
            <div className="block text-center text-subtitle h-[30%] whitespace-pre-line overflow-auto p-2">
                {(debug || displayMsg) && description && (
                    <>
                        {description.map((msg, i) => (
                            <li key={i}>{msg}</li>
                        ))}
                    </>
                )}
            </div>
            <Button
                text={common.close}
                active={Boolean(redirectUrl)}
                onClick={() =>
                    redirectUrl && window.location.replace(redirectUrl)
                }
                className="!w-fit !items-start"
            />
        </Container>
    );
};

export default ErrorPage;
