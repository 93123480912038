import { memo } from "react";
import { GreenCheckCircleFill } from "../../../../icons";
import Lottie from "lottie-react";

interface FooterProps {
    done: boolean;
    animation: unknown;
    hasTimeout: boolean;
    width: number;
}

const equalProps = (
    prevProps: Readonly<FooterProps>,
    nextprops: Readonly<FooterProps>
): boolean => {
    return (
        prevProps.done === nextprops.done &&
        prevProps.hasTimeout === nextprops.hasTimeout
    );
};

const Footer: React.FC<FooterProps> = ({
    done,
    animation,
    width,
    hasTimeout,
}) => {
    return (
        <div className="flex-center flex-col gap-2 h-1/5 md:h-[10%] z-20">
            <div className="flex-center flex-1">
                {done && <GreenCheckCircleFill />}
                {!done && !hasTimeout && (
                    <div className="flex-center">
                        <Lottie
                            animationData={animation}
                            loop
                            style={{ width }}
                        />
                    </div>
                )}
            </div>
            <div className="flex-center text-center flex-1">
                <p className="text-paragraph text-white">Powered by ShareID</p>
            </div>
        </div>
    );
};

export default memo(Footer, equalProps);
