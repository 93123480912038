const LeftFaceFrame = ({
    color,
    className,
}: {
    color: "white" | "blue" | "green";
    className?: string;
}) => {
    return (
        <svg
            width="201"
            height="275"
            className={className}
            viewBox="0 0 201 275"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M188.299 122.91C187.875 101.024 189.525 84.3461 182.101 66.7731C165.388 27.2083 131.214 2 96.945 2C62.676 2 28.5019 27.2083 11.7888 66.7731C4.36548 84.3461 2.46548 105.342 2.04205 127.228C2.04205 127.228 1.136 170.291 7.73575 196.712C14.9882 225.745 25.9477 264.257 44.006 270.906C56.7995 275.617 78.3051 270.906 78.3051 270.906C78.3051 270.906 104.87 267.844 126.403 252.063C148.378 235.958 164.25 195.927 164.25 195.927M188.299 122.91C188.299 122.91 196.903 127.41 198.549 134.294C200.52 142.538 195.395 164.521 195.395 164.521C195.395 164.521 188.482 191.125 174.894 194.356C170.402 195.425 164.25 195.927 164.25 195.927M188.299 122.91L181.253 125.102C177.899 126.146 175.535 129.148 175.264 132.659C174.704 139.898 173.611 152.394 172.135 161.381C169.892 175.035 164.25 195.927 164.25 195.927M83.2342 3.32826C83.2342 3.32826 43.4529 35.5855 38.9596 132.659C36.6561 182.424 51.2794 272.579 51.2794 272.579"
                stroke={color}
                strokeWidth="3"
                strokeLinejoin="round"
                strokeDasharray="2 6"
            />
        </svg>
    );
};

export default LeftFaceFrame;
