import React from "react";
import Button from "../Button";
import { useMultilangue } from "../../context/multilangue";

interface TimeoutCardContentProps {
    text: string;
    restartFn: () => void;
    cancelFn: () => void;
}

const TimeoutCardContent: React.FC<TimeoutCardContentProps> = ({
    text,
    restartFn,
    cancelFn,
}) => {
    const {
        t: {
            words: { common },
        },
    } = useMultilangue();
    return (
        <>
            <p className="text-white text-subtitle text-center">{text}</p>
            <div className="flex w-full justify-evenly items-center">
                <Button
                    onClick={restartFn}
                    text={common.try_again}
                    className="!w-28 md:!w-40 h-full"
                />
                <Button
                    onClick={cancelFn}
                    text={common.quit}
                    className="!w-28 md:!w-40 h-full"
                />
            </div>
        </>
    );
};

export default TimeoutCardContent;
