import { createContext, useContext, useReducer } from "react";
import { RouterInterface } from "../../types";
import { initialState, reducer } from "./reducer";

const RouterContext = createContext<RouterInterface>({
    state: initialState,
    goto: () => {},
});

const RouterProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, goto: dispatch };

    return (
        <RouterContext.Provider value={value}>
            {children}
        </RouterContext.Provider>
    );
};

const useRouter = (): RouterInterface => {
    const context = useContext<RouterInterface>(RouterContext);
    if (context === undefined) {
        throw new Error("useRouter must be used within a RouterProvider");
    }
    return context;
};

export { RouterProvider, useRouter };
