const CircleLoader = () => {
    return (
        <div className="animate-spin">
            <svg
                width="200"
                height="200"
                viewBox="0 0 200 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    opacity="0.4"
                    d="M64.2745 163.526C53.1832 157.289 43.9236 148.259 37.4102 137.342C37.1147 136.847 37.2864 136.208 37.7858 135.919V135.919C38.2852 135.631 38.9231 135.803 39.2188 136.298C45.5448 146.891 54.5333 155.652 65.2981 161.706C76.3665 167.931 88.9054 171.064 101.601 170.776C114.296 170.489 126.681 166.793 137.456 160.074C148.232 153.355 157.002 143.862 162.848 132.589C168.693 121.316 171.399 108.678 170.681 95.9996C169.964 83.3213 165.849 71.0694 158.769 60.5278C151.689 49.9862 141.904 41.5431 130.439 36.0832C119.288 30.7731 106.948 28.4749 94.6452 29.4082C94.0702 29.4518 93.5624 29.0293 93.5103 28.455V28.455C93.4582 27.8808 93.8816 27.3722 94.4566 27.3283C107.133 26.3614 119.848 28.7267 131.336 34.1979C143.14 39.8188 153.214 48.511 160.503 59.3635C167.792 70.216 172.027 82.8293 172.766 95.8816C173.505 108.934 170.719 121.945 164.701 133.55C158.683 145.156 149.654 154.929 138.561 161.846C127.468 168.763 114.718 172.568 101.648 172.864C88.5782 173.16 75.6694 169.934 64.2745 163.526Z"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeWidth="4"
                />
                <path
                    d="M163.663 62.2777C170.096 73.1333 173.64 85.4435 173.974 98.0354C174.003 99.1201 173.12 100 172.035 100V100C170.95 100 170.074 99.1201 170.043 98.0355C169.71 86.1484 166.356 74.5299 160.283 64.2807C153.876 53.4676 144.678 44.5769 133.654 38.5402C122.63 32.5036 110.185 29.5433 97.6235 29.9696C85.062 30.3958 72.8463 34.193 62.2567 40.9631C51.6671 47.7332 43.0935 57.227 37.4342 68.4496C31.7749 79.6722 29.2384 92.2104 30.0905 104.75C30.9425 117.29 35.1518 129.37 42.2771 139.724C49.0309 149.538 58.1688 157.458 68.8181 162.75C69.7898 163.233 70.2205 164.398 69.7651 165.383V165.383C69.3097 166.368 68.1407 166.799 67.1683 166.318C55.8795 160.729 46.1936 152.346 39.0403 141.951C31.5154 131.017 27.0701 118.26 26.1702 105.017C25.2704 91.7736 27.9492 78.5323 33.9258 66.6804C39.9024 54.8285 48.9569 44.8023 60.1403 37.6526C71.3237 30.5028 84.2243 26.4928 97.4903 26.0426C110.756 25.5924 123.899 28.7187 135.541 35.0938C147.184 41.469 156.897 50.8583 163.663 62.2777Z"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeWidth="4"
                />
            </svg>
        </div>
    );
};

export default CircleLoader;
