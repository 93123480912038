const Shield = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                fill="currentColor"
                viewBox="0 0 18 19"
            >
                <g>
                    <path
                        className="fill-primary-active dark:fill-secondary-active"
                        strokeLinejoin="round"
                        strokeWidth="1.154"
                        d="M1.836 4.66a.577.577 0 0 0-.336.546l.577-.022-.577.022v.011l.002.028.006.104a26.96 26.96 0 0 0 .17 1.755c.146 1.128.412 2.64.886 4.16.473 1.513 1.166 3.074 2.19 4.266C5.791 16.736 7.182 17.577 9 17.577c1.818 0 3.209-.84 4.246-2.047 1.024-1.192 1.717-2.753 2.19-4.266.474-1.52.74-3.032.886-4.16a26.8 26.8 0 0 0 .176-1.859l.001-.028v-.01l-.576-.023.577.022a.577.577 0 0 0-.336-.547L9.241 1.476a.577.577 0 0 0-.482 0L1.836 4.659Z"
                    />
                    <path
                        fill="#fff"
                        d="m6.158 8.96-1.205 1.132 2.892 3.446 5.185-5.625-.922-1.298-4.214 3.702L6.158 8.96Z"
                    />
                </g>
            </svg>
        </div>
    );
};

export default Shield;
