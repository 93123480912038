const IdCard = ({ className }: { className?: string }) => (
    <div className={className}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="22"
            fill="currentColor"
            viewBox="0 0 28 22"
        >
            <g className="Vector">
                <path
                    className="fill-primary-active dark:fill-secondary-active"
                    d="M13.973 22H3.035C1.267 22 0 20.679 0 18.835V3.165C0 1.333 1.266 0 3.008 0h21.984C26.724 0 28 1.33 28 3.135v15.728c0 1.805-1.276 3.135-3.007 3.136h-11.02V22ZM14 20.785c3.646 0 7.29.002 10.935 0 1.16 0 1.904-.774 1.905-1.981.002-5.203.002-10.405 0-15.608 0-1.195-.742-1.98-1.878-1.98H3.038c-1.135 0-1.877.786-1.878 1.981-.002 5.203-.002 10.405.002 15.608 0 .217.025.441.081.649.23.845.902 1.331 1.822 1.333H14v-.002Z"
                />
                <path
                    className="fill-primary-active dark:fill-secondary-active"
                    d="M8.759 13.452c-.747 0-1.495.025-2.241-.007-.97-.041-1.882.734-1.86 1.964.007.353.006.706-.002 1.06-.008.383-.263.655-.589.644-.324-.011-.57-.274-.563-.645.013-.628-.03-1.271.088-1.88.276-1.414 1.441-2.353 2.867-2.37 1.53-.018 3.061-.023 4.591 0 1.723.028 2.948 1.361 2.949 3.17 0 .354.003.707 0 1.06-.004.388-.244.66-.576.664-.327.005-.57-.269-.58-.654-.014-.486.01-.976-.044-1.457-.1-.876-.798-1.527-1.635-1.543-.802-.016-1.603-.004-2.404-.004v-.003l-.001.001Zm2.898-5.494C11.65 9.65 10.346 11.006 8.73 11c-1.603-.006-2.903-1.391-2.888-3.082.014-1.676 1.325-3.041 2.913-3.033 1.61.008 2.91 1.384 2.902 3.074v-.001ZM10.5 7.945c0-1.013-.782-1.826-1.757-1.824-.961.002-1.738.808-1.743 1.81-.006 1.005.785 1.845 1.742 1.852.953.007 1.757-.834 1.758-1.838Zm9.945 7.937h3.335c.28 0 .525.067.66.357.155.333 0 .721-.337.833a1.052 1.052 0 0 1-.322.046c-2.241.002-4.482.002-6.723 0-.437 0-.693-.213-.712-.574-.02-.406.235-.658.685-.66 1.02-.005 2.04-.002 3.06-.002h.355Zm-.048-8.55c-1.148 0-2.295.002-3.443-.001-.307 0-.55-.2-.601-.474-.056-.305.084-.606.348-.696.126-.042.269-.041.405-.041 1.712-.002 3.424-.001 5.136-.001.528 0 1.056-.003 1.585 0 .42.003.679.243.673.61-.005.364-.273.602-.687.602h-3.416v.001Zm.014 4.883c-1.156 0-2.313.002-3.469-.003-.3 0-.513-.167-.581-.423-.072-.27.026-.558.26-.693a.706.706 0 0 1 .335-.091 1990.6 1990.6 0 0 1 6.911-.003c.367 0 .63.255.633.6.005.35-.246.61-.618.61-1.157.006-2.314.003-3.47.003Z"
                />
            </g>
        </svg>
    </div>
);

export default IdCard;
