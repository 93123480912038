import { DocumentsType, ScopesType } from "../../types";

export const filter_document = (
    country: ScopesType,
    data: ScopesType[]
): DocumentsType[] => {
    if (country) {
        const res: ScopesType[] = data.filter(
            (datum: ScopesType) => datum.country_code === country.country_code
        );
        if (res.length) {
            return res[0].documents;
        }
    }

    return [];
};

export const manageCountryCode = (countryCode: string) => {
    if (
        countryCode.split("-").length > 1 &&
        countryCode.split("-")[0] === "us"
    ) {
        return "us";
    } else {
        return countryCode;
    }
};

export const UsStates = [
    { name: "Alabama", code: "us-al" },
    { name: "Alaska", code: "us-ak" },
    { name: "Arizona", code: "us-az" },
    { name: "Arkansas", code: "us-ar" },
    { name: "California", code: "us-ca" },
    { name: "Colorado", code: "us-co" },
    { name: "Connecticut", code: "us-ct" },
    { name: "Delaware", code: "us-de" },
    { name: "Florida", code: "us-fl" },
    { name: "Georgia", code: "us-ga" },
    { name: "Hawaii", code: "us-hi" },
    { name: "Idaho", code: "us-id" },
    { name: "Illinois", code: "us-il" },
    { name: "Indiana", code: "us-in" },
    { name: "Iowa", code: "us-ia" },
    { name: "Kansas", code: "us-ks" },
    { name: "Kentucky", code: "us-ky" },
    { name: "Louisiana", code: "us-la" },
    { name: "Maine", code: "us-me" },
    { name: "Maryland", code: "us-md" },
    { name: "Massachusetts", code: "us-ma" },
    { name: "Michigan", code: "us-mi" },
    { name: "Minnesota", code: "us-mn" },
    { name: "Mississippi", code: "us-ms" },
    { name: "Missouri", code: "us-mo" },
    { name: "Montana", code: "us-mt" },
    { name: "Nebraska", code: "us-ne" },
    { name: "Nevada", code: "us-nv" },
    { name: "New Hampshire", code: "us-nh" },
    { name: "New Jersey", code: "us-nj" },
    { name: "New Mexico", code: "us-nm" },
    { name: "New York", code: "us-ny" },
    { name: "North Carolina", code: "us-nc" },
    { name: "North Dakota", code: "us-nd" },
    { name: "Ohio", code: "us-oh" },
    { name: "Oklahoma", code: "us-ok" },
    { name: "Oregon", code: "us-or" },
    { name: "Pennsylvania", code: "us-pa" },
    { name: "Rhode Island", code: "us-ri" },
    { name: "South Carolina", code: "us-sc" },
    { name: "South Dakota", code: "us-sd" },
    { name: "Tennessee", code: "us-tn" },
    { name: "Texas", code: "us-tx" },
    { name: "Utah", code: "us-ut" },
    { name: "Vermont", code: "us-vt" },
    { name: "Virginia", code: "us-va" },
    { name: "Washington", code: "us-wa" },
    { name: "West Virginia", code: "us-wv" },
    { name: "Wisconsin", code: "us-wi" },
    { name: "Wyoming", code: "us-wy" },
];
