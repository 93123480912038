import { useEffect, useState } from "react";
import { useRouter } from "../../context/router";
import { ChallengeOrdersType, Pages } from "../../types";
import PresentationMessage from "./PresentationMessage";
import { useData } from "../../context/data";
import { useLog, useNextPage } from "../../hooks";
import { Blink, Left, Right, Smile } from "./CaptureLiveness/Challenges";
import { getVideoDeviceID } from "../../utils";
import { useMultilangue } from "../../context/multilangue";
import ErrorSettings from "../CheckPhoneSettings/ErrorSettings";
import { SlowWifi } from "../../icons";
import { updateStatus } from "../../context/data/action";
import { LogLevels } from "../../hooks/useLog";

const ScanLiveness = (): JSX.Element => {
    const { next, prev } = useNextPage();
    const { logRequest } = useLog();
    const { goto } = useRouter();
    const [step, setStep] = useState<number>(0);
    const {
        data: { challengeOrders, devices, desktopTest },
        dispatch,
    } = useData();
    const {
        t: {
            words: {
                check_phone_settings: { slow_internet, no_internet_text },
            },
        },
    } = useMultilangue();
    const [deviceId, setDeviceId] = useState("");
    const [steps, setSteps] = useState<
        { index: number; component: JSX.Element }[]
    >([
        {
            index: 0,
            component: (
                <PresentationMessage
                    next={() => setStep((previous) => previous + 1)}
                    prev={prev}
                />
            ),
        },
    ]);

    useEffect(() => {
        getVideoDeviceID({
            devices,
            side: "front",
            desktopTest,
            onError: () => {
                dispatch(updateStatus("error"));
                goto({
                    page: Pages.ERROR_PAGE,
                    props: {
                        message: "CameraSettingError",
                        code: 701,
                        description: [
                            "Something went wrong during the setting of camera or record buffer.",
                        ],
                    },
                });
                logRequest({
                    level: LogLevels.ERROR,
                    payload: { devices: devices },
                });
            },
            onSuccess: (id) => setDeviceId(id),
        });
    }, [devices, goto]);

    useEffect(() => {
        const blinkNbr = challengeOrders.indexOf(ChallengeOrdersType.BLINK);
        const smileNbr = challengeOrders.indexOf(ChallengeOrdersType.SMILE);
        const rightNbr = challengeOrders.indexOf(ChallengeOrdersType.RIGHT);
        const leftNbr = challengeOrders.indexOf(ChallengeOrdersType.LEFT);

        const handleNext = (componentIndex: number) => {
            if (componentIndex === 4) next();
            else setStep((previous) => previous + 1);
        };

        const newSteps = [
            {
                index: 0,
                component: (
                    <PresentationMessage
                        next={() => handleNext(0)}
                        prev={prev}
                    />
                ),
            },
            {
                index: blinkNbr + 1,
                component: (
                    <Blink
                        index={blinkNbr}
                        deviceId={deviceId}
                        next={() => handleNext(blinkNbr + 1)}
                        setStep={setStep}
                    />
                ),
            },
            {
                index: smileNbr + 1,
                component: (
                    <Smile
                        index={smileNbr}
                        deviceId={deviceId}
                        next={() => handleNext(smileNbr + 1)}
                        setStep={setStep}
                    />
                ),
            },
            {
                index: rightNbr + 1,
                component: (
                    <Right
                        index={rightNbr}
                        deviceId={deviceId}
                        next={() => handleNext(rightNbr + 1)}
                        setStep={setStep}
                    />
                ),
            },
            {
                index: leftNbr + 1,
                component: (
                    <Left
                        index={leftNbr}
                        deviceId={deviceId}
                        next={() => handleNext(leftNbr + 1)}
                        setStep={setStep}
                    />
                ),
            },
            {
                index: 5,
                component: (
                    <ErrorSettings
                        title={slow_internet}
                        icon={<SlowWifi />}
                        text={no_internet_text}
                        onClick={() => setStep(0)}
                    />
                ),
            },
        ];

        newSteps.sort((a, b) => a.index - b.index);
        setSteps(newSteps);
    }, [challengeOrders, deviceId]);

    return <>{steps[step].component}</>;
};

export default ScanLiveness;
