import { memo } from "react";
import { useMultilangue } from "../../../context/multilangue";
import { DocAICodes, DocumentsType } from "../../../types";
import { useData } from "../../../context/data";
import { HoldFrontID, PassportInHand } from "../../../icons";

interface DocCaptureHeaderProps {
    display: boolean;
    docCode: DocAICodes;
}

const DocCaptureHeader: React.FC<DocCaptureHeaderProps> = ({
    display,
    docCode,
}) => {
    const {
        t: {
            words: {
                scan_document: {
                    capture: {
                        keep_document,
                        doc_close,
                        doc_blurry,
                        doc_dark,
                        doc_bright,
                        doc_far,
                        loading,
                    },
                },
            },
        },
    } = useMultilangue();
    const {
        data: {
            data: { documentType },
        },
    } = useData();

    const isPassport = documentType === DocumentsType.PASSPORT;

    const getTitle = () => {
        const getTitleWithCode = (code: number) => {
            switch (code) {
                case 0:
                    return keep_document;
                case 1:
                    return doc_far;
                case 2:
                    return doc_close;
                case 3:
                    return doc_blurry;
                case 4:
                    return doc_dark;
                case 5:
                    return doc_bright;
                case 6:
                    return keep_document;
                case 7:
                    return loading;
                default:
                    return keep_document;
            }
        };

        const title = getTitleWithCode(docCode);
        return title.split(" - ");
    };

    const isCodeError = [1, 2, 3, 4, 5].includes(docCode);
    const displayIcon = Boolean(docCode === 0 || docCode === 6);

    return (
        <div
            className={`flex items-end justify-center w-full z-20 mb-3 ${
                isPassport ? "h-[10%]" : "h-[25%]"
            }`}
        >
            {display && (
                <div
                    className={`bg-primary-bg dark:bg-secondary-bg w-full md:w-96 min-h-[4rem] rounded-lg flex-center gap-2 p-2 ${
                        isCodeError && "ring ring-red-500"
                    } ${docCode === 7 && "ring ring-green-500"}`}
                >
                    {displayIcon && (
                        <div className="h-full">
                            {isPassport ? (
                                <PassportInHand />
                            ) : (
                                <HoldFrontID size="32" />
                            )}
                        </div>
                    )}
                    <div className={`h-full text-center`}>
                        {getTitle().map((part, index) => (
                            <p className="text-subtitle" key={index}>
                                {part}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(DocCaptureHeader);
