const log = (
    msg: string,
    color: "green" | "red" | "yellow" | "white",
    debug: boolean
) => {
    if (debug) {
        console.log(`%c${msg}`, `color:${color};font-weight:bold;`);
    }
};

export default log;
