import { useEffect, useRef } from "react";
import { useMultilangue } from "../../context/multilangue";
import { Check } from "../../icons";

export type ItemBlockType = {
    name: string;
    code: string;
};

const ItemBlock = ({
    flag,
    name,
    onClick,
    selected,
}: {
    flag?: string;
    name: string;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    selected?: boolean;
}) => {
    return (
        <div
            onClick={onClick}
            className={`flex gap-3 w-full h-7 ${selected && "selected"}`}
        >
            {flag && (
                <div className="flex-center flex-[0.3]">
                    <img
                        className="cursor-pointer"
                        src={flag}
                        alt="country_flag"
                    />
                </div>
            )}
            <div className="flex items-center justify-start flex-[2]">
                <p className="flex text-subtitle cursor-pointer">{name}</p>
            </div>
            <div className="flex-center flex-[0.3] w-full">
                {selected && <Check />}
            </div>
        </div>
    );
};

const ItemsBlock = ({
    className,
    title,
    items,
    selectedValue,
    onClick,
    hasFlag,
}: {
    className?: string;
    title?: string;
    items: ItemBlockType[];
    selectedValue: string;
    onClick: (code: string) => void;
    hasFlag: boolean;
}) => {
    const {
        t: {
            words: { select_country },
        },
    } = useMultilangue();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Scroll to the selected item when it changes
        if (containerRef.current) {
            const selectedItem =
                containerRef.current.querySelector(".selected");
            if (selectedItem) {
                selectedItem.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [selectedValue]);

    return (
        <div className={`${className} flex flex-col w-full gap-4`}>
            {title && (
                <p className="text-subtitle text-gray-400 uppercase">{title}</p>
            )}
            <div ref={containerRef} className="flex flex-col overflow-auto gap-4 w-full">
                {items
                    .map(({ name, code }) => (
                        <ItemBlock
                            flag={
                                hasFlag
                                    ? `/icons/flags/${code.toUpperCase()}.svg`
                                    : undefined
                            }
                            key={code}
                            name={
                                hasFlag
                                    ? (select_country.countries[code] as string)
                                    : name
                            }
                            selected={selectedValue === code}
                            onClick={() => onClick(code)}
                        />
                    ))}
            </div>
        </div>
    );
};

export default ItemsBlock;
