import { useEffect, useMemo, useState } from "react";
import { Button, Container } from "../../components";
import { useMultilangue } from "../../context/multilangue";
import { useNextPage } from "../../hooks";
import { CameraIcon, VideoIcon } from "../../icons";
import { useData } from "../../context/data";
import { ChallengeOrdersType, DocumentsType, Pages } from "../../types";
import ReactPlayer from "react-player";
import { useRouter } from "../../context/router";

type TabLabel = "photo" | "video";
type LivenessTabsType = "front" | "back" | "smile" | "blink" | "left" | "right";

const fetchInfoForVideo = async (videoUrl: string | null) => {
    if (!videoUrl) return null;
    return new Promise((resolve) => {
        const videoElement = document.createElement("video");
        videoElement.src = videoUrl;

        videoElement.addEventListener("loadedmetadata", () => {
            const videoInfo = {
                duration: videoElement.duration,
                width: videoElement.videoWidth,
                height: videoElement.videoHeight,
                playbackMetrics: videoElement.getVideoPlaybackQuality(),
            };

            resolve(videoInfo);
        });

        videoElement.addEventListener("error", (e) => {
            console.error("Error loading video:", e);
            resolve(null);
        });
    });
};

const MediaSummary = () => {
    const [selected, setSelected] = useState<TabLabel>("photo");
    const [openTab, setOpenTab] = useState<LivenessTabsType>("front");
    const [canContinue, setCanContinue] = useState(true);
    const [gotoPage, setGotoPage] = useState<Pages | null>(null);
    const { next } = useNextPage();
    const {
        data: {
            data: {
                pictureFrontDocument,
                pictureBackDocument,
                frontDocumentFile,
                backDocumentFile,
                documentType,
                streamFrontImg,
                streamBackImg,
                livenessFiles: {
                    applicantVideo1,
                    applicantVideo2,
                    applicantVideo3,
                    applicantVideo4,
                },
            },
            challengeOrders,
        },
    } = useData();
    const {
        t: {
            words: { common },
        },
    } = useMultilangue();
    const { goto } = useRouter();

    const frontDocImgUrl = useMemo(
        () => pictureFrontDocument && URL.createObjectURL(pictureFrontDocument),
        [pictureFrontDocument]
    );
    const backDocImgUrl = useMemo(
        () => pictureBackDocument && URL.createObjectURL(pictureBackDocument),
        [pictureBackDocument]
    );
    const frontDocVideoUrl = useMemo(
        () => frontDocumentFile && URL.createObjectURL(frontDocumentFile),
        [frontDocumentFile]
    );
    const backDocVideoUrl = useMemo(
        () => backDocumentFile && URL.createObjectURL(backDocumentFile),
        [backDocumentFile]
    );
    const applicantVideo1Url = useMemo(
        () => applicantVideo1 && URL.createObjectURL(applicantVideo1),
        [applicantVideo1]
    );
    const applicantVideo2Url = useMemo(
        () => applicantVideo2 && URL.createObjectURL(applicantVideo2),
        [applicantVideo2]
    );
    const applicantVideo3Url = useMemo(
        () => applicantVideo3 && URL.createObjectURL(applicantVideo3),
        [applicantVideo3]
    );
    const applicantVideo4Url = useMemo(
        () => applicantVideo4 && URL.createObjectURL(applicantVideo4),
        [applicantVideo4]
    );

    const desorderedChallenges = [
        applicantVideo1Url,
        applicantVideo2Url,
        applicantVideo3Url,
        applicantVideo4Url,
    ];

    useEffect(() => {
        return () => {
            frontDocImgUrl && URL.revokeObjectURL(frontDocImgUrl);
            backDocImgUrl && URL.revokeObjectURL(backDocImgUrl);
            frontDocVideoUrl && URL.revokeObjectURL(frontDocVideoUrl);
            backDocVideoUrl && URL.revokeObjectURL(backDocVideoUrl);
            applicantVideo1Url && URL.revokeObjectURL(applicantVideo1Url);
            applicantVideo2Url && URL.revokeObjectURL(applicantVideo2Url);
            applicantVideo3Url && URL.revokeObjectURL(applicantVideo3Url);
            applicantVideo4Url && URL.revokeObjectURL(applicantVideo4Url);
        };
    }, []);

    const tabs = useMemo(
        () => [
            {
                label: "photo",
                icon: (
                    <CameraIcon
                        className={`${
                            selected === "photo"
                                ? "stroke-primary-active dark:stroke-white"
                                : "stroke-black dark:stroke-white"
                        }`}
                    />
                ),
            },
            {
                label: "video",
                icon: (
                    <VideoIcon
                        className={`${
                            selected === "video"
                                ? "stroke-primary-active dark:stroke-white"
                                : "stroke-black dark:stroke-white"
                        }`}
                    />
                ),
            },
        ],
        [selected]
    );

    const livenessTabs = [
        { label: "front", onClick: () => setOpenTab("front") },
        { label: "back", onClick: () => setOpenTab("back") },
        { label: "smile", onClick: () => setOpenTab("smile") },
        { label: "blink", onClick: () => setOpenTab("blink") },
        { label: "left", onClick: () => setOpenTab("left") },
        { label: "right", onClick: () => setOpenTab("right") },
    ];

    const videoUrls: Record<LivenessTabsType, string | null | undefined> =
        useMemo(
            () => ({
                front: frontDocVideoUrl,
                back: backDocVideoUrl,
                smile: desorderedChallenges[
                    challengeOrders.findIndex(
                        (ele) => ele === ChallengeOrdersType.SMILE
                    )
                ],
                blink: desorderedChallenges[
                    challengeOrders.findIndex(
                        (ele) => ele === ChallengeOrdersType.BLINK
                    )
                ],
                left: desorderedChallenges[
                    challengeOrders.findIndex(
                        (ele) => ele === ChallengeOrdersType.LEFT
                    )
                ],
                right: desorderedChallenges[
                    challengeOrders.findIndex(
                        (ele) => ele === ChallengeOrdersType.RIGHT
                    )
                ],
            }),
            []
        );

    useEffect(() => {
        const fetchVideoInfo = async () => {
            const videoInfoPromises = [
                fetchInfoForVideo(frontDocVideoUrl),
                fetchInfoForVideo(backDocVideoUrl),
                fetchInfoForVideo(applicantVideo1Url),
                fetchInfoForVideo(applicantVideo2Url),
                fetchInfoForVideo(applicantVideo3Url),
                fetchInfoForVideo(applicantVideo4Url),
            ];

            try {
                const videoInfo = await Promise.all(videoInfoPromises);

                // Check for null values in videoInfo
                const nullVideoIndex = videoInfo.findIndex(
                    (info) => info === null
                );

                if (nullVideoIndex !== -1) {
                    if (nullVideoIndex < 2) {
                        // Doc video failed
                        // setGotoPage(Pages.SCAN_DOCUMENT_PAGE);
                        // setCanContinue(false);
                    } else {
                        // Liveness video failed
                        // setGotoPage(Pages.SCAN_LIVENESS_PAGE);
                        // setCanContinue(false);
                    }
                } else {
                    // All videos loaded successfully
                    setCanContinue(true);
                }
            } catch (error) {}

            const videoInfo = await Promise.all(videoInfoPromises);
            console.log("Video Info:", videoInfo);
        };

        fetchVideoInfo();
    }, []);

    const handleDownload = () => {
        const videoUrl = videoUrls[openTab];
        if (videoUrl) {
            const link = document.createElement("a");
            link.href = videoUrl;
            link.download = `${openTab}_video_${Date.now()}.mp4`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <Container className={"h-full"}>
            <div className="flex w-full h-[10%]">
                {tabs.map(({ label, icon }, i) => (
                    <div
                        key={i}
                        onClick={() => setSelected(label as TabLabel)}
                        className={`flex-center flex-1 gap-2 cursor-pointer p-3 text-subtitle rounded-xl ${
                            selected === label
                                ? "bg-gray-100 dark:bg-[#112057] text-primary-active"
                                : "bg-white dark:bg-[#0A1334]"
                        }`}
                    >
                        {icon}
                        {label}
                    </div>
                ))}
            </div>
            <div className="flex w-full h-[80%]">
                {selected === "photo" && (
                    <div className="flex flex-col w-full overflow-auto p-10 gap-4">
                        <p className="text-subtitle">FRONT</p>
                        <img
                            className="max-w-full w-full h-auto !object-contain"
                            src={frontDocImgUrl ?? "/icons/noImg.svg"}
                            alt="front_doc_img"
                        />
                        <p className="text-subtitle">STREAM FRONT</p>
                        <img
                            className="max-w-full w-full h-auto !object-contain"
                            src={streamFrontImg ?? "/icons/noImg.svg"}
                            alt="strem_front_doc_img"
                        />
                        {documentType !== DocumentsType.PASSPORT && (
                            <>
                                <p className="text-subtitle">BACK</p>
                                <img
                                    className="max-w-full w-full h-auto !object-contain"
                                    src={backDocImgUrl ?? "/icons/noImg.svg"}
                                    alt="back_doc_img"
                                />
                                <p className="text-subtitle">STREAM BACK</p>
                                <img
                                    className="max-w-full w-full h-auto !object-contain"
                                    src={streamBackImg ?? "/icons/noImg.svg"}
                                    alt="stream_back_doc_img"
                                />
                            </>
                        )}
                    </div>
                )}
                {selected === "video" && (
                    <div className="flex flex-col w-full h-full rounded-2xl flex-1">
                        <div className="flex w-full h-[10%]">
                            {livenessTabs.map(({ label, onClick }, i) => (
                                <div
                                    onClick={onClick}
                                    key={i}
                                    className={`flex-center flex-1 cursor-pointer p-3 text-subtitle ${
                                        openTab === label &&
                                        `bg-white dark:bg-[#0A1334] border-b-2 border-primary-active dark:border-[#182C7A]`
                                    }`}
                                >
                                    {label}
                                </div>
                            ))}
                        </div>
                        <div className="w-full flex-center flex-col gap-1 h-[90%] p-6">
                            <div className="flex-center w-full h-full bg-black rounded-2xl">
                                {videoUrls[openTab] ? (
                                    <>
                                        <ReactPlayer
                                            url={videoUrls[openTab]!}
                                            width={"95%"}
                                            height={"95%"}
                                            style={{
                                                objectFit: "contain",
                                                borderRadius: "16px",
                                                overflow: "hidden",
                                            }}
                                            controls
                                        />
                                    </>
                                ) : (
                                    <img
                                        className="max-w-full w-4/5 h-auto max-h-full !object-contain"
                                        src={"/icons/noImg.svg"}
                                        alt="no_video"
                                    />
                                )}
                            </div>
                            <Button
                                text="Download"
                                onClick={handleDownload}
                                active={Boolean(videoUrls[openTab])}
                                className="w-28"
                            />
                        </div>
                    </div>
                )}
            </div>
            <Button
                text={canContinue ? common.continue : "Restart"}
                onClick={canContinue ? next : () => goto({ page: gotoPage! })}
            />
        </Container>
    );
};

export default MediaSummary;
