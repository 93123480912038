import React from "react";
import { useMultilangue } from "../../context/multilangue";
import { CircleLoader } from "../../icons";

interface CheckingSettingsProps {
    icon: JSX.Element;
    text: string;
}

const CheckingSettings: React.FC<CheckingSettingsProps> = ({ icon, text }) => {
    const {
        t: {
            words: { check_phone_settings },
        },
    } = useMultilangue();
    return (
        <div className="flex items-center justify-around flex-col h-[90%]">
            <div className="flex-center relative">
                <CircleLoader />
                <div className="absolute">{icon}</div>
            </div>
            <div className="text-subtitle text-center">{text}</div>
            <div className="text-subtitle font-medium">
                {check_phone_settings.keep_app_opened}
            </div>
        </div>
    );
};

export default CheckingSettings;
