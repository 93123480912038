import { useCallback } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { v4 as uuidv4 } from "uuid";
import { useData } from "../../context/data";
import config from "../../config.json";
import pkg from "../../../package.json";

export enum LogLevels {
    DEBUG = "DEBUG", // Debug is the lowest severity level and provides a detailed account of the application’s state and execution.
    INFO = "INFO", // This log level provides general information about the program, such as when it starts or stops.
    WARNING = "WARNING", // This indicates that something unexpected happened, but the application can recover.
    ERROR = "ERROR", // It indicates that the application encountered an error, which can potentially cause the application to fail.
    CRITICAL = "CRITICAL", // This log level indicates that a critical error or failure occurred, which can cause the application to stop unexpectedly.
}

type DataType = {
    level: LogLevels;
    cid: string;
    sdkVersion: string;
    sdkType: "WEB";
    // sdkScope: "mobile";
    payload: Record<string, any>;
};

export type LogProps = Pick<DataType, "level" | "payload">;

const useLog = (): { logRequest: (logProps: LogProps) => Promise<void> } => {
    const {
        data: { correlationID, accessToken },
    } = useData();

    const logRequest = useCallback(
        async (logProps: LogProps) => {
            const { level, payload } = logProps;
            const cid = correlationID ?? `cid${uuidv4()}`;
            await axios.request({
                baseURL: config.VAR_SHAREID_API_URL,
                url: "/sdk/log",
                method: "POST",
                headers: {
                    // "X-CID": cid,
                    authorization: `Bearer ${accessToken}`,
                },
                data: {
                    level,
                    cid,
                    sdkVersion: pkg.version,
                    sdkType: "WEB",
                    // sdkScope: "mobile",
                    payload,
                },
            } as AxiosRequestConfig<DataType>);
        },
        [correlationID, accessToken]
    );

    return { logRequest };
};

export default useLog;