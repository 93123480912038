import { Button, Container } from "../../components";
import { useData } from "../../context/data";
import { useMultilangue } from "../../context/multilangue";
import { SessionExpiredIcon } from "../../icons";

const SessionExpired = () => {
    const {
        t: {
            words: {
                error_page: {
                    errors: { session_expired, session_expired_retry },
                },
                common: { close }
            },
        },
    } = useMultilangue();
    const {
        data: { redirectUrl },
    } = useData();
    return (
        <Container className={"h-full"}>
            <div className="flex items-end justify-center text-center text-title h-[20%]">
                {session_expired}
            </div>
            <div className="flex-center h-2/5">
                <SessionExpiredIcon />
            </div>
            <div className="flex items-start justify-center text-center text-subtitle h-[10%] whitespace-pre-line overflow-auto">
                {session_expired_retry}
            </div>
            <Button
                text={close}
                active={Boolean(redirectUrl)}
                className="!w-fit h-[30%] !items-start"
                onClick={() =>
                    redirectUrl && window.location.replace(redirectUrl)
                }
            />
        </Container>
    );
};

export default SessionExpired;
