import { DeviceType } from "../../types";

const filterDevices = (devices: DeviceType[], direction: "front" | "back") => {
    const whitelistBackWords = [
        "rear",
        "back",
        "rück",
        "arrière",
        "trasera",
        "trás",
        "traseira",
        "posteriore",
        "后面",
        "後面",
        "背面",
        "后置", // alternative
        "後置", // alternative
        "背置", // alternative
        "задней",
        "الخلفية",
        "후",
        "arka",
        "achterzijde",
        "หลัง",
        "baksidan",
        "bagside",
        "sau",
        "bak",
        "tylny",
        "takakamera",
        "belakang",
        "אחורית",
        "πίσω",
        "spate",
        "hátsó",
        "zadní",
        "darrere",
        "zadná",
        "задня",
        "stražnja",
        "belakang",
        "बैक",
    ];
    const frontWords = ["front", "avant", "frontale", "frontal"];
    const words = direction === "front" ? frontWords : whitelistBackWords;
    return devices.filter((device) => {
        return words.some((word) =>
            device.label.toLowerCase().includes(word)
        );
    });
};

export default filterDevices;
