import { useMemo } from "react";
import { Button } from "../../components";
import { useData } from "../../context/data";
import { updateDocumentCountry } from "../../context/data/action";
import { useMultilangue } from "../../context/multilangue";
import { useNextPage } from "../../hooks";
import ItemsBlock, { ItemBlockType } from "./ItemsBlock";

interface StatesRendererProps {
  stateCode: string;
  setStateCode: React.Dispatch<React.SetStateAction<string>>;
}

const StatesRenderer: React.FC<StatesRendererProps> = ({
  stateCode,
  setStateCode,
}) => {
  const { next } = useNextPage();
  const {
    dispatch,
    data: { scopes },
  } = useData();
  const {
    t: {
      words: { select_country, common },
    },
  } = useMultilangue();

  const scopesWithUsStates = useMemo(
    () =>
      scopes.reduce((acc, current) => {
        if (current.country_code.startsWith("us-")) {
          acc.push({ name: current.country, code: current.country_code });
        }
        return acc;
      }, [] as ItemBlockType[]),
    [scopes]
  );

  return (
    <>
      <ItemsBlock
        className="h-[65%]"
        title={select_country.states_a_to_z}
        items={scopesWithUsStates}
        selectedValue={stateCode}
        onClick={(code) => setStateCode(code)}
        hasFlag={false}
      />
      <Button
        text={common.continue}
        onClick={() => {
          if (stateCode) {
            dispatch(
              updateDocumentCountry({
                documentCountry: stateCode,
              })
            );
            next();
          }
        }}
        className="h-[10%]"
        active={Boolean(stateCode)}
      />
    </>
  );
};

export default StatesRenderer;
