const Flash = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                fill="currentColor"
                viewBox="0 0 14 24"
            >
                <g clipPath="url(#a)">
                    <path
                        className="fill-primary-active dark:fill-secondary-active"
                        d="M11.339 10.305c.446 0 .892-.003 1.339.002.154.002.313.007.462.045.585.15.973.798.467 1.397-1.991 2.357-3.966 4.727-5.946 7.092l-4.074 4.864c-.037.045-.074.09-.114.132-.169.18-.365.208-.581.098-.22-.111-.21-.293-.158-.499.593-2.299 1.182-4.599 1.773-6.898.14-.545.285-1.088.423-1.633.166-.659-.19-1.159-.874-1.202-.367-.023-.737-.009-1.106-.009-.572 0-1.144.002-1.717 0a3.02 3.02 0 0 1-.377-.024c-.375-.047-.657-.231-.802-.587-.14-.344.008-.616.226-.876 1.771-2.11 3.54-4.223 5.31-6.335L10.245.319c.031-.037.064-.073.093-.112.166-.222.383-.258.62-.146.237.113.203.32.15.529-.513 1.99-1.023 3.98-1.535 5.97l-.66 2.561c-.155.606.19 1.12.824 1.164.531.035 1.067.007 1.6.007v.014-.001Z"
                    />
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" d="M0 0h13.84v24H0z" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default Flash;
