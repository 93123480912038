import {
    ActionTypes,
    DataReducerAction,
    DataReducerState,
    DeviceType,
    DocumentDataType,
    StatusType,
} from "../../types";

export const initialization = ({
    challengeOrders,
    scopes,
    id,
    accessToken,
    debug,
    onboardingMethod,
    redirectUrl,
    correlationID,
}: Pick<
    DataReducerState,
    | "accessToken"
    | "challengeOrders"
    | "scopes"
    | "id"
    | "debug"
    | "onboardingMethod"
    | "redirectUrl"
    | "correlationID"
>): DataReducerAction => {
    return {
        type: ActionTypes.INITIALIZATION,
        payload: {
            challengeOrders,
            scopes,
            id,
            status: "initiated",
            accessToken,
            debug,
            onboardingMethod,
            redirectUrl,
            correlationID,
        },
    };
};

export const updateStatus = (status: StatusType): DataReducerAction => {
    return {
        type: ActionTypes.UPDATE_STATUS,
        payload: {
            status,
        },
    };
};

export const updateDocumentType = ({
    documentType,
}: Pick<DocumentDataType, "documentType">): DataReducerAction => {
    return {
        type: ActionTypes.UPDATE_DOCUMENT_TYPE,
        payload: {
            documentType,
        },
    };
};

export const updateDocumentCountry = ({
    documentCountry,
}: Pick<DocumentDataType, "documentCountry">): DataReducerAction => {
    return {
        type: ActionTypes.UPDATE_DOCUMENT_COUNTRY,
        payload: {
            documentCountry,
        },
    };
};

export const clearDocuments = (): DataReducerAction => {
    return {
        type: ActionTypes.CLEAR_DOCUMENTS,
        payload: {},
    };
};

export const addFrontDocument = ({
    frontDocumentFile,
    pictureFrontDocument,
}: Pick<
    DocumentDataType,
    "frontDocumentFile" | "pictureFrontDocument"
>): DataReducerAction => {
    return {
        type: ActionTypes.ADD_FRONT_DOCUMENT,
        payload: {
            frontDocumentFile,
            pictureFrontDocument,
        },
    };
};

export const addBackDocument = ({
    backDocumentFile,
    pictureBackDocument,
}: Pick<
    DocumentDataType,
    "backDocumentFile" | "pictureBackDocument"
>): DataReducerAction => {
    return {
        type: ActionTypes.ADD_BACK_DOCUMENT,
        payload: {
            backDocumentFile,
            pictureBackDocument,
        },
    };
};

export const addStreamImage = (
    key: "streamFrontImg" | "streamBackImg",
    data: string
): DataReducerAction => {
    return {
        type: ActionTypes.ADD_STREAM_IMAGE,
        payload: {
            key,
            data,
        },
    };
};

export const addLivenessVideo = ({
    file,
    index,
}: {
    file: Blob | null;
    index: number;
}): DataReducerAction => {
    return {
        type: ActionTypes.ADD_LIVENESS_VIDEO,
        payload: {
            file,
            index,
        },
    };
};

export const addDevices = (devices: DeviceType[]): DataReducerAction => {
    return {
        type: ActionTypes.ADD_DEVICES,
        payload: {
            devices,
        },
    };
};
