import React, { useEffect } from "react";
import { AxiosRequestConfig } from "axios";
import { Button, Container } from "../../components";
import { useData } from "../../context/data";
import { useMultilangue } from "../../context/multilangue";
import { useRouter } from "../../context/router";
import {
    DataReducerState,
    Pages,
    RouterReducerAction,
    OnboardingMethodType,
    DataReducerAction,
} from "../../types";
import config from "../../config.json";
import { queryErrorHandling, retryRequest } from "../../utils";
import { CircleLoader, IconSendingInfos } from "../../icons";
import { updateStatus } from "../../context/data/action";

type ReqData = {
    name: string;
    value: string | Blob | null;
    condition: boolean;
};

const fillOnboardingRequest = async (
    state: DataReducerState,
    goto: React.Dispatch<RouterReducerAction>,
    dispatch: React.Dispatch<DataReducerAction>
): Promise<void> => {
    const { data, id, onboardingMethod } = state;
    const {
        livenessFiles,
        frontDocumentFile,
        pictureFrontDocument,
        backDocumentFile,
        pictureBackDocument,
        documentType,
        documentCountry,
    } = data;
    const {
        applicantVideo1,
        applicantVideo2,
        applicantVideo3,
        applicantVideo4,
    } = livenessFiles;
    const isPassport = documentType === "passport";
    const rotation = isPassport ? `${90}` : `${0}`;

    const stepDocumentOnly = onboardingMethod !== "liveness";
    const stepLivenessOnly = onboardingMethod !== "document";

    // prettier-ignore
    const reqData: ReqData[] = [
        { name: 'id',                       value: id,                                      condition: true },
        { name: 'document_country',         value: documentCountry,                         condition: stepDocumentOnly },
        { name: 'document_rotation',        value: rotation,                                condition: stepDocumentOnly },
        { name: 'document_type',            value: documentType,                            condition: stepDocumentOnly },
        { name: 'front_document',           value: frontDocumentFile,                       condition: stepDocumentOnly },
        { name: 'picture_front_document',   value: pictureFrontDocument,                    condition: stepDocumentOnly },
        { name: 'back_document',            value: backDocumentFile,                        condition: stepDocumentOnly && !isPassport },
        { name: 'picture_back_document',    value: pictureBackDocument,                     condition: stepDocumentOnly && !isPassport },
        { name: 'face_rotation',            value: "0",                                     condition: stepLivenessOnly },
        { name: 'applicant_video1',         value: applicantVideo1,                         condition: stepLivenessOnly },
        { name: 'applicant_video2',         value: applicantVideo2,                         condition: stepLivenessOnly },
        { name: 'applicant_video3',         value: applicantVideo3,                         condition: stepLivenessOnly },
        { name: 'applicant_video4',         value: applicantVideo4,                         condition: stepLivenessOnly },
    ]

    const filteredReqData = reqData.filter((d) => d.condition);
    const formData = new FormData();
    filteredReqData.forEach((d) => formData.append(d.name, d.value!));

    const url: Record<OnboardingMethodType, string> = {
        all: "fill",
        document: "fill_document",
        liveness: "fill_liveness",
    };

    const axiosReqConfig: AxiosRequestConfig = {
        baseURL: config.VAR_SHAREID_API_URL,
        url: `/v1/onboarding_demand/${url[onboardingMethod || "all"]}`,
        method: "post",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            // "X-CID": correlationID,
        },
        timeout: 120000,
    };

    const onReqFail = (error: any) => {
        if (error.code === "ECONNABORTED") {
            dispatch(updateStatus("error"));
            goto({
                page: Pages.ERROR_PAGE,
                props: {
                    message: "UploadVideosError",
                    code: 604,
                    description: [
                        "Something went wrong during the upload of the videos.",
                    ],
                },
            });
        } else {
            queryErrorHandling(error, goto, dispatch);
        }
    };

    const onReqSuccess = () => {
        dispatch(updateStatus("done"));
        goto({ page: Pages.FINAL_PAGE });
    };

    retryRequest({
        maxRetries: 3,
        config: axiosReqConfig,
        onFail: onReqFail,
        onSuccess: onReqSuccess,
    });
};

const SendInformation = (): JSX.Element => {
    const { data, dispatch } = useData();
    const { goto } = useRouter();
    const {
        t: {
            words: {
                final_page: { back_to_website },
                send_information: { sending },
                check_phone_settings: { keep_app_opened },
            },
        },
    } = useMultilangue();

    useEffect(() => {
        if (!data.desktopTest) {
            fillOnboardingRequest(data, goto, dispatch);
        }
    }, [data, goto]);

    return (
        <Container className={"h-full"}>
            <div className="flex items-center justify-around flex-col h-[90%]">
                <div className="flex-center relative">
                    <CircleLoader />
                    <div className="absolute">
                        <IconSendingInfos />
                    </div>
                </div>
                <div className="text-subtitle text-center">{sending}</div>
                <div className="text-subtitle font-medium">
                    {keep_app_opened}
                </div>
            </div>
            <Button
                text={back_to_website}
                onClick={() => undefined}
                active={false}
            />
        </Container>
    );
};

export default SendInformation;
