import { memo, useEffect, useState } from "react";
import { useMultilangue } from "../../../context/multilangue";
import { useRouter } from "../../../context/router";
import { GreenCheckCircleFill, PassportDiagram } from "../../../icons";
import { Pages } from "../../../types";
import { TimeoutCardContent } from "../../../components";
import Lottie from "lottie-react";
import PassportAnimation from "../../../icons/Animations/passportTilting.json";
import IdAnimation from "../../../icons/Animations/idTilting.json";
import { useTimeout } from "../../../hooks";

interface DocCaptureCardProps {
    done: boolean;
    hasTimeout: boolean;
    isPassport: boolean;
    recording: boolean;
    restart: () => void;
}

const equalProps = (
    prevProps: Readonly<DocCaptureCardProps>,
    nextprops: Readonly<DocCaptureCardProps>
): boolean => {
    return (
        prevProps.done === nextprops.done &&
        prevProps.hasTimeout === nextprops.hasTimeout &&
        prevProps.recording === nextprops.recording
    );
};

const RenderCompleteContent = ({ text }: { text: string }) => (
    <>
        <GreenCheckCircleFill size="35" />
        <p className="text-green-600 text-lg font-bold">{text}</p>
    </>
);

const DocCaptureCard: React.FC<DocCaptureCardProps> = ({
    done,
    hasTimeout,
    isPassport,
    recording,
    restart,
}) => {
    const { goto } = useRouter();
    const {
        t: {
            words: {
                scan_document: {
                    capture: {
                        wrong_doc,
                        scanning_complete,
                        start_moving,
                        keep_moving,
                    },
                },
            },
        },
    } = useMultilangue();
    const [text, setText] = useState(start_moving);
    useTimeout(() => setText(keep_moving), recording ? 3000 : null);
    const [counter, setCounter] = useState(3);
    useEffect(() => {
        recording &&
            counter > 0 &&
            setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter, recording]);

    return (
        <div
            className={`flex justify-center ${
                isPassport ? "h-[85%] items-center" : "h-[70%] items-start"
            } w-full md:w-2/5 z-10`}
        >
            {done || hasTimeout ? (
                <div className="flex-center w-full h-full absolute inset-0">
                    <div
                        className={`flex justify-evenly items-center flex-col aspect-id_card w-[96%] mb-8 md:w-auto md:h-1/3 rounded-3xl bg-black/60 ring ${
                            done && "ring-green-500"
                        } ${hasTimeout && "ring-primary-active"}`}
                    >
                        {done && (
                            <RenderCompleteContent text={scanning_complete} />
                        )}
                        {hasTimeout && (
                            <TimeoutCardContent
                                text={wrong_doc}
                                restartFn={restart}
                                cancelFn={() =>
                                    goto({ page: Pages.SELECT_DOCUMENT_PAGE })
                                }
                            />
                        )}
                    </div>
                </div>
            ) : recording ? (
                <div className="flex-center w-full h-full absolute inset-0">
                    <Lottie
                        animationData={
                            isPassport ? PassportAnimation : IdAnimation
                        }
                        loop
                        style={{
                            width: "100%",
                            height: isPassport ? "100%" : "130%",
                        }}
                    />
                    <div className="absolute h-full w-full inset-0 flex-center flex-col gap-3 text-white font-bold">
                        <p>{text}</p>
                        <p className="text-2xl">{counter > 0 && counter}</p>
                    </div>
                </div>
            ) : (
                <div className="flex-center w-full h-full absolute inset-0">
                    <div
                        className={`${
                            isPassport
                                ? "aspect-passport w-[80%] mt-14 md:w-auto md:h-full"
                                : "aspect-id_card w-[96%] mb-8 md:w-auto md:h-1/3"
                        } relative rounded-3xl max-h-[80%] border border-border-default shadow-full-shadow-around`}
                    >
                        {isPassport && <PassportDiagram />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(DocCaptureCard, equalProps);
