import React from "react";
import { useMultilangue } from "../../context/multilangue";
import { Button } from "../../components";

interface ErrorSettingsProps {
    title: string;
    icon: JSX.Element;
    text: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ErrorSettings: React.FC<ErrorSettingsProps> = ({
    title,
    icon,
    text,
    onClick,
}) => {
    const {
        t: {
            words: { common },
        },
    } = useMultilangue();
    return (
        <div className="flex items-center flex-col gap-1 h-[90%]">
            <div className="flex items-end justify-center text-center text-title h-[20%]">
                {title}
            </div>
            <div className="flex-center h-2/5">
                {icon}
            </div>
            <div className="flex items-start justify-center text-center text-subtitle h-[10%] whitespace-pre-line overflow-auto">
                {text}
            </div>
            {onClick && (
                <Button
                    text={common.retry}
                    onClick={onClick}
                    className="!w-fit h-[30%] !items-start"
                />
            )}
        </div>
    );
};

export default ErrorSettings;
