const GreenCheckCircleFill = ({ size = "24" }: { size?: string }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="35"
                width="35"
                height="35"
                rx="17.5"
                transform="rotate(90 35 0)"
                fill="#0DDF6E"
            />
            <path
                d="M24.6666 13L15.5 22.1667L11.3333 18"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default GreenCheckCircleFill;
