const BackArrow = ({
    className,
    onClick,
}: {
    className?: string;
    onClick?: React.DOMAttributes<HTMLDivElement>["onClick"];
}) => (
    <div className={className} onClick={onClick}>
        <svg
            width="10"
            height="15"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 13L1 7L7 1"
                className="stroke-[#66686B] dark:stroke-white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </div>
);

export default BackArrow;
