import React from "react";
import { TimeoutCardContent } from "../../../../components";
import { useMultilangue } from "../../../../context/multilangue";

interface FailCardProps {
    retryFn: () => void;
    cancelFn: () => void;
}

const FailCard: React.FC<FailCardProps> = ({ retryFn, cancelFn }) => {
    const {
        t: {
            words: {
                scan_liveness: {
                    errors: { fail_challenge },
                },
            },
        },
    } = useMultilangue();
    return (
        <div className="flex-center h-[65%] w-full md:w-1/2 z-10 pt-10">
            <div className="flex justify-evenly items-center flex-col w-full aspect-id_card rounded-3xl bg-black/70 border border-border-default">
                <TimeoutCardContent
                    text={fail_challenge}
                    restartFn={retryFn}
                    cancelFn={cancelFn}
                />
            </div>
        </div>
    );
};

export default FailCard;
