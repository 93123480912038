const ShowBackCard = () => {
    return (
        <svg
            width="129"
            height="133"
            viewBox="0 0 129 133"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M46 76.5684L46.0003 94.5565C46.0003 94.8745 46.258 95.1322 46.576 95.1322L65.4239 95.1322"
                stroke="#0DDF6E"
                strokeWidth="1.9"
                strokeLinecap="round"
            />
            <path
                d="M122.848 57.5674L122.847 39.5793C122.847 39.2613 122.59 39.0035 122.272 39.0035L103.424 39.0035"
                stroke="#0DDF6E"
                strokeWidth="1.9"
                strokeLinecap="round"
            />
            <path
                d="M46 57.5664L46.0003 39.5783C46.0003 39.2603 46.258 39.0025 46.576 39.0025L65.4239 39.0025"
                stroke="#0DDF6E"
                strokeWidth="1.9"
                strokeLinecap="round"
            />
            <path
                d="M122.848 76.5645L122.847 94.5526C122.847 94.8706 122.59 95.1283 122.272 95.1283L103.424 95.1283"
                stroke="#0DDF6E"
                strokeWidth="1.9"
                strokeLinecap="round"
            />
            <path
                d="M25 2L9.5799 2.00021C5.39362 2.00027 2 5.39393 2 9.58021L2 25"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2.9"
                strokeLinecap="round"
            />
            <path
                d="M99.834 131L118.531 131C122.718 131 126.111 127.606 126.111 123.42L126.111 108"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2.9"
                strokeLinecap="round"
            />
            <path
                d="M104.445 2L119.865 2.00021C124.052 2.00027 127.445 5.39393 127.445 9.58021L127.445 25"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2.9"
                strokeLinecap="round"
            />
            <path
                d="M25 131L9.5799 131C5.39362 131 2 127.606 2 123.42L2 108"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2.9"
                strokeLinecap="round"
            />
            <path
                d="M5.03027 97.7435C5.03027 97.7435 6.0957 83.1072 7.68632 75.8313C8.01226 74.2857 9.44683 70.1578 12.5776 66.0117M36.4307 87.5603L33.449 87.6512"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M12.2803 66.2472L18.3433 40.5401L22.7994 30.9938C22.7994 30.9938 25.8763 23.7506 28.8471 24.0066C33.0052 24.365 31.796 27.4323 31.796 27.4323L28.8471 35.3519L26.3313 43.0802L24.6541 51.8087"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
            />
            <path
                d="M8.10547 74.3781L10.5699 45.9009L13.4631 33.4404C13.4631 33.4404 15.794 27.6171 18.307 28.61C20.2732 29.387 19.4097 36.1066 19.4097 36.1066L18.307 41.0097"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
            />
            <path
                d="M7.5464 76.6504L6.69033 68.7765L4.94436 56.1931L4.00572 46.919C4.00572 46.919 3.86801 42.3366 4.94433 40.1252C5.51686 38.9489 6.86452 37.8897 8.0123 38.5541C8.903 39.0696 10.0855 42.1 10.0855 42.1L10.5086 46.5427L10.0855 51.1923"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
            />
            <path
                d="M66 59H75.3618"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M104.155 74.2715L101.814 76.5554L104.155 78.8391"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M97.134 74.2715L94.7935 76.5554L97.134 78.8391"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M90.1125 74.2715L87.772 76.5554L90.1125 78.8391"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M83.091 74.2715L80.7505 76.5554L83.091 78.8391"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M76.0695 74.2715L73.729 76.5554L76.0695 78.8391"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M66 65H84.7238"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M23.3965 86.159C36.8139 89.4867 41.4913 86.2316 44.1749 85.759C46.8584 85.2862 48.892 84.7804 51.4321 86.1063C55.4387 88.1974 59.4819 88.3842 60.2944 91.9781C60.5739 93.2146 60.9466 93.9419 59.1762 95.5784C56.8094 97.7666 52.7899 95.6144 49.5603 96.0514C45.6937 96.5744 40.0193 98.9608 40.0193 98.9608L25.0055 106.144"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.1118 66.5574C18.4036 58.1327 29.3122 46.513 31.3744 44.3326C31.5976 44.0967 31.8921 43.9439 32.2167 43.891L42.4151 42.2325C42.4383 42.2287 42.4585 42.2249 42.4816 42.2203C43.0371 42.1062 50.737 40.5659 52.4571 42.4632C52.8857 42.936 53.7337 43.0996 53.7337 44.9181C53.7337 46.4864 50.809 48.6363 50.0044 49.198C49.8697 49.2921 49.7199 49.3583 49.56 49.4004L43.6427 50.9552L36.9363 52.5913C36.6435 52.6627 36.3797 52.8192 36.1801 53.0401L27.5715 62.5604C27.5517 62.5823 27.5354 62.6012 27.5173 62.6245C27.1442 63.1038 22.658 69.0227 24.5042 74.195C26.4298 79.5896 32.3309 88.9243 39.5987 87.1058"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M116.037 48.3301H54.0368C53.1808 48.3301 52.4868 49.0072 52.4868 49.8426V84.6301C52.4868 85.4654 53.1808 86.1426 54.0368 86.1426H116.037C116.893 86.1426 117.587 85.4654 117.587 84.6301V49.8426C117.587 49.0072 116.893 48.3301 116.037 48.3301Z"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
            />
            <path
                d="M69 74L67 76.5001L69 79"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ShowBackCard;
