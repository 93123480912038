const CameraIcon = ({ className }: { className: string }) => {
    return (
      <div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0832 3.33325H7.9165L5.83317 5.83325H3.33317C2.89114 5.83325 2.46722 6.00885 2.15466 6.32141C1.8421 6.63397 1.6665 7.05789 1.6665 7.49992V14.9999C1.6665 15.4419 1.8421 15.8659 2.15466 16.1784C2.46722 16.491 2.89114 16.6666 3.33317 16.6666H16.6665C17.1085 16.6666 17.5325 16.491 17.845 16.1784C18.1576 15.8659 18.3332 15.4419 18.3332 14.9999V7.49992C18.3332 7.05789 18.1576 6.63397 17.845 6.32141C17.5325 6.00885 17.1085 5.83325 16.6665 5.83325H14.1665L12.0832 3.33325Z"
            className={className}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 13.3333C11.3807 13.3333 12.5 12.214 12.5 10.8333C12.5 9.45254 11.3807 8.33325 10 8.33325C8.61929 8.33325 7.5 9.45254 7.5 10.8333C7.5 12.214 8.61929 13.3333 10 13.3333Z"
            className={className}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };
  
  export default CameraIcon;