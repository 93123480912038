import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { MultilangueProvider } from "./context/multilangue";
import { DataProvider } from "./context/data";
import { RouterProvider } from "./context/router";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.Fragment>
        <DataProvider>
            <MultilangueProvider>
                <RouterProvider>
                    <App />
                </RouterProvider>
            </MultilangueProvider>
        </DataProvider>
    </React.Fragment>
);