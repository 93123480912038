import Webcam from "react-webcam";

type GetScreenshotType = {
    webcamRef: React.MutableRefObject<Webcam>;
    resolution: { w: number; h: number };
    imageQuality: number;
    imageType: string;
    mirrored?: boolean;
    grayscale?: boolean;
    saveIn4k?: (image: Blob | null) => void;
};

const getScreenshot = async ({
    webcamRef,
    resolution,
    imageQuality,
    imageType,
    mirrored = false,
    grayscale = false,
    saveIn4k = undefined,
}: GetScreenshotType): Promise<string> => {
    const video = webcamRef.current.video;

    // Create a canvas for the provided resolution
    const canvas = document.createElement("canvas");
    canvas.width = resolution.w;
    canvas.height = resolution.h;
    const ctx = canvas.getContext("2d");

    // Create a canvas for 4K resolution
    const canvas4k = document.createElement("canvas");
    canvas4k.width = 2160;
    canvas4k.height = 3840;
    const ctx4k = canvas4k.getContext("2d");

    if (ctx && ctx4k && video) {
        mirrored && ctx.scale(-1, 1);

        ctx.drawImage(
            video,
            0,
            0,
            mirrored ? resolution.w * -1 : resolution.w,
            resolution.h
        );
        ctx4k.drawImage(video, 0, 0, canvas4k.width, canvas4k.height);

        if (grayscale) {
            let imgData = ctx.getImageData(0, 0, resolution.w, resolution.h);
            for (let i = 0; i < imgData.data.length; i += 4) {
                const grayscale =
                    0.2126 * imgData.data[i] +
                    0.7152 * imgData.data[i + 1] +
                    0.0722 * imgData.data[i + 2];
                // Assign grayscale value to each RGB channel
                imgData.data[i] = grayscale; // Red channel
                imgData.data[i + 1] = grayscale; // Green channel
                imgData.data[i + 2] = grayscale; // Blue channel
            }
            ctx.putImageData(imgData, 0, 0);
        }
        if (saveIn4k !== undefined) {
            // Convert 4K canvas to blob for blob format
            canvas4k.toBlob(
                (blob4k) => {
                    saveIn4k(blob4k);
                },
                imageType,
                imageQuality
            );
        }
        const dataUrl = canvas.toDataURL(imageType, imageQuality);
        return new Promise((resolve) => {
            resolve(dataUrl);
        });
    }
    return new Promise((resolve) => {
        return resolve("");
    });
};

export default getScreenshot;
