import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import useInterval from "../useInterval";
import config from "../../config.json";
import {
    DocumentDetectionRequestProps,
    DocumentDetectionResponseType,
    DocumentDetectionReturnedType,
    DocumentDetectionType,
    FormDataType,
} from "../../types";
import { useData } from "../../context/data";
import { useRouter } from "../../context/router";
import { getScreenshot, queryErrorHandling } from "../../utils";
import useLog, { LogLevels } from "../useLog";
import { addStreamImage } from "../../context/data/action";

const MAX_WIDTH = 1080;
const MAX_HEIGHT = 1920;

const requestDetectionToAI = async ({
    accessToken,
    formData,
    goto,
    setDocCode,
    setStep,
    dispatch,
    correlationID,
    logRequest,
}: DocumentDetectionRequestProps): Promise<DocumentDetectionReturnedType> => {
    // Initialize a variable to track whether the request has completed
    let requestCompleted = false;

    // Set a timeout to return code 5 after 1 second
    setTimeout(() => {
        if (!requestCompleted) {
            // If the request is still pending after 1 second, return code 5
            setDocCode(7);
        }
    }, 1000);

    try {
        const result: AxiosResponse<DocumentDetectionResponseType> =
            await axios.request({
                baseURL: config.VAR_SHAREID_API_URL,
                url: "/stream/doc",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    // "X-CID": correlationID,
                    Authorization: `Bearer ${accessToken}`,
                },
                method: "post",
                data: formData,
                timeout: 10000,
            });

        // Mark the request as completed
        requestCompleted = true;

        result.data?.code !== 4 && setDocCode(result.data?.code);

        return { verified: result.data.doc_verified };
    } catch (error: any) {
        if (error.code === "ECONNABORTED") {
            setStep(4);
            logRequest({
                level: LogLevels.WARNING,
                payload: {
                    message:
                        "The user is experiencing connection issues that prevent him from completing the process correctly.",
                },
            });
        } else {
            queryErrorHandling(error, goto, dispatch);
        }
        return { verified: false };
    }
};

const useDocumentDetection = ({
    webcamRef,
    documentConfig,
    hasTimeout,
    delay,
    setDocCode,
    setDocPicture,
    setStep,
}: DocumentDetectionType): boolean => {
    const { logRequest } = useLog();
    const [detected, setDetected] = useState<boolean>(false);
    const [hasResponse, setHasResponse] = useState<boolean>(true);
    const { goto } = useRouter();
    const {
        data: {
            accessToken,
            desktopTest,
            correlationID,
            data: { pictureFrontDocument },
        },
        dispatch,
    } = useData();

    useEffect(() => {
        if (desktopTest && webcamRef) {
            setTimeout(async () => {
                const streamImg = await getScreenshot({
                    webcamRef,
                    saveIn4k: (image) => setDocPicture(image as Blob | null),
                    resolution: { w: MAX_WIDTH / 2, h: MAX_HEIGHT / 2 },
                    imageQuality: 0.9,
                    imageType: "image/jpeg",
                });
                dispatch(
                    addStreamImage(
                        pictureFrontDocument
                            ? "streamBackImg"
                            : "streamFrontImg",
                        streamImg
                    )
                );
                setDetected(true);
            }, 5000);
        }
    }, [webcamRef, desktopTest]);

    useEffect(() => {
        if (hasTimeout) setDetected(false);
    }, [hasTimeout]);

    /*
        !hasTimeout = the timer has not yet expired
        hasResponse = wait for a response from the previous request to make a new one
        webcamRef.current = check that the webcam is started
    */
    useInterval(async () => {
        if (!hasTimeout && hasResponse && webcamRef?.current && !desktopTest) {
            setHasResponse(false);
            const streamImg = await getScreenshot({
                webcamRef,
                saveIn4k: (image) => setDocPicture(image as Blob | null),
                resolution: { w: MAX_WIDTH / 2, h: MAX_HEIGHT / 2 },
                imageQuality: 0.9,
                imageType: "image/jpeg",
                grayscale: true,
            });
            if (streamImg) {
                const formData: FormDataType = {
                    image: streamImg.split(",")[1],
                    label: documentConfig.label,
                    front: documentConfig.isFront,
                    country: documentConfig.country,
                };
                requestDetectionToAI({
                    accessToken,
                    formData,
                    goto,
                    setDocCode,
                    setStep,
                    dispatch,
                    correlationID,
                    logRequest,
                }).then(({ verified }) => {
                    if (verified) {
                        dispatch(
                            addStreamImage(
                                pictureFrontDocument
                                    ? "streamBackImg"
                                    : "streamFrontImg",
                                streamImg
                            )
                        );
                    }
                    setHasResponse(!Boolean(verified));
                    !hasTimeout && setDetected(Boolean(verified));
                });
            } else {
                setHasResponse(true);
            }
        }
    }, delay);

    return detected;
};

export default useDocumentDetection;
