const Passport = ({ className }: { className?: string }) => (
    <div className={className}>
        <svg width="35" height="49" viewBox="0 0 67 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.47706 10.4L50.9088 2.1805C53.8884 1.65303 56.65 3.87244 56.776 6.8958L56.8797 9.3852C56.9103 10.1194 56.368 10.7524 55.6378 10.8347L4.22403 16.6302C3.03825 16.7639 2 15.8361 2 14.6428V13.3541C2 11.899 3.04422 10.6537 4.47706 10.4Z"
                fill="transparent"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="2"
            />
            <path
                d="M60 11H3.4C2.07452 11 1 12.0745 1 13.4V67V93.6C1 94.9255 2.07452 96 3.4 96H60C63.3137 96 66 93.3137 66 90V28.5V17C66 13.6863 63.3137 11 60 11Z"
                fill="transparent"
            />
            <path
                d="M3.4 12H60V10H3.4V12ZM60 95H3.4V97H60V95ZM65 17V28.5H67V17H65ZM65 28.5V90H67V28.5H65ZM2 67V13.4H0V67H2ZM2 93.6V67H0V93.6H2ZM3.4 95C2.6268 95 2 94.3732 2 93.6H0C0 95.4778 1.52223 97 3.4 97V95ZM60 97C63.866 97 67 93.866 67 90H65C65 92.7614 62.7614 95 60 95V97ZM60 12C62.7614 12 65 14.2386 65 17H67C67 13.134 63.866 10 60 10V12ZM3.4 10C1.52223 10 0 11.5222 0 13.4H2C2 12.6268 2.6268 12 3.4 12V10Z"
                className="fill-primary-active dark:fill-secondary-active"
            />
            <g clipPath="url(#clip0_2670_12277)">
                <path
                    d="M34.4393 62.8744C43.3205 62.8744 50.5197 55.6752 50.5197 46.794C50.5197 37.9128 43.3205 30.7136 34.4393 30.7136C25.5581 30.7136 18.3589 37.9128 18.3589 46.794C18.3589 55.6752 25.5581 62.8744 34.4393 62.8744Z"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeWidth="2.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.9668 40.3621L48.9115 40.3621"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeWidth="2.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.1729 53.2263H48.1075"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeWidth="2.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M34.4395 62.8744C37.9916 62.8744 40.8716 55.6752 40.8716 46.794C40.8716 37.9128 37.9916 30.7136 34.4395 30.7136C30.8873 30.7136 28.0073 37.9128 28.0073 46.794C28.0073 55.6752 30.8873 62.8744 34.4395 62.8744Z"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeWidth="2.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <rect
                x="16.5"
                y="72.5"
                width="27"
                height="1"
                rx="0.5"
                className="stroke-primary-active dark:stroke-secondary-active"
            />
            <rect
                x="25.8999"
                y="78.5"
                width="15.8"
                height="1"
                rx="0.5"
                className="stroke-primary-active dark:stroke-secondary-active"
            />
            <rect
                x="47.5"
                y="72.5"
                width="5"
                height="1"
                rx="0.5"
                className="stroke-primary-active dark:stroke-secondary-active fill-primary-active dark:fill-secondary-active"
            />
            <defs>
                <clipPath id="clip0_2670_12277">
                    <rect
                        width="38.593"
                        height="38.593"
                        fill="white"
                        transform="translate(13.1426 27.4976)"
                    />
                </clipPath>
            </defs>
        </svg>
    </div>
);

export default Passport;
