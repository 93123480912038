import { AxiosResponse } from "axios";
import { updateStatus } from "../../context/data/action";
import { DataReducerAction, Pages, RouterReducerAction } from "../../types";

const queryErrorHandling = (
    error: any,
    goto: React.Dispatch<RouterReducerAction>,
    dispatch: React.Dispatch<DataReducerAction>
) => {
    dispatch(updateStatus("error"));
    if (error.response) {
        const response: AxiosResponse<{
            description: string | string[];
            message: string;
        }> = error.response;
        const errorDescription = response?.data?.description;
        const errorMsg = response?.data?.message;
        goto({
            page: Pages.ERROR_PAGE,
            props: {
                code: error?.response?.status || 666,
                message: errorMsg || "UnknownError",
                description: errorDescription
                    ? Array.isArray(errorDescription)
                        ? errorDescription
                        : [errorDescription]
                    : ["Request failed because of an unknown error"],
            },
        });
    } else {
        goto({
            page: Pages.ERROR_PAGE,
            props: {
                code: 666,
                message: "UnknownError",
                description: ["Request failed because of an unknown error"],
            },
        });
    }
};

export default queryErrorHandling;
