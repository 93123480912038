import { ThemeType } from "../../types";

const isColor = (strColor: string | undefined, defaultColor: string) => {
    if (strColor) {
        const s = new Option().style;
        s.color = strColor;
        return s.color !== '' ? strColor : defaultColor;
    } else return defaultColor;
}

function isFontWeightValid(value: number | string | undefined, defaultValue: string) {
    const validFontWeights = [
        100, 200, 300, 400, 500, 600, 700, 800, 900, 
        "100", "200", "300", "400", "500", "600", "700", "800", "900"
    ];
    if (value) {
        return validFontWeights.includes(value) ? value.toString() : defaultValue;
    } else return defaultValue;
}

const isValidFontSize = (value: string | undefined, defaultValue: string) => {
    // This regular expression matches strings that start with one or more digits 
    // (with an optional decimal part) followed by either "px" or "em" or "rem".
    const validFontSizeRegex = /^(\d+(\.\d+)?)(px|em|rem)$/;
    if (value) {
        return validFontSizeRegex.test(value) ? value: defaultValue;
    } else return defaultValue;
};

const updateCssVariables = (defaultTheme: ThemeType, theme: ThemeType) => {
    const root = document.documentElement;

    // Default Values
  const { colors: defaultColors, text: defaultText } = defaultTheme
  const { primary: defaultPrimary, secondary: defaultSecondary} = defaultColors
  const { title: defaultTitle, subtitle: defaultSubtitle, paragraph: defaultParagraph } = defaultText

    // Props Values
    const { colors, text } = theme;
    const { primary, secondary } = colors;
    const { title, subtitle, paragraph } = text;

    // Update primary colors
    primary.active && root.style.setProperty("--primary-active", isColor(primary.active, defaultPrimary.active));
    primary.hover && root.style.setProperty("--primary-hover", isColor(primary.hover, defaultPrimary.hover));
    primary.disabled && root.style.setProperty("--primary-disabled", isColor(primary.disabled, defaultPrimary.disabled));
    primary.bg && root.style.setProperty("--primary-bg", isColor(primary.bg, defaultPrimary.bg));

    // Update secondary colors
    secondary.active && root.style.setProperty("--secondary-active", isColor(secondary.active, defaultSecondary.active));
    secondary.hover && root.style.setProperty("--secondary-hover", isColor(secondary.hover, defaultSecondary.hover));
    secondary.disabled && root.style.setProperty("--secondary-disabled", isColor(secondary.disabled, defaultSecondary.disabled));
    secondary.bg && root.style.setProperty("--secondary-bg", isColor(secondary.bg, defaultSecondary.bg));

    // Update title text
    title.size && root.style.setProperty("--title-size", isValidFontSize(title.size, defaultTitle.size));
    title.lineHeight && root.style.setProperty("--title-line-height", isValidFontSize(title.lineHeight, defaultTitle.lineHeight));
    title.weight && root.style.setProperty("--title-weight", isFontWeightValid(title.weight, defaultTitle.weight));
    title.primaryColor && root.style.setProperty("--title-primary-color", isColor(title.primaryColor, defaultTitle.primaryColor));
    title.secondaryColor && root.style.setProperty("--title-secondary-color", isColor(title.secondaryColor, defaultTitle.secondaryColor));

    // Update subtitle text
    subtitle.size && root.style.setProperty("--subtitle-size", isValidFontSize(subtitle.size, defaultSubtitle.size));
    subtitle.lineHeight && root.style.setProperty("--subtitle-line-height", isValidFontSize(subtitle.lineHeight, defaultSubtitle.lineHeight));
    subtitle.weight && root.style.setProperty("--subtitle-weight", isFontWeightValid(subtitle.weight, defaultSubtitle.weight));
    subtitle.primaryColor && root.style.setProperty("--subtitle-primary-color", isColor(subtitle.primaryColor, defaultSubtitle.primaryColor));
    subtitle.secondaryColor && root.style.setProperty("--subtitle-secondary-color", isColor(subtitle.secondaryColor, defaultSubtitle.secondaryColor));

    // Update paragraph text
    paragraph.size && root.style.setProperty("--paragraph-size", isValidFontSize(paragraph.size, defaultParagraph.size));
    paragraph.lineHeight && root.style.setProperty("--paragraph-line-height", isValidFontSize(paragraph.lineHeight, defaultParagraph.lineHeight));
    paragraph.weight && root.style.setProperty("--paragraph-weight", isFontWeightValid(paragraph.weight, defaultParagraph.weight));
    paragraph.primaryColor && root.style.setProperty("--paragraph-primary-color", isColor(paragraph.primaryColor, defaultParagraph.primaryColor));
    paragraph.secondaryColor && root.style.setProperty("--paragraph-secondary-color", isColor(paragraph.secondaryColor, defaultParagraph.secondaryColor));
};

export default updateCssVariables;
