const ShineSuccess = () => {
    return (
        <div>
            <svg
                width="229"
                height="230"
                viewBox="0 0 229 230"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M188 114C188 154.869 154.869 188 114 188C73.1309 188 40 154.869 40 114C40 73.1309 73.1309 40 114 40C154.869 40 188 73.1309 188 114ZM43.9293 114C43.9293 152.699 75.301 184.071 114 184.071C152.699 184.071 184.071 152.699 184.071 114C184.071 75.301 152.699 43.9293 114 43.9293C75.301 43.9293 43.9293 75.301 43.9293 114Z"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeWidth="4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M141.954 105.189C143.324 103.96 143.439 101.853 142.21 100.482C140.981 99.1113 138.874 98.9963 137.503 100.225L106.297 128.203L91.0705 112.976C89.7687 111.675 87.6582 111.675 86.3564 112.976C85.0547 114.278 85.0547 116.389 86.3564 117.69L103.138 134.472C103.255 134.589 103.377 134.695 103.505 134.79C103.563 134.867 103.625 134.943 103.691 135.016C104.92 136.387 107.028 136.502 108.398 135.273L141.954 105.189Z"
                    className="fill-primary-active dark:fill-secondary-active"
                />
                <path
                    d="M115 27L115 1"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeLinecap="round"
                />
                <path
                    d="M114 229L114 203"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeLinecap="round"
                />
                <path
                    d="M177.079 53.1279L195.464 34.7432"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeLinecap="round"
                />
                <path
                    d="M33.5364 195.257L51.9212 176.872"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeLinecap="round"
                />
                <path
                    d="M176.372 177.579L194.757 195.964"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeLinecap="round"
                />
                <path
                    d="M34.2434 34.0361L52.6282 52.4209"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeLinecap="round"
                />
                <path
                    d="M202.5 115.5H228.5"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeLinecap="round"
                />
                <path
                    d="M0.5 114.5H26.5"
                    className="stroke-primary-active dark:stroke-secondary-active"
                    strokeLinecap="round"
                />
            </svg>
        </div>
    );
};

export default ShineSuccess;
