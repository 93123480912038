const CameraVideo = () => {
    return (
        <div>
            <svg
                width="28"
                height="18"
                viewBox="0 0 28 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M27.8704 4.1484C27.7407 3.75951 27.4815 3.5 27.0926 3.24074C26.7037 3.11111 26.3148 2.98135 25.9259 3.11098L20.2222 6.99987V1.2963C20.2222 0.648148 19.7036 0 19.0555 0H18.9259H1.2963C0.648148 0 0 0.518392 0 1.16654V1.2963V16.2037C0 16.8519 0.51855 17.5 1.1667 17.5H1.2963H18.9259C19.574 17.5 20.2222 16.9816 20.2222 16.3335V16.2037V11.7964L25.537 15.4258L25.9259 15.6853H26.3149C27.0927 15.6853 27.7407 15.037 27.8704 14.2593V4.1484ZM18.2777 15.5556H1.94444V1.94444H18.2777V15.5556ZM25.9259 13.3516L20.9999 9.98173V8.81456L25.9259 5.4447V13.3516Z"
                    className="fill-primary-active dark:fill-secondary-active"
                />
            </svg>
        </div>
    );
};

export default CameraVideo;
