import { useRef, useState } from "react"

const useStateRef = (init_value: any) => {
    const [value, set_value] = useState(init_value);
    const value_ref = useRef(value);
    value_ref.current = value;

    return [value_ref.current, set_value];
}

export default useStateRef;