import { Container, Logo } from "../../components";
import { WrongDeviceIcon } from "../../icons";

const WrongDevice = () => {
    return (
        <Container className={"h-full"}>
            <div className="w-full flex-center h-[10%]">
                <Logo />
            </div>
            <div className="flex flex-col gap-2 items-center w-full md:w-[40rem] h-[70%] border border-border-default rounded-2xl">
                <div className="w-full h-1/5 flex-center flex-col gap-1 md:gap-3 text-center">
                    <h1 className="text-title text-primary-active">
                        Wrong device!
                    </h1>
                    <p className="text-subtitle">
                        Use a mobile device to scan the QR code or open the
                        link.
                    </p>
                </div>
                <div className="flex-center w-full h-3/5">
                    <WrongDeviceIcon />
                </div>
                <div className="w-4/5 h-1/5 border-t border-border-default" />
            </div>
        </Container>
    );
};

export default WrongDevice;
