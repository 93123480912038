const isTypeSupported = () => {
    if ("MediaSource" in window) {
        if (MediaSource.isTypeSupported('video/webm;codecs="vp9"')) {
            return 'video/webm;codecs="vp9"';
        } else if (MediaSource.isTypeSupported('video/webm;codecs="vp8"')) {
            return 'video/webm;codecs="vp8"';
        } else if (
            MediaSource.isTypeSupported('video/mp4;codecs="avc1.42E01E"')
        ) {
            // H.264 (also known as AVC) is a codec used within container formats like MP4.
            // The MIME type video/mp4 indicates the container format, and the codec used within that container is specified using the codecs parameter.
            return 'video/mp4;codecs="avc1.42E01E"'; // H.264
        } else if (
            MediaSource.isTypeSupported('video/mp4;codecs="hev1"') ||
            MediaSource.isTypeSupported('video/mp4;codecs="hvc1"')
        ) {
            return 'video/mp4;codecs="hev1"'; // H.265 (HEVC)
        } else {
            return "video/mp4"; // Fallback to a generic type if none of the above are supported
        }
    }
    return null; // Indicate that none of the specified types are supported
};

export default isTypeSupported;
