const CheckCircleFill = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0,0,256,256"
            >
                <g
                    fill="none"
                    fillRule="nonzero"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                >
                    <g transform="scale(5.33333,5.33333)">
                        <path
                            d="M44,24c0,11.045 -8.955,20 -20,20c-11.045,0 -20,-8.955 -20,-20c0,-11.045 8.955,-20 20,-20c11.045,0 20,8.955 20,20z"
                            className={`fill-primary-active dark:fill-secondary-active`}
                        ></path>
                        <path
                            d="M34.586,14.586l-13.57,13.586l-5.602,-5.586l-2.828,2.828l8.434,8.414l16.395,-16.414z"
                            fill="#ffffff"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default CheckCircleFill;
