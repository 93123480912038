import { useMemo } from "react";
import { Button } from "../../components";
import { useData } from "../../context/data";
import { updateDocumentCountry } from "../../context/data/action";
import { useMultilangue } from "../../context/multilangue";
import { useNextPage } from "../../hooks";
import ItemsBlock, { ItemBlockType } from "./ItemsBlock";

interface CountriesRendererProps {
  countryCode: string;
  setCountryCode: React.Dispatch<React.SetStateAction<string>>;
  setDisplayStates: React.Dispatch<React.SetStateAction<boolean>>;
}

const suggestedCountriesCode = [
  "at",
  "be",
  "bg",
  "hr",
  "cz",
  "dk",
  "ee",
  "fi",
  "fr",
  "de",
  "gr",
  "hu",
  "ie",
  "it",
  "lv",
  "lt",
  "lu",
  "mt",
  "nl",
  "pl",
  "pt",
  "ro",
  "sk",
  "si",
  "es",
  "se",
];

const getSuggestedCountries = (scopes: ItemBlockType[]) => {
  const values = scopes.filter((scope) =>
    suggestedCountriesCode.includes(scope.code)
  );
  const frIndex = values.findIndex((element) => element.code === "fr");
  if (frIndex > -1) {
    values.unshift(values.splice(frIndex, 1)[0]);
  }
  return values;
};

const CountriesRenderer: React.FC<CountriesRendererProps> = ({
  countryCode,
  setCountryCode,
}) => {
  const { next } = useNextPage();
  const {
    data: { scopes },
    dispatch,
  } = useData();
  const {
    t: {
      words: { select_country, common },
    },
  } = useMultilangue();

  const scopesWithoutUsStates = useMemo(
    () =>
      scopes.reduce((acc, current) => {
        if (!current.country_code.startsWith("us-")) {
          acc.push({ name: current.country, code: current.country_code });
        }
        return acc;
      }, [] as ItemBlockType[]),
    [scopes]
  );

  return (
    <>
      <ItemsBlock
        className="h-[30%]"
        title={select_country.suggested_countries}
        items={getSuggestedCountries(scopesWithoutUsStates)}
        selectedValue={countryCode}
        onClick={(code) => setCountryCode(code)}
        hasFlag={true}
      />
      <ItemsBlock
        className="h-[35%]"
        title={select_country.countries_a_to_z}
        items={scopesWithoutUsStates}
        selectedValue={countryCode}
        onClick={(code) => setCountryCode(code)}
        hasFlag={true}
      />
      <Button
        text={common.continue}
        onClick={() => {
          if (countryCode) {
            dispatch(
              updateDocumentCountry({
                documentCountry: countryCode,
              })
            );
            next();
          }
        }}
        className="h-[10%]"
        active={Boolean(countryCode)}
      />
    </>
  );
};

export default CountriesRenderer;
