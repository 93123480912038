const RightFaceFrame = ({
    color,
    className,
}: {
    color: "white" | "blue" | "green";
    className?: string;
}) => {
    return (
        <svg
            width="200"
            height="275"
            className={className}
            viewBox="0 0 200 275"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.6471 122.91C13.0684 101.024 11.4273 84.3462 18.8129 66.7731C35.4412 27.2083 69.4418 2 103.537 2C137.632 2 171.633 27.2083 188.261 66.7731C195.647 84.3462 197.537 105.342 197.958 127.228C197.958 127.228 198.86 170.291 192.293 196.712C185.078 225.745 174.174 264.257 156.207 270.906C143.479 275.617 122.082 270.906 122.082 270.906C122.082 270.906 95.6526 267.844 74.2289 252.063C52.3647 235.958 36.5738 195.927 36.5738 195.927M12.6471 122.91C12.6471 122.91 4.08675 127.41 2.44899 134.294C0.487782 142.538 5.58676 164.521 5.58676 164.521C5.58676 164.521 12.4646 191.125 25.9833 194.356C30.453 195.425 36.5738 195.927 36.5738 195.927M12.6471 122.91L19.6572 125.102C22.9944 126.146 25.3461 129.148 25.616 132.659C26.1724 139.898 27.2606 152.394 28.729 161.381C30.9599 175.035 36.5738 195.927 36.5738 195.927M117.178 3.32826C117.178 3.32826 156.757 35.5855 161.228 132.659C163.52 182.424 148.971 272.579 148.971 272.579"
                stroke={color}
                strokeWidth="2.79205"
                strokeLinejoin="round"
                strokeDasharray="1.86 5.58"
            />
        </svg>
    );
};

export default RightFaceFrame;
