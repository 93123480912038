const Smile = () => {
    const color = [1, 1, 1, 1];
    return {
        v: "5.7.1",
        fr: 29.9700012207031,
        ip: 0,
        op: 150.000006109625,
        w: 54,
        h: 52,
        nm: "smile",
        ddd: 0,
        assets: [],
        layers: [
            {
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "smile Outlines",
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [27, 26, 0], ix: 2 },
                    a: { a: 0, k: [27, 26, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [10.833, 0],
                                            [0, -10.834],
                                            [-10.834, 0],
                                            [0, 10.834],
                                        ],
                                        o: [
                                            [-10.834, 0],
                                            [0, 10.834],
                                            [10.833, 0],
                                            [0, -10.834],
                                        ],
                                        v: [
                                            [0.001, -19.647],
                                            [-19.646, 0],
                                            [0.001, 19.647],
                                            [19.648, 0],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 1",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ind: 1,
                                ty: "sh",
                                ix: 2,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [12.438, 0],
                                            [0, 12.438],
                                            [-12.439, 0],
                                            [0, -12.439],
                                        ],
                                        o: [
                                            [-12.439, 0],
                                            [0, -12.439],
                                            [12.438, 0],
                                            [0, 12.438],
                                        ],
                                        v: [
                                            [0.001, 22.558],
                                            [-22.558, 0],
                                            [0.001, -22.558],
                                            [22.558, 0],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 2",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "mm",
                                mm: 1,
                                nm: "Merge Paths 1",
                                mn: "ADBE Vector Filter - Merge",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: color, ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill 1",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [26.907, 25.129], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group 1",
                        np: 4,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-1.884, 0],
                                            [0, -1.884],
                                            [1.884, 0],
                                            [0, 1.884],
                                        ],
                                        o: [
                                            [1.884, 0],
                                            [0, 1.884],
                                            [-1.884, 0],
                                            [0, -1.884],
                                        ],
                                        v: [
                                            [0, -3.411],
                                            [3.411, 0],
                                            [0, 3.411],
                                            [-3.411, 0],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 1",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: color, ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill 1",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [34.412, 18.353], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group 2",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 2,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-1.884, 0],
                                            [0, -1.884],
                                            [1.884, 0],
                                            [0, 1.884],
                                        ],
                                        o: [
                                            [1.884, 0],
                                            [0, 1.884],
                                            [-1.884, 0],
                                            [0, -1.884],
                                        ],
                                        v: [
                                            [0, -3.411],
                                            [3.411, 0],
                                            [0, 3.411],
                                            [-3.411, 0],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 1",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: color, ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill 1",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [19.404, 18.353], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group 3",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 3,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 1,
                                    k: [
                                        {
                                            i: { x: 0.833, y: 0.833 },
                                            o: { x: 0.167, y: 0.167 },
                                            t: 0,
                                            s: [
                                                {
                                                    i: [
                                                        [0, 0],
                                                        [-8.869, 10.915],
                                                        [0, 0],
                                                        [2.931, -0.858],
                                                        [0.981, 0.291],
                                                        [0, 0],
                                                        [1.913, 1.712],
                                                    ],
                                                    o: [
                                                        [3.411, 4.548],
                                                        [0, 0],
                                                        [-2.258, 2.056],
                                                        [-0.981, 0.287],
                                                        [0, 0],
                                                        [-2.461, -0.731],
                                                        [0, 0],
                                                    ],
                                                    v: [
                                                        [-10.66, -5.458],
                                                        [10.66, -5.458],
                                                        [9.39, -4.301],
                                                        [1.504, 0.129],
                                                        [-1.503, 0.122],
                                                        [-2.137, -0.066],
                                                        [-8.779, -3.775],
                                                    ],
                                                    c: true,
                                                },
                                            ],
                                        },
                                        {
                                            i: { x: 0.833, y: 0.833 },
                                            o: { x: 0.167, y: 0.167 },
                                            t: 10,
                                            s: [
                                                {
                                                    i: [
                                                        [0, 0],
                                                        [-8.869, 10.915],
                                                        [0, 0],
                                                        [2.931, -0.858],
                                                        [0.981, 0.291],
                                                        [0, 0],
                                                        [1.913, 1.712],
                                                    ],
                                                    o: [
                                                        [3.411, 4.548],
                                                        [0, 0],
                                                        [-2.258, 2.056],
                                                        [-0.981, 0.287],
                                                        [0, 0],
                                                        [-2.461, -0.731],
                                                        [0, 0],
                                                    ],
                                                    v: [
                                                        [-10.66, -5.458],
                                                        [10.66, -5.458],
                                                        [9.39, -4.301],
                                                        [1.504, 0.129],
                                                        [-1.503, 0.122],
                                                        [-2.137, -0.066],
                                                        [-8.779, -3.775],
                                                    ],
                                                    c: true,
                                                },
                                            ],
                                        },
                                        {
                                            t: 20.0000008146167,
                                            s: [
                                                {
                                                    i: [
                                                        [0, 0],
                                                        [-8.869, 10.915],
                                                        [0, 0],
                                                        [2.704, 0.066],
                                                        [0.085, 0.072],
                                                        [0, 0],
                                                        [5.362, 0.033],
                                                    ],
                                                    o: [
                                                        [3.411, 4.548],
                                                        [0, 0],
                                                        [-2.619, -0.005],
                                                        [-1.022, -0.025],
                                                        [0, 0],
                                                        [-2.531, 0.01],
                                                        [0, 0],
                                                    ],
                                                    v: [
                                                        [-10.66, -5.458],
                                                        [10.66, -5.458],
                                                        [5.015, -5.488],
                                                        [1.753, -5.496],
                                                        [-1.44, -5.503],
                                                        [-4.261, -5.503],
                                                        [-8.467, -5.525],
                                                    ],
                                                    c: true,
                                                },
                                            ],
                                        },
                                    ],
                                    ix: 2,
                                },
                                nm: "Path 1",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: { a: 0, k: color, ix: 3 },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 2.9, ix: 5 },
                                lc: 2,
                                lj: 2,
                                bm: 0,
                                nm: "Stroke 1",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [26.167, 34.743], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group 4",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 4,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 150.000006109625,
                st: 0,
                bm: 0,
            },
        ],
        markers: [],
    };
};

export default Smile;
