const LivenessCheck = () => {
    return (
        <div>
            <svg
                width="140"
                height="158"
                viewBox="0 0 140 158"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_4905_25820)">
                    <path
                        d="M126.849 38.9138C125.491 38.9138 124.389 37.8136 124.389 36.4539V4.95728H94.1321C92.7745 4.95728 91.6721 3.85706 91.6721 2.49949C91.6721 1.14191 92.7723 0.0395508 94.1321 0.0395508H126.849C128.207 0.0395508 129.307 1.13977 129.307 2.49949V36.456C129.307 37.8136 128.207 38.916 126.849 38.916V38.9138Z"
                        className="fill-secondary-bg dark:fill-primary-bg"
                    />
                    <path
                        d="M14.0739 38.9138C12.7163 38.9138 11.6161 37.8136 11.6161 36.4539V2.49949C11.6161 1.14191 12.7163 0.0395508 14.0739 0.0395508H46.7929C48.1505 0.0395508 49.2529 1.13977 49.2529 2.49949C49.2529 3.85921 48.1527 4.95728 46.7929 4.95728H16.5317V36.4539C16.5317 37.8115 15.4315 38.9138 14.0717 38.9138H14.0739Z"
                        className="fill-secondary-bg dark:fill-primary-bg"
                    />
                    <path
                        d="M33.8177 104.762H14.0739C12.7163 104.762 11.6161 103.661 11.6161 102.302V70.8243C11.6161 69.4667 12.7163 68.3644 14.0739 68.3644C15.4315 68.3644 16.5338 69.4646 16.5338 70.8243V99.8438H33.8198C35.1774 99.8438 36.2798 100.944 36.2798 102.302C36.2798 103.659 35.1796 104.762 33.8198 104.762H33.8177Z"
                        className="fill-secondary-bg dark:fill-primary-bg"
                    />
                    <path
                        d="M126.849 104.762H107.103C105.745 104.762 104.645 103.661 104.645 102.302C104.645 100.942 105.745 99.8438 107.103 99.8438H124.389V70.8243C124.389 69.4667 125.489 68.3644 126.849 68.3644C128.209 68.3644 129.307 69.4646 129.307 70.8243V102.302C129.307 103.659 128.207 104.762 126.849 104.762Z"
                        className="fill-secondary-bg dark:fill-primary-bg"
                    />
                    <path
                        d="M70.3799 98.9345C54.2049 98.9345 41.0452 80.8742 41.0452 58.6748C41.0452 57.3172 42.1454 56.2148 43.5051 56.2148C44.8648 56.2148 45.965 57.3151 45.965 58.6748C45.965 77.831 57.1473 94.0168 70.3821 94.0168C83.6168 94.0168 94.3423 78.7918 94.6404 60.0774C94.6619 58.7198 95.7793 57.6067 97.139 57.6561C98.4966 57.6775 99.5818 58.7949 99.5603 60.1546C99.2129 81.9015 86.3963 98.9345 70.3842 98.9345H70.3799Z"
                        className="fill-primary-active dark:fill-secondary-active"
                    />
                    <path
                        d="M3.21968 157.032C2.96018 157.032 2.69638 156.989 2.43902 156.903C1.15222 156.472 0.45735 155.078 0.888428 153.791L7.38463 134.414C9.11109 129.26 13.2267 125.168 18.3889 123.472L40.0672 116.345C45.9565 114.411 50.3145 109.422 51.4426 103.327L53.8296 90.4032C54.0741 89.0649 55.3695 88.1984 56.6949 88.4322C58.031 88.6788 58.9146 89.9614 58.6658 91.2975L56.2788 104.221C54.8247 112.086 49.2014 118.522 41.605 121.02L19.9267 128.147C16.2292 129.361 13.2825 132.289 12.0471 135.98L5.55094 155.357C5.20779 156.386 4.24912 157.036 3.21968 157.036V157.032Z"
                        className="fill-primary-active dark:fill-secondary-active"
                    />
                    <path
                        d="M137.54 157.031C136.511 157.031 135.552 156.382 135.209 155.352L128.715 135.975C127.477 132.286 124.53 129.357 120.835 128.143L99.1548 121.016C91.5583 118.518 85.9372 112.081 84.4831 104.217L82.0961 91.2931C81.8494 89.9569 82.7309 88.6744 84.067 88.4278C85.3924 88.1855 86.6856 89.0626 86.9323 90.3988L89.3193 103.323C90.4452 109.418 94.8054 114.406 100.692 116.341L122.373 123.467C127.535 125.166 131.649 129.254 133.377 134.41L139.871 153.787C140.305 155.073 139.61 156.47 138.321 156.899C138.061 156.986 137.797 157.027 137.54 157.027V157.031Z"
                        className="fill-primary-active dark:fill-secondary-active"
                    />
                    <path
                        d="M97.0898 62.5739C97.019 62.5739 96.9482 62.5739 96.8796 62.5653C95.7901 62.4731 94.8893 61.6688 94.6749 60.5965L93.1543 52.9593C92.4466 49.4056 90.585 46.1607 87.9127 43.823C85.0711 41.3395 81.4444 40.1042 77.7492 40.0162L73.4041 40.0505C68.1925 40.1385 63.0303 38.9525 58.3206 36.7542L56.4333 35.8727L56.3132 36.4668C54.9084 43.4606 52.2147 49.9932 48.305 55.8846L45.5534 60.0367C45.043 60.8024 44.1529 61.2098 43.2286 61.1219C42.3107 61.019 41.53 60.4077 41.2019 59.5456L39.7263 55.6316C37.646 50.1198 36.5908 44.3227 36.5908 38.397C36.5908 36.589 36.8696 34.8068 37.4229 33.0975C39.0443 28.064 42.9305 24.0706 47.8267 22.4106C48.3779 22.2241 48.8862 21.8938 49.2937 21.4563L52.1804 18.3594C54.9492 15.3954 58.3721 13.0299 62.0823 11.5243C66.599 9.68632 71.3152 9.06866 75.9305 9.64986C82.3623 10.4627 89.6049 15.166 93.6133 19.1915C97.0555 22.6487 101.225 28.5251 102.782 33.8632C104.733 40.5545 104.435 47.8164 101.947 54.3126L99.3846 60.9975C99.0178 61.954 98.0999 62.5781 97.0898 62.5781V62.5739ZM49.4009 27.0667C45.9759 28.2291 43.2479 31.0451 42.1026 34.6031C41.708 35.8255 41.5064 37.1016 41.5064 38.3949C41.5064 43.5142 42.3814 48.5284 44.11 53.3067L44.2065 53.1652C47.7624 47.8057 50.2138 41.8628 51.492 35.4953L51.67 34.6052C51.9574 33.1726 52.8624 31.9737 54.1535 31.3196C55.4189 30.6762 56.8858 30.6547 58.1705 31.2531L60.4052 32.2976C64.4522 34.187 68.9968 35.1521 73.3697 35.1307L77.717 35.0964C82.4846 35.1521 87.4045 36.84 91.1555 40.1192C94.4475 42.9995 96.796 46.9263 97.819 51.2436C99.4918 46.0899 99.5862 40.458 98.0635 35.2379C96.7552 30.7534 93.0342 25.574 90.1325 22.6573C86.5895 19.0993 80.3635 15.1638 75.3171 14.5268C71.5382 14.0507 67.6735 14.5612 64.1327 15.9981C60.8856 17.3171 58.0632 19.2666 55.7813 21.7115L52.8946 24.8105C51.9273 25.8443 50.7242 26.6228 49.4052 27.0689L49.4009 27.0667Z"
                        className="fill-primary-active dark:fill-secondary-active"
                    />
                    <path
                        d="M70.3969 140.292C67.5638 140.292 64.7286 139.398 62.3115 137.609L39.3743 120.654C38.2805 119.847 38.0489 118.307 38.8596 117.216C39.6681 116.124 41.2058 115.895 42.2975 116.701L65.2347 133.657C68.3273 135.939 72.4794 135.941 75.5699 133.65L98.4621 116.701C99.5537 115.895 101.094 116.122 101.902 117.214C102.708 118.305 102.481 119.845 101.39 120.654L78.4973 137.603C76.076 139.396 73.2386 140.29 70.3991 140.29L70.3969 140.292Z"
                        className="fill-primary-active dark:fill-secondary-active"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_4905_25820">
                        <rect
                            width="139.24"
                            height="156.992"
                            fill="white"
                            transform="translate(0.759766 0.0395508)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default LivenessCheck;
