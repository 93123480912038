import axios, { AxiosResponse } from "axios";
import {
  ChallengeOrdersType,
  DataReducerAction,
  DocumentsType,
  OnboardingDemandFillInfoResponseType,
  Pages,
  RouterReducerAction,
  ThemeType,
  WordsType,
} from "../../types";
import config from "../../config.json";
import parseJwt from "../parseJwt";
import updateCssVariables from "../updateCssVariables";
import { initialization } from "../../context/data/action";
import queryErrorHandling from "../queryErrorHandling";
import { validate as uuidValidate } from "uuid";

const SESSION_EXPIRED = "SESSION_EXPIRED";
const INVALID_ID = "INVALID_ID";

type OnboardingInformationsTypes = {
  id: string | null;
  dispatch: React.Dispatch<DataReducerAction>;
  goto: React.Dispatch<RouterReducerAction>;
  defaultTheme: ThemeType;
  desktopTest: boolean;
  errors: WordsType["error_page"]["errors"];
  countries: Record<string, string>;
};

const getOnboardingInformations = async ({
  id,
  dispatch,
  goto,
  defaultTheme,
  desktopTest,
  errors,
  countries,
}: OnboardingInformationsTypes) => {
  const isBoolean = (val: any) => "boolean" === typeof val;
  if (desktopTest) {
    const shuffledChallengeOrders: ChallengeOrdersType[] = [
      ChallengeOrdersType.BLINK,
      ChallengeOrdersType.LEFT,
      ChallengeOrdersType.RIGHT,
      ChallengeOrdersType.SMILE,
    ]
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    dispatch(
      initialization({
        challengeOrders: shuffledChallengeOrders,
        scopes: [
          {
            country: "France",
            country_code: "fr",
            documents: [
              DocumentsType.ID_CARD,
              DocumentsType.PASSPORT,
              DocumentsType.RESIDENCY_PERMIT,
              DocumentsType.DRIVER_PERMIT,
            ],
          },
        ],
        id: "df86bb9d-d6f7-48b2-89f1-2bb6e04d9469",
        accessToken: "test_access_token",
        debug: true,
        onboardingMethod: "all",
        redirectUrl: "",
        correlationID: "",
      })
    );
    goto({ page: Pages.LETS_VERIFY_PAGE });
  } else {
    try {
      if (!id || !uuidValidate(id)) throw INVALID_ID;

      const result: AxiosResponse<OnboardingDemandFillInfoResponseType> =
        await axios.request({
          baseURL: config.VAR_SHAREID_API_URL,
          url: `/v1/data/sdk/onboarding_demand/${id}/fill_information`,
        });
      const {
        challenge_order: challengeOrders,
        scopes,
        onboarding_method,
        props: { debug, theme, accessToken, redirectUrl, correlationID },
      } = result.data.payload.informations;

      const decodedJwt: { exp: number; iat: number; payload: string } =
        parseJwt(accessToken, isBoolean(debug) ? debug : false);

      if (decodedJwt.exp * 1000 < Date.now()) throw SESSION_EXPIRED;

      theme && updateCssVariables(defaultTheme, theme);

      await axios.request({
        baseURL: config.VAR_SHAREID_API_URL,
        url: `/v1/data/sdk/onboarding_demand/${id}/filling`,
        method: "POST",
      });

      const translatedScopes = scopes
        .map((scope) => {
          scope.country = countries[scope.country_code] as string;
          return scope;
        })
        .sort((a, b) => a.country?.localeCompare(b.country));

      dispatch(
        initialization({
          challengeOrders: challengeOrders,
          scopes: translatedScopes,
          id,
          accessToken: accessToken,
          debug: isBoolean(debug) ? debug : false,
          onboardingMethod: ["all", "liveness", "document"].includes(
            onboarding_method
          )
            ? onboarding_method
            : "all",
          redirectUrl,
          correlationID: correlationID || "",
        })
      );

      goto({ page: Pages.LETS_VERIFY_PAGE });
    } catch (error: any) {
      if (error === SESSION_EXPIRED) {
        goto({ page: Pages.SESSION_EXPIRED_PAGE });
      } else if (error === INVALID_ID) {
        goto({
          page: Pages.ERROR_PAGE,
          props: {
            displayMsg: true,
            description: [errors.onboarding_id_error],
          },
        });
      } else {
        if (error.response?.data?.message === "NotFound") {
          goto({
            page: Pages.ERROR_PAGE,
            props: {
              displayMsg: true,
              description: [errors.session_expired_retry],
            },
          });
        } else {
          queryErrorHandling(error, goto, dispatch);
        }
      }
    }
  }
};

export default getOnboardingInformations;
