const VideoIcon = ({ className }: { className: string }) => {
    return (
      <div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.3335 5.83325L12.5002 8.33325L8.3335 10.8333V5.83325Z"
            className={className}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.6665 2.5H3.33317C2.4127 2.5 1.6665 3.24619 1.6665 4.16667V12.5C1.6665 13.4205 2.4127 14.1667 3.33317 14.1667H16.6665C17.587 14.1667 18.3332 13.4205 18.3332 12.5V4.16667C18.3332 3.24619 17.587 2.5 16.6665 2.5Z"
            className={className}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 14.1667V17.5001"
            className={className}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.6665 17.5H13.3332"
            className={className}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };
  
  export default VideoIcon;
  