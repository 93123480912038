import { memo } from "react";

const Signature = () => {  
    return (
        <div className="flex justify-center items-end text-center h-[5%] z-10">
            <p className="text-paragraph text-white">Powered by ShareID</p>
        </div>
    );
};

export default memo(Signature);
