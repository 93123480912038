import React from "react";
interface ButtonProps {
    text: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    active?: boolean;
    className?: string;
}

const Button: React.FC<ButtonProps> = ({
    text,
    onClick,
    active = true,
    className = "",
}): JSX.Element => {
    const cursorNotAllowed: string = active ? "" : "cursor-not-allowed";
    const textStyle: string = "text-white text-subtitle";
    const hoverStyle: string =
        "hover:shadow-xl hover:bg-primary-hover dark:hover:bg-secondary-hover";
    const color: string = active
        ? `bg-primary-active dark:bg-secondary-active ${hoverStyle}`
        : "bg-primary-disabled dark:bg-secondary-disabled shadow-xl";
    return (
        <div
            className={`flex justify-center items-end h-[10%] w-full ${className}`}
        >
            <button
                type="button"
                onClick={onClick}
                disabled={!active}
                className={`${cursorNotAllowed} ${color} ${textStyle} w-full h-12 flex-center shadow-sm rounded-3xl px-5 py-2.5`}
            >
                {text}
            </button>
        </div>
    );
};

export default Button;
