import React from "react";

const Container = ({
    children,
    className = "",
}: {
    children: React.ReactNode;
    className?: string;
}): JSX.Element => {
    return (
        <div className={`${className} relative flex items-center flex-col gap-1 w-full p-3`}>{children}</div>
    );
};

export default Container;
