import { useEffect, useState } from "react";
import { Button, WithBackNavigation } from "../../components";
import { useMultilangue } from "../../context/multilangue";
import { CameraVideo, LightBulb, LivenessCheck } from "../../icons";

const Instruction = ({ icon, text }: { icon: JSX.Element; text: string }) => {
    return (
        <div className="flex w-full gap-4">
            <div className="flex-center flex-1">{icon}</div>
            <div className="flex items-center flex-[4]">
                <p className="text-subtitle">{text}</p>
            </div>
        </div>
    );
};

const PresentationMessage = ({
    next,
    prev,
}: {
    next: () => void;
    prev: () => void;
}): JSX.Element => {
    const [isActive, setIsActive] = useState(false);
    const {
        t: {
            words: {
                scan_liveness: { title, video_selfie, lighted_place },
                common,
            },
        },
    } = useMultilangue();

    useEffect(() => {
        setTimeout(() => {
            setIsActive(true);
        }, 2000);
    }, []);

    return (
        <WithBackNavigation onClick={prev}>
            <div className="flex-center h-[10%] text-center">
                <h1 className="text-title">{title}</h1>
            </div>
            <div className="flex-center h-2/5">
                <LivenessCheck />
            </div>
            <div className="flex items-center h-[35%] flex-col justify-evenly px-2">
                <Instruction icon={<CameraVideo />} text={video_selfie} />
                <Instruction icon={<LightBulb />} text={lighted_place} />
            </div>
            <Button
                text={common.continue}
                active={isActive}
                onClick={next}
                className="h-[15%]"
            />
        </WithBackNavigation>
    );
};

export default PresentationMessage;
