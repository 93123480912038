import { useState, useCallback, useEffect } from "react";
import {
    UseTorchLightHook,
    UseTorchLightHookOptions,
    UseTorchLightHookReturnType,
} from "../../types";
import { log } from "../../utils";

const isFunction = (val: any) => typeof val === "function";

const defaultOptions: UseTorchLightHookOptions = {
    debug: false,
    vibrate: 0,
};

const checkTorchAvailability = async (
    stream: MediaStream | any
): Promise<boolean> => {
    let available = false;
    if ("ImageCapture" in window) {
        const [track] = stream.getTracks();
        const imageCapture = new ImageCapture(track);

        if ("getPhotoCapabilities" in imageCapture) {
            try {
                // get the photo capabilities and check if flash is available
                const capabilities = await imageCapture.getPhotoCapabilities();
                const { fillLightMode } = capabilities;
                available = fillLightMode.includes("flash");
            } catch (error) {
                available = false;
            }
        } else {
            available = false;
        }
    } else {
        available = false;
    }
    return available;
};

const useTorchLight: UseTorchLightHook = (
    stream: MediaStream | any,
    {
        debug,
        vibrate,
        onError,
        onSuccess,
    }: UseTorchLightHookOptions = defaultOptions
): UseTorchLightHookReturnType => {
    const [on, setOn] = useState(false);
    const [available, setAvailable] = useState(false);

    useEffect(() => {
        if (stream) {
            checkTorchAvailability(stream).then((response) =>
                setAvailable(response)
            );
        }
    }, [stream]);

    const turnOn = useCallback(async () => {
        try {
            window.navigator.vibrate([vibrate]);

            if (stream && !on) {
                const [track] = stream.getTracks();

                if (track instanceof MediaStreamTrack) {
                    try {
                        // ImageCapture is a new specification supported in modern browsers
                        const imageCapture = new ImageCapture(track);
                        const capabilities =
                            await imageCapture.getPhotoCapabilities();

                        if (capabilities.fillLightMode.includes("flash")) {
                            await track.applyConstraints({
                                advanced: [{ torch: true } as any],
                            });

                            if (onSuccess && isFunction(onSuccess)) {
                                onSuccess({ track, on });
                            }
                        }
                    } catch (err: any) {
                        log(
                            `[UseTorchLight]: error ${err.message}`,
                            "red",
                            debug
                        );

                        // If errored, we can go to fallback forcing to apply the constraints
                        try {
                            await track.applyConstraints({
                                advanced: [{ torch: true } as any],
                            });
                        } catch (err: any) {
                            log(
                                `[UseTorchLight]: error ${err.message}`,
                                "red",
                                debug
                            );

                            if (onError && isFunction(onError)) {
                                onError(err);
                            }
                        }

                        if (onError && isFunction(onError)) {
                            onError(err);
                        }
                    }

                    setOn(true);
                }
            }
        } catch (err: any) {
            log(`[UseTorchLight]: error ${err.message}`, "red", debug);
        }
    }, [stream, vibrate, debug, on, onError, onSuccess]);

    const turnOff = useCallback(async () => {
        window.navigator.vibrate([vibrate]);

        if (stream && on) {
            const pc: RTCPeerConnection | any = new RTCPeerConnection();

            // Older way for browsers
            if ("applyConstraints" in stream) {
                try {
                    await stream?.applyConstraints({
                        advanced: [{ torch: false } as any],
                    });

                    if ("addStream" in pc) {
                        pc?.addStream(stream);
                    }

                    if (onSuccess && isFunction(onSuccess)) {
                        onSuccess({ stream, on });
                    }
                } catch (err: any) {
                    log(`[UseTorchLight]: error ${err.message}`, "red", debug);

                    if (onError && isFunction(onError)) {
                        onError(err);
                    }
                }

                // New way for modern browsers
            } else {
                const tracks: MediaStreamTrack[] = stream.getTracks();

                for (let track of tracks) {
                    try {
                        await track.applyConstraints({
                            advanced: [{ torch: false } as any],
                        });

                        pc.addTrack(track, stream);

                        if (isFunction(onSuccess)) {
                            onSuccess && onSuccess({ track, on });
                        }
                    } catch (err: any) {
                        log(
                            `[UseTorchLight]: error ${err.message}`,
                            "red",
                            debug
                        );

                        if (isFunction(onError)) {
                            onError && onError(err);
                        }
                    }
                }
            }

            setOn(false);
        }
    }, [stream, vibrate, debug, on, onError, onSuccess]);

    return [turnOn, turnOff, available, on];
};

export default useTorchLight;
