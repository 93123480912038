import React from "react";
import { BackArrow } from "../../icons";
import { WithBackNavigationType } from "../../types";
import Container from "../Container";
import { useData } from "../../context/data";

const WithBackNavigation = ({
    children,
    onClick,
}: WithBackNavigationType): JSX.Element => {
    const {
        data: { status },
    } = useData();
    return (
        <>
            <div className="relative z-20 w-full h-[5%] bg-primary-bg dark:bg-secondary-bg flex items-center">
                <BackArrow
                    className="h-14hpx cursor-pointer w-1/6 flex-center"
                    onClick={status !== "error" ? onClick : undefined}
                />
            </div>
            <Container className={"h-[95%]"}>{children}</Container>
        </>
    );
};

export default WithBackNavigation;
