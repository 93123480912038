const FrontFaceFrame = ({
    color,
    className,
}: {
    color: "white" | "blue" | "green";
    className?: string;
}) => {
    return (
        <svg
            width="189"
            height="244"
            className={className}
            viewBox="0 0 189 244"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M94.5719 2C124.152 2 153.398 24.1025 167.653 58.4451C173.86 73.3986 175.539 91.1163 175.951 109.432C175.954 109.574 176.058 109.693 176.196 109.717C177.058 109.865 178.233 110.131 179.466 110.601C181.884 111.522 184.836 113.363 185.683 116.969C187.395 124.254 187.481 134.822 185.687 142.858C182.75 156.011 178.297 163.883 174.495 168.495C172.596 170.799 170.867 172.279 169.588 173.193C168.948 173.651 168.423 173.965 168.045 174.17C168.01 174.189 167.983 174.221 167.971 174.259C154.884 214.077 127.118 242 94.5719 242M94.5719 2C64.9914 2 35.7454 24.1025 21.4902 58.4451C15.5648 72.7202 13.766 89.5138 13.2566 106.942C13.2147 108.374 12.0608 109.485 10.6956 109.875C8.8212 110.41 7.2786 111.481 6.05212 112.958C4.11942 115.285 3.04742 118.523 2.49818 121.971C1.39772 128.88 2.30223 137.252 3.45188 142.836L3.45663 142.858C6.39311 156.011 10.8468 163.883 14.6486 168.495C16.5479 170.799 18.2765 172.279 19.556 173.193C20.1953 173.651 20.7211 173.965 21.0985 174.17C21.1334 174.189 21.1599 174.221 21.1724 174.259C21.2555 174.512 21.3392 174.764 21.4235 175.016C34.6094 214.427 62.2316 242 94.5719 242M94.5719 2V122V242"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="2 6"
            />
        </svg>
    );
};

export default FrontFaceFrame;
