import { Button, Container } from "../../components";
import { useMultilangue } from "../../context/multilangue";
import { Flash, Shield } from "../../icons";
import { useNextPage } from "../../hooks";
import { useData } from "../../context/data";
import { formatTexts } from "../../utils";

const Instruction = ({
    title,
    icon,
    text,
}: {
    title: string;
    icon?: JSX.Element;
    text: string;
}) => {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex gap-2">
                {icon}
                <p className="text-subtitle text-primary-active font-bold">
                    {title}
                </p>
            </div>
            <p className="text-subtitle">{text}</p>
        </div>
    );
};

export const LetsVerify = (): JSX.Element => {
    const { next } = useNextPage();
    const {
        t: {
            words: {
                common,
                lets_verify: {
                    title,
                    fast_verify_identity_title,
                    fast_verify_identity_desc,
                    safe_secure_title,
                    safe_secure_desc,
                    terms,
                },
            },
        },
    } = useMultilangue();
    const {
        data: { status },
    } = useData();

    return (
        <Container className={"h-full"}>
            <div className="flex-center h-[20%] text-center">
                <h1 className="text-title">{title}</h1>
            </div>
            <div className="flex flex-col h-[60%] gap-16">
                <Instruction
                    title={fast_verify_identity_title}
                    icon={<Flash />}
                    text={fast_verify_identity_desc}
                />
                <Instruction
                    title={safe_secure_title}
                    icon={<Shield />}
                    text={safe_secure_desc}
                />
            </div>
            <div className="flex items-center justify-end text-center flex-col h-[10%]">
                <p className="text-paragraph">{formatTexts(terms)}</p>
            </div>
            <Button
                active={status === "initiated"}
                text={common.continue}
                onClick={next}
            />
        </Container>
    );
};

export default LetsVerify;
