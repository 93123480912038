const ErrorCircle = (): JSX.Element => (
    <div>
        <svg
            width="106"
            height="105"
            viewBox="0 0 106 105"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M53 105C82.2711 105 106 81.4949 106 52.5C106 23.5051 82.2711 0 53 0C23.7289 0 0 23.5051 0 52.5C0 81.4949 23.7289 105 53 105Z"
                fill="#FF0000"
            />
            <path
                d="M53.2273 65.9927C55.2278 65.9864 57.1524 65.2334 58.6171 63.8837C60.0818 62.534 60.9789 60.6869 61.1296 58.711L63.4299 27.4C63.5024 26.3265 63.3519 25.2497 62.9879 24.236C62.6239 23.2223 62.0542 22.2932 61.3137 21.5061C60.5732 20.719 59.6778 20.0906 58.6826 19.6595C57.6875 19.2285 56.6138 19.0041 55.5277 19H50.9219C49.8346 19.0002 48.759 19.222 47.762 19.6518C46.7651 20.0815 45.868 20.7099 45.1268 21.4979C44.3855 22.2858 43.8159 23.2164 43.4534 24.2318C43.0908 25.2472 42.9432 26.3256 43.0196 27.4L45.3199 58.711C45.4706 60.6878 46.3685 62.5356 47.8343 63.8854C49.3002 65.2352 51.226 65.9878 53.2273 65.9927Z"
                fill="white"
            />
            <path
                d="M53.2333 85.5175C54.8508 85.5175 56.4318 85.0424 57.7767 84.1523C59.1215 83.2621 60.1697 81.997 60.7887 80.5168C61.4076 79.0365 61.5695 77.4077 61.254 75.8363C60.9385 74.2649 60.1597 72.8215 59.016 71.6886C57.8723 70.5557 56.4151 69.7843 54.8288 69.4717C53.2424 69.1591 51.5981 69.3195 50.1037 69.9327C48.6094 70.5458 47.3322 71.5841 46.4336 72.9162C45.535 74.2484 45.0554 75.8145 45.0554 77.4167C45.0582 79.5643 45.9207 81.6232 47.4537 83.1418C48.9868 84.6603 51.0653 85.5147 53.2333 85.5175Z"
                fill="white"
            />
        </svg>
    </div>
);

export default ErrorCircle;
