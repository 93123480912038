const DriverLicense = ({ className }: { className?: string }) => (
    <div className={className}>
        <svg
            width="79"
            height="64"
            viewBox="0 0 79 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.8006 12.8347L44.1242 9.05992C45.6672 8.78676 47.0973 9.9361 47.1626 11.5018L47.193 12.2331C47.2089 12.6134 46.9281 12.9411 46.5499 12.9838L22.6696 15.6756C22.0555 15.7449 21.5179 15.2644 21.5179 14.6464V14.3645C21.5179 13.6109 22.0586 12.966 22.8006 12.8347Z"
                className="stroke-primary-active dark:stroke-secondary-active fill-primary-bg dark:fill-secondary-bg"
                strokeWidth="1.03572"
            />
            <path
                d="M48.4221 13.0972H22.2429C21.5564 13.0972 21 13.6537 21 14.3401V39.3994V51.7773C21 52.4637 21.5564 53.0201 22.2429 53.0201H48.4221C50.1382 53.0201 51.5293 51.629 51.5293 49.913V21.3167V16.2044C51.5293 14.4883 50.1382 13.0972 48.4221 13.0972Z"
                className="stroke-primary-active dark:stroke-secondary-active fill-primary-bg dark:fill-secondary-bg"
                strokeWidth="1.03572"
            />
            <rect
                x="28.2798"
                y="41.9829"
                width="12.6814"
                height="0.469681"
                rx="0.234841"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="0.469681"
            />
            <rect
                x="28.2798"
                y="44.8008"
                width="7.42096"
                height="0.469681"
                rx="0.234841"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="0.469681"
            />
            <rect
                x="37.6733"
                y="44.8008"
                width="6.10586"
                height="0.469681"
                rx="0.234841"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="0.469681"
            />
            <path
                d="M30.041 26.9529L31.4273 23.7183C31.653 23.1917 32.228 22.8431 32.8705 22.8431H40.1278C40.7703 22.8431 41.3453 23.1917 41.571 23.7183L42.9573 26.9529"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.041 26.9529H42.9573V32.2368H30.041V26.9529Z"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.2152 32.2368V33.411H30.041V32.2368"
                className="fill-primary-active dark:fill-secondary-active"
            />
            <path
                d="M31.2152 32.2368V33.411H30.041V32.2368H31.2152Z"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M42.9572 32.2368V33.411H41.783V32.2368"
                className="fill-primary-active dark:fill-secondary-active"
            />
            <path
                d="M42.9572 32.2368V33.411H41.783V32.2368"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.3898 29.8881C32.7141 29.8881 32.9769 29.6253 32.9769 29.301C32.9769 28.9768 32.7141 28.7139 32.3898 28.7139C32.0656 28.7139 31.8027 28.9768 31.8027 29.301C31.8027 29.6253 32.0656 29.8881 32.3898 29.8881Z"
                className="stroke-primary-active dark:stroke-secondary-active fill-primary-active dark:fill-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M40.6088 29.8884C40.9331 29.8884 41.1959 29.6255 41.1959 29.3013C41.1959 28.977 40.9331 28.7142 40.6088 28.7142C40.2846 28.7142 40.0217 28.977 40.0217 29.3013C40.0217 29.6255 40.2846 29.8884 40.6088 29.8884Z"
                className="stroke-primary-active dark:stroke-secondary-active fill-primary-active dark:fill-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                x="42.8402"
                y="41.9826"
                width="2.34841"
                height="0.469681"
                rx="0.234841"
                className="stroke-primary-active dark:stroke-secondary-active fill-primary-active dark:fill-secondary-active"
                strokeWidth="0.469681"
            />
            <path
                d="M63.9037 38.4601H44.1712C43.5658 38.4601 43.075 38.9509 43.075 39.5563V54.9038C43.075 55.5093 43.5658 56.0001 44.1712 56.0001H63.9037C64.5091 56.0001 64.9999 55.5093 64.9999 54.9038V39.5563C64.9999 38.9509 64.5091 38.4601 63.9037 38.4601Z"
                className="stroke-primary-active dark:stroke-secondary-active fill-primary-bg dark:fill-secondary-bg"
                strokeWidth="1.03572"
                strokeLinejoin="round"
            />
            <path
                d="M49.6502 50.9067C51.5957 50.9067 53.1728 49.3295 53.1728 47.3841C53.1728 45.4386 51.5957 43.8615 49.6502 43.8615C47.7047 43.8615 46.1276 45.4386 46.1276 47.3841C46.1276 49.3295 47.7047 50.9067 49.6502 50.9067Z"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M49.6506 48.1668C50.0829 48.1668 50.4334 47.8163 50.4334 47.384C50.4334 46.9517 50.0829 46.6012 49.6506 46.6012C49.2183 46.6012 48.8678 46.9517 48.8678 47.384C48.8678 47.8163 49.2183 48.1668 49.6506 48.1668Z"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M49.65 48.1669V50.9067"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M48.8672 47.384L46.2252 46.6012"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M50.4323 47.384L53.0742 46.6012"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M56.5282 49.0382H59.1728H61.8173H59.5118M56.5282 46.4886H62.7666"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.03572"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </div>
);

export default DriverLicense;
