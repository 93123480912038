const Network = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="28"
                fill="currentColor"
                viewBox="0 0 38 28"
            >
                <g className="artwork" clipPath="url(#a)">
                    <g className="wifi">
                        <g className="Group">
                            <path
                                d="m37.564 9.193-.698.696a1.494 1.494 0 0 1-2.044.063A23.774 23.774 0 0 0 19 3.956 23.773 23.773 0 0 0 3.177 9.951a1.494 1.494 0 0 1-2.043-.063l-.697-.695A1.486 1.486 0 0 1 .5 7.028 27.734 27.734 0 0 1 19 0a27.736 27.736 0 0 1 18.499 7.028c.641.57.672 1.56.065 2.165Z"
                                className="fill-primary-active dark:fill-secondary-active"
                            />
                            <path
                                d="m31.937 14.801-.7.697a1.493 1.493 0 0 1-2.011.09A15.844 15.844 0 0 0 19 11.868a15.843 15.843 0 0 0-10.226 3.718 1.494 1.494 0 0 1-2.012-.09l-.699-.696a1.484 1.484 0 0 1 .088-2.184A19.808 19.808 0 0 1 19 7.913c4.9 0 9.387 1.77 12.85 4.704.663.562.702 1.57.086 2.184Z"
                                className="fill-primary-active dark:fill-secondary-active"
                            />
                            <path
                                d="m26.29 20.43-.706.703a1.5 1.5 0 0 1-1.936.146A7.92 7.92 0 0 0 19 19.782a7.92 7.92 0 0 0-4.647 1.497 1.499 1.499 0 0 1-1.937-.147l-.706-.703a1.486 1.486 0 0 1 .162-2.244A11.882 11.882 0 0 1 19 15.825c2.673 0 5.14.878 7.129 2.36.728.543.804 1.603.161 2.244Z"
                                className="fill-primary-active dark:fill-secondary-active"
                            />
                        </g>
                        <path
                            d="M20.897 27.238a2.597 2.597 0 0 0 0-3.681 2.618 2.618 0 0 0-3.693 0 2.597 2.597 0 0 0 0 3.68 2.618 2.618 0 0 0 3.693 0Z"
                            className="fill-primary-active dark:fill-secondary-active"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="a" className="a">
                        <path fill="#fff" d="M0 0h38v28H0z" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default Network;
