const HoldFrontID = ({ size = "30" }: { size?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="currentColor"
            viewBox="0 0 30 30"
        >
            <g>
                <path
                    d="M13.232 18.716c.028.543.206 1.079-.223 1.514-.42.424-.94.354-1.486.28.032.367.045.716.096 1.06.196 1.335-.152 2.53-.929 3.608-.248.345-.385.695-.37 1.123.014.406-.27.637-.598.495-.122-.053-.263-.208-.279-.331-.074-.584.02-1.138.387-1.625.448-.599.825-1.244.893-1.997.049-.538 0-1.089-.05-1.63-.076-.85-.187-1.696-.286-2.543-.07-.604.103-1.165.292-1.726.159-.472.382-.93.346-1.45a1.627 1.627 0 0 0-.605-1.186c-.18-.15-.371-.13-.466.07-.426.895-.886 1.779-1.236 2.703-.175.46-.192 1-.192 1.506 0 1.184-.276 2.278-.917 3.277-.198.31-.419.406-.669.267-.247-.136-.292-.42-.1-.732.357-.583.634-1.198.72-1.88.038-.306.044-.615.042-.923 0-.077-.066-.18-.132-.229-.536-.39-.768-.92-.766-1.57v-1.206c-.705.75-1.394 1.418-1.712 2.334-.119.342-.084.746-.074 1.121.053 2.108.354 4.183.843 6.235.09.384.038.807.025 1.21-.007.215-.179.318-.377.341-.199.023-.365-.052-.456-.241a.423.423 0 0 1-.049-.204c.078-.85-.21-1.65-.36-2.466A32.428 32.428 0 0 1 4 18.329c-.004-.5.186-.944.434-1.364.533-.905 1.245-1.662 2.005-2.372a.779.779 0 0 0 .27-.639c-.011-1.674-.008-3.257-.005-4.93.002-.933.484-1.626 1.304-1.859.2-.057.415-.083.624-.084 5.03-.004 10.06-.004 15.09-.004 1.164 0 1.934.773 1.935 1.938.002 2.625.002 5.16 0 7.785 0 1.155-.765 1.915-1.923 1.915H13.232Zm-5.568-1.473c.022 0 .043-.002.064-.003.041-.153.061-.314.126-.454.448-.982.871-1.976 1.37-2.932.313-.602 1.127-.713 1.681-.316.886.637 1.242 1.81.885 2.926-.14.441-.287.882-.44 1.352h12.362c.686 0 1.044-.354 1.044-1.039V9.02c0-.674-.368-1.04-1.048-1.04H8.649c-.671 0-1.037.354-1.038 1.02a4038.34 4038.34 0 0 0 0 7.783c0 .153.034.306.052.459h.001Zm3.647 1.475c.012.13.022.218.028.305.04.488.175.618.666.64.07.002.154.021.209-.008.072-.04.171-.12.172-.182a4.258 4.258 0 0 0-.059-.656c-.005-.038-.081-.094-.126-.095-.289-.008-.577-.004-.89-.004Z"
                    className="fill-secondary-bg dark:fill-primary-bg"
                />
                <path
                    fillRule="evenodd"
                    className="fill-secondary-bg dark:fill-primary-bg"
                    d="M11.15 9A1.15 1.15 0 0 0 10 10.15v1.998c0 .635.515 1.15 1.15 1.15h1.997a1.15 1.15 0 0 0 1.15-1.15V10.15A1.15 1.15 0 0 0 13.147 9H11.15Zm1.04 1.883a.635.635 0 1 0 0-1.27.635.635 0 0 0 0 1.27Zm0 .127a1.272 1.272 0 0 0-1.269 1.27.127.127 0 0 0 .127.127h2.286a.127.127 0 0 0 .127-.127 1.271 1.271 0 0 0-1.27-1.27Zm2.967.064c0-.196.16-.355.355-.355h4a.355.355 0 1 1 0 .71h-4a.355.355 0 0 1-.355-.355Zm.355.712a.356.356 0 0 0 0 .711h6.133a.356.356 0 0 0 0-.711h-6.133Z"
                    clipRule="evenodd"
                />
            </g>
        </svg>
    );
};

export default HoldFrontID;
