const PassportDiagram = () => {
    return (
        <div className="w-full h-full absolute inset-0 p-4 flex-center">
            <svg
                width="90%"
                height="90%"
                viewBox="0 0 190 422"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M170.323 6.41791L166.495 -3.27667e-07L165 0L169.522 7.17346L170.478 7.17346L170.323 6.41791ZM173.732 -1.91357e-06L169.856 6.43489L169.737 7.17346L170.694 7.17346L175.227 -2.24124e-06L173.732 -1.91357e-06Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 17.6937L166.495 11.2758L165 11.2758L169.522 18.4493L170.478 18.4493L170.323 17.6937ZM173.732 11.2758L169.856 17.7107L169.737 18.4493L170.694 18.4493L175.227 11.2758L173.732 11.2758Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 28.9696L166.495 22.5517L165 22.5517L169.522 29.7251L170.478 29.7251L170.323 28.9696ZM173.732 22.5517L169.856 28.9866L169.737 29.7251L170.694 29.7251L175.227 22.5517L173.732 22.5517Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 40.2454L166.495 33.8275L165 33.8275L169.522 41.001L170.478 41.001L170.323 40.2454ZM173.732 33.8275L169.856 40.2624L169.737 41.001L170.694 41.001L175.227 33.8275L173.732 33.8275Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 51.5212L166.495 45.1033L165 45.1033L169.522 52.2768L170.478 52.2768L170.323 51.5212ZM173.732 45.1033L169.856 51.5382L169.737 52.2768L170.694 52.2768L175.227 45.1033L173.732 45.1033Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 62.7971L166.495 56.3792L165 56.3792L169.522 63.5526L170.478 63.5526L170.323 62.7971ZM173.732 56.3791L169.856 62.814L169.737 63.5526L170.694 63.5526L175.227 56.3791L173.732 56.3791Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 74.0729L166.495 67.655L165 67.655L169.522 74.8284L170.478 74.8284L170.323 74.0729ZM173.732 67.655L169.856 74.0899L169.737 74.8284L170.694 74.8284L175.227 67.655L173.732 67.655Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 85.3487L166.495 78.9308L165 78.9308L169.522 86.1043L170.478 86.1043L170.323 85.3487ZM173.732 78.9308L169.856 85.3657L169.737 86.1043L170.694 86.1043L175.227 78.9308L173.732 78.9308Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 96.6245L166.495 90.2066L165 90.2066L169.522 97.3801L170.478 97.3801L170.323 96.6245ZM173.732 90.2066L169.856 96.6415L169.737 97.3801L170.694 97.3801L175.227 90.2066L173.732 90.2066Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 107.9L166.495 101.482L165 101.482L169.522 108.656L170.478 108.656L170.323 107.9ZM173.732 101.482L169.856 107.917L169.737 108.656L170.694 108.656L175.227 101.482L173.732 101.482Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 119.176L166.495 112.758L165 112.758L169.522 119.932L170.478 119.932L170.323 119.176ZM173.732 112.758L169.856 119.193L169.737 119.932L170.694 119.932L175.227 112.758L173.732 112.758Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 130.452L166.495 124.034L165 124.034L169.522 131.208L170.478 131.208L170.323 130.452ZM173.732 124.034L169.856 130.469L169.737 131.208L170.694 131.208L175.227 124.034L173.732 124.034Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 141.728L166.495 135.31L165 135.31L169.522 142.483L170.478 142.483L170.323 141.728ZM173.732 135.31L169.856 141.745L169.737 142.483L170.694 142.483L175.227 135.31L173.732 135.31Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 153.004L166.495 146.586L165 146.586L169.522 153.759L170.478 153.759L170.323 153.004ZM173.732 146.586L169.856 153.021L169.737 153.759L170.694 153.759L175.227 146.586L173.732 146.586Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 164.28L166.495 157.862L165 157.862L169.522 165.035L170.478 165.035L170.323 164.28ZM173.732 157.862L169.856 164.297L169.737 165.035L170.694 165.035L175.227 157.862L173.732 157.862Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 175.555L166.495 169.137L165 169.137L169.522 176.311L170.478 176.311L170.323 175.555ZM173.732 169.137L169.856 175.572L169.737 176.311L170.694 176.311L175.227 169.137L173.732 169.137Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 186.831L166.495 180.413L165 180.413L169.522 187.587L170.478 187.587L170.323 186.831ZM173.732 180.413L169.856 186.848L169.737 187.587L170.694 187.587L175.227 180.413L173.732 180.413Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 198.107L166.495 191.689L165 191.689L169.522 198.863L170.478 198.863L170.323 198.107ZM173.732 191.689L169.856 198.124L169.737 198.863L170.694 198.863L175.227 191.689L173.732 191.689Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 209.383L166.495 202.965L165 202.965L169.522 210.138L170.478 210.138L170.323 209.383ZM173.732 202.965L169.856 209.4L169.737 210.138L170.694 210.138L175.227 202.965L173.732 202.965Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 220.659L166.495 214.241L165 214.241L169.522 221.414L170.478 221.414L170.323 220.659ZM173.732 214.241L169.856 220.676L169.737 221.414L170.694 221.414L175.227 214.241L173.732 214.241Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 231.935L166.495 225.517L165 225.517L169.522 232.69L170.478 232.69L170.323 231.935ZM173.732 225.517L169.856 231.951L169.737 232.69L170.694 232.69L175.227 225.517L173.732 225.517Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 243.21L166.495 236.792L165 236.792L169.522 243.966L170.478 243.966L170.323 243.21ZM173.732 236.792L169.856 243.227L169.737 243.966L170.694 243.966L175.227 236.792L173.732 236.792Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 254.486L166.495 248.068L165 248.068L169.522 255.242L170.478 255.242L170.323 254.486ZM173.732 248.068L169.856 254.503L169.737 255.242L170.694 255.242L175.227 248.068L173.732 248.068Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 265.762L166.495 259.344L165 259.344L169.522 266.518L170.478 266.518L170.323 265.762ZM173.732 259.344L169.856 265.779L169.737 266.518L170.694 266.518L175.227 259.344L173.732 259.344Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 277.038L166.495 270.62L165 270.62L169.522 277.793L170.478 277.793L170.323 277.038ZM173.732 270.62L169.856 277.055L169.737 277.793L170.694 277.793L175.227 270.62L173.732 270.62Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 288.314L166.495 281.896L165 281.896L169.522 289.069L170.478 289.069L170.323 288.314ZM173.732 281.896L169.856 288.331L169.737 289.069L170.694 289.069L175.227 281.896L173.732 281.896Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 299.59L166.495 293.172L165 293.172L169.522 300.345L170.478 300.345L170.323 299.59ZM173.732 293.172L169.856 299.607L169.737 300.345L170.694 300.345L175.227 293.172L173.732 293.172Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 310.865L166.495 304.447L165 304.447L169.522 311.621L170.478 311.621L170.323 310.865ZM173.732 304.447L169.856 310.882L169.737 311.621L170.694 311.621L175.227 304.447L173.732 304.447Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 322.141L166.495 315.723L165 315.723L169.522 322.897L170.478 322.897L170.323 322.141ZM173.732 315.723L169.856 322.158L169.737 322.897L170.694 322.897L175.227 315.723L173.732 315.723Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 333.417L166.495 326.999L165 326.999L169.522 334.173L170.478 334.173L170.323 333.417ZM173.732 326.999L169.856 333.434L169.737 334.173L170.694 334.173L175.227 326.999L173.732 326.999Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 344.693L166.495 338.275L165 338.275L169.522 345.448L170.478 345.448L170.323 344.693ZM173.732 338.275L169.856 344.71L169.737 345.448L170.694 345.448L175.227 338.275L173.732 338.275Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 355.969L166.495 349.551L165 349.551L169.522 356.724L170.478 356.724L170.323 355.969ZM173.732 349.551L169.856 355.986L169.737 356.724L170.694 356.724L175.227 349.551L173.732 349.551Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M170.323 367.244L166.495 360.827L165 360.827L169.522 368L170.478 368L170.323 367.244ZM173.732 360.827L169.856 367.261L169.737 368L170.694 368L175.227 360.827L173.732 360.827Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 6.41791L181.268 -3.56501e-06L179.773 -3.23735e-06L184.294 7.17346L185.251 7.17346L185.096 6.41791ZM188.505 -5.15092e-06L184.629 6.43489L184.51 7.17346L185.466 7.17346L190 -5.47858e-06L188.505 -5.15092e-06Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 17.6937L181.268 11.2758L179.773 11.2758L184.294 18.4493L185.251 18.4493L185.096 17.6937ZM188.505 11.2758L184.629 17.7107L184.51 18.4493L185.466 18.4493L190 11.2758L188.505 11.2758Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 28.9696L181.268 22.5517L179.773 22.5517L184.294 29.7251L185.251 29.7251L185.096 28.9696ZM188.505 22.5517L184.629 28.9865L184.51 29.7251L185.466 29.7251L190 22.5517L188.505 22.5517Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 40.2454L181.268 33.8275L179.773 33.8275L184.294 41.0009L185.251 41.0009L185.096 40.2454ZM188.505 33.8275L184.629 40.2624L184.51 41.0009L185.466 41.0009L190 33.8275L188.505 33.8275Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 51.5212L181.268 45.1033L179.773 45.1033L184.294 52.2768L185.251 52.2768L185.096 51.5212ZM188.505 45.1033L184.629 51.5382L184.51 52.2768L185.466 52.2768L190 45.1033L188.505 45.1033Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 62.7971L181.268 56.3791L179.773 56.3791L184.294 63.5526L185.251 63.5526L185.096 62.7971ZM188.505 56.3791L184.629 62.814L184.51 63.5526L185.466 63.5526L190 56.3791L188.505 56.3791Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 74.0729L181.268 67.655L179.773 67.655L184.294 74.8284L185.251 74.8284L185.096 74.0729ZM188.505 67.655L184.629 74.0899L184.51 74.8284L185.466 74.8284L190 67.655L188.505 67.655Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 85.3487L181.268 78.9308L179.773 78.9308L184.294 86.1043L185.251 86.1043L185.096 85.3487ZM188.505 78.9308L184.629 85.3657L184.51 86.1043L185.466 86.1043L190 78.9308L188.505 78.9308Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 96.6245L181.268 90.2066L179.773 90.2066L184.294 97.3801L185.251 97.3801L185.096 96.6245ZM188.505 90.2066L184.629 96.6415L184.51 97.3801L185.466 97.3801L190 90.2066L188.505 90.2066Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 107.9L181.268 101.482L179.773 101.482L184.294 108.656L185.251 108.656L185.096 107.9ZM188.505 101.482L184.629 107.917L184.51 108.656L185.467 108.656L190 101.482L188.505 101.482Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 119.176L181.268 112.758L179.773 112.758L184.294 119.932L185.251 119.932L185.096 119.176ZM188.505 112.758L184.629 119.193L184.51 119.932L185.467 119.932L190 112.758L188.505 112.758Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 130.452L181.268 124.034L179.773 124.034L184.294 131.208L185.251 131.208L185.096 130.452ZM188.505 124.034L184.629 130.469L184.51 131.208L185.467 131.208L190 124.034L188.505 124.034Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 141.728L181.268 135.31L179.773 135.31L184.294 142.483L185.251 142.483L185.096 141.728ZM188.505 135.31L184.629 141.745L184.51 142.483L185.467 142.483L190 135.31L188.505 135.31Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 153.004L181.268 146.586L179.773 146.586L184.294 153.759L185.251 153.759L185.096 153.004ZM188.505 146.586L184.629 153.021L184.51 153.759L185.467 153.759L190 146.586L188.505 146.586Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 164.28L181.268 157.862L179.773 157.862L184.294 165.035L185.251 165.035L185.096 164.28ZM188.505 157.862L184.629 164.297L184.51 165.035L185.467 165.035L190 157.862L188.505 157.862Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 175.555L181.268 169.137L179.773 169.137L184.294 176.311L185.251 176.311L185.096 175.555ZM188.505 169.137L184.629 175.572L184.51 176.311L185.467 176.311L190 169.137L188.505 169.137Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 186.831L181.268 180.413L179.773 180.413L184.294 187.587L185.251 187.587L185.096 186.831ZM188.505 180.413L184.629 186.848L184.51 187.587L185.467 187.587L190 180.413L188.505 180.413Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 198.107L181.268 191.689L179.773 191.689L184.294 198.863L185.251 198.863L185.096 198.107ZM188.505 191.689L184.629 198.124L184.51 198.863L185.467 198.863L190 191.689L188.505 191.689Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 209.383L181.268 202.965L179.773 202.965L184.294 210.138L185.251 210.138L185.096 209.383ZM188.505 202.965L184.629 209.4L184.51 210.138L185.467 210.138L190 202.965L188.505 202.965Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 220.659L181.268 214.241L179.773 214.241L184.294 221.414L185.251 221.414L185.096 220.659ZM188.505 214.241L184.629 220.676L184.51 221.414L185.467 221.414L190 214.241L188.505 214.241Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 231.935L181.268 225.517L179.773 225.517L184.294 232.69L185.251 232.69L185.096 231.935ZM188.505 225.517L184.629 231.951L184.51 232.69L185.467 232.69L190 225.517L188.505 225.517Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 243.21L181.268 236.792L179.773 236.792L184.294 243.966L185.251 243.966L185.096 243.21ZM188.505 236.792L184.629 243.227L184.51 243.966L185.467 243.966L190 236.792L188.505 236.792Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 254.486L181.268 248.068L179.773 248.068L184.294 255.242L185.251 255.242L185.096 254.486ZM188.505 248.068L184.629 254.503L184.51 255.242L185.467 255.242L190 248.068L188.505 248.068Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 265.762L181.268 259.344L179.773 259.344L184.294 266.518L185.251 266.518L185.096 265.762ZM188.505 259.344L184.629 265.779L184.51 266.518L185.467 266.518L190 259.344L188.505 259.344Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 277.038L181.268 270.62L179.773 270.62L184.294 277.793L185.251 277.793L185.096 277.038ZM188.505 270.62L184.629 277.055L184.51 277.793L185.467 277.793L190 270.62L188.505 270.62Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 288.314L181.268 281.896L179.773 281.896L184.294 289.069L185.251 289.069L185.096 288.314ZM188.505 281.896L184.629 288.331L184.51 289.069L185.467 289.069L190 281.896L188.505 281.896Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 299.59L181.268 293.172L179.773 293.172L184.294 300.345L185.251 300.345L185.096 299.59ZM188.505 293.172L184.629 299.607L184.51 300.345L185.467 300.345L190 293.172L188.505 293.172Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 310.865L181.268 304.447L179.773 304.447L184.294 311.621L185.251 311.621L185.096 310.865ZM188.505 304.447L184.629 310.882L184.51 311.621L185.467 311.621L190 304.447L188.505 304.447Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 322.141L181.268 315.723L179.773 315.723L184.294 322.897L185.251 322.897L185.096 322.141ZM188.505 315.723L184.629 322.158L184.51 322.897L185.467 322.897L190 315.723L188.505 315.723Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 333.417L181.268 326.999L179.773 326.999L184.294 334.173L185.251 334.173L185.096 333.417ZM188.505 326.999L184.629 333.434L184.51 334.173L185.467 334.173L190 326.999L188.505 326.999Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 344.693L181.268 338.275L179.773 338.275L184.294 345.448L185.251 345.448L185.096 344.693ZM188.505 338.275L184.629 344.71L184.51 345.448L185.467 345.448L190 338.275L188.505 338.275Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 355.969L181.268 349.551L179.773 349.551L184.294 356.724L185.251 356.724L185.096 355.969ZM188.505 349.551L184.629 355.986L184.51 356.724L185.467 356.724L190 349.551L188.505 349.551Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    d="M185.096 367.244L181.268 360.827L179.773 360.827L184.294 368L185.251 368L185.096 367.244ZM188.505 360.827L184.629 367.261L184.51 368L185.467 368L190 360.827L188.505 360.827Z"
                    fill="#FCFCFC"
                    fillOpacity="0.56"
                />
                <path
                    opacity="0.3"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.9997e-06 406.496C8.95297e-07 414.918 6.82736 421.745 15.2493 421.745L91.4957 421.745C99.9176 421.745 106.745 414.918 106.745 406.496L106.745 330.249C106.745 321.827 99.9176 315 91.4957 315L15.2493 315C6.82735 315 1.31026e-05 321.827 1.19982e-05 330.249L1.9997e-06 406.496ZM46.7906 367.341C46.7906 358.632 39.7302 351.572 31.0208 351.572C22.3114 351.572 15.251 358.632 15.251 367.341C15.251 376.051 22.3114 383.111 31.0208 383.111C39.7302 383.111 46.7906 376.051 46.7906 367.341ZM49.9453 367.335C49.9549 375.697 53.2808 383.713 59.1936 389.626C65.1064 395.539 73.123 398.865 81.485 398.874C82.3214 398.874 83.1237 398.542 83.7152 397.95C84.3066 397.359 84.6389 396.557 84.6389 395.72L84.6389 338.949C84.6389 338.112 84.3066 337.31 83.7152 336.719C83.1237 336.127 82.3215 335.795 81.485 335.795C73.1231 335.805 65.1064 339.131 59.1936 345.043C53.2808 350.956 49.9549 358.973 49.9453 367.335Z"
                    fill="white"
                    fillOpacity="0.62"
                />
            </svg>
        </div>
    );
};

export default PassportDiagram;
