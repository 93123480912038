import { useData } from "../../../context/data";
import { useMultilangue } from "../../../context/multilangue";
import { Button, WithBackNavigation } from "../../../components";
import {
  ShowBackCardIcon,
  ShowFrontCardIcon,
  ShowFrontPassportIcon,
} from "../../../icons";
import { useEffect, useState } from "react";
import { DocumentsType } from "../../../types";
import Lottie from "lottie-react";
import idBackAnimation from "../../../icons/Animations/id_back.json";
import idFrontAnimation from "../../../icons/Animations/id_front.json";
import passportAnimation from "../../../icons/Animations/passport.json";

const VerificationIntroduction = ({
  side,
  next,
  prev,
}: {
  side: "Front" | "Back";
  next: () => void;
  prev: () => void;
}) => {
  const [isActive, setIsActive] = useState(false);
  const {
    data: {
      data: { documentType },
    },
  } = useData();
  const {
    t: {
      words: {
        scan_document: {
          introduction: {
            doc_front,
            doc_back,
            show_doc_in_front_of_camera,
            move_doc_as_shown,
            start_scanning,
          },
        },
      },
    },
  } = useMultilangue();

  const backSide = side === "Back";

  useEffect(() => {
    setTimeout(() => {
      setIsActive(true);
    }, 3000);
  }, []);

  const animation = backSide
    ? idBackAnimation
    : documentType === DocumentsType.PASSPORT
    ? passportAnimation
    : idFrontAnimation;

  return (
    <WithBackNavigation onClick={prev}>
      <div className="flex-center h-[10%] text-center">
        <h1 className="text-title">{backSide ? doc_back : doc_front}</h1>
      </div>
      <div className="flex flex-col w-full h-4/5 border border-gray-200 rounded-2xl p-5">
        <div className="w-full h-[10%] flex items-center gap-2">
          <div className="w-6 h-6 rounded-full bg-primary-active dark:bg-secondary-active text-white flex-center">
            1
          </div>
          <p className="text-subtitle">{show_doc_in_front_of_camera}</p>
        </div>
        <div className="w-full h-2/5 flex-center flex-col">
          {documentType === DocumentsType.PASSPORT && !backSide && (
            <ShowFrontPassportIcon />
          )}
          {documentType !== DocumentsType.PASSPORT && !backSide && (
            <ShowFrontCardIcon />
          )}
          {backSide && <ShowBackCardIcon />}
        </div>
        <div className="w-full h-[10%] flex items-center gap-2">
          <div className="w-6 h-6 rounded-full bg-primary-active dark:bg-secondary-active text-white flex-center">
            2
          </div>
          <p className="text-subtitle">{move_doc_as_shown}</p>
        </div>
        <div className="w-full h-2/5 flex-center flex-col">
          <Lottie
            animationData={animation}
            loop={true}
            style={{ height: "133px" }}
          />
        </div>
      </div>
      <Button
        text={start_scanning}
        onClick={next}
        active={isActive}
        className="h-[10%]"
      />
    </WithBackNavigation>
  );
};

export default VerificationIntroduction;
