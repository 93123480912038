import axios from "axios";
import { DEFAULT_ERROR_SETTINGS, SettingsErrorType } from ".";
import { addDevices, updateStatus } from "../../context/data/action";
import {
    DataReducerAction,
    RouterReducerAction,
    WordsType,
} from "../../types";
import { queryErrorHandling } from "../../utils";
import config from "../../config.json";

export type NetworkInformation = {
    downlink?: number;
    downlinkMax?: number;
    effectiveType?: "slow-2g" | "2g" | "3g" | "4g";
    rtt?: number;
    type?:
        | "bluetooth"
        | "cellular"
        | "ethernet"
        | "none"
        | "wifi"
        | "wimax"
        | "other"
        | "unknown";
};

// const networkSpeedTest = async (debug: boolean) => {
//     const testImage = new Image();
//     testImage.src = `https://source.unsplash.com/random?topics=nature&cache=${Date.now()}`;

//     const estimatedSpeedPromise = new Promise<number>((resolve, reject) => {
//         const startTime = new Date().getTime();
//         testImage.onload = () => {
//             const endTime = new Date().getTime();
//             const loadTimeMs = (endTime - startTime) / 1000;
//             const loadedBits =
//                 testImage.naturalWidth * testImage.naturalHeight * 8;
//             const speedInBps = (loadedBits / loadTimeMs).toFixed(2);
//             const speedInKbps = (+speedInBps / 1024).toFixed(2);
//             const speedInMbps = (+speedInKbps / 1024).toFixed(2);
//             resolve(+speedInMbps);
//         };
//         testImage.onerror = () => {
//             log("Error loading test image", "red", debug);
//             reject(new Error("Error loading test image"));
//         };
//     });

//     try {
//         const estimatedSpeedMbps = await estimatedSpeedPromise;
//         return estimatedSpeedMbps;
//     } catch (error) {
//         return 0; // Return a default value if there's an error
//     }
// };

// const checkConnection = async (
//     setStep: React.Dispatch<React.SetStateAction<number>>,
//     setError: React.Dispatch<React.SetStateAction<SettingsErrorType>>,
//     debug: boolean,
//     check_phone_settings: WordsType["check_phone_settings"]
// ) => {
//     if ("connection" in navigator) {
//         const connection = navigator.connection as NetworkInformation;
//         if (connection) {
//             if (connection.downlink && connection.downlink > 2) {
//                 setStep(1);
//                 setError(DEFAULT_ERROR_SETTINGS);
//             } else {
//                 setError({
//                     title: check_phone_settings.slow_internet,
//                     text: check_phone_settings.slow_internet_text,
//                     value: true,
//                 });
//             }
//         } else {
//             log("Network information not available", "red", debug);
//             const downlink = await networkSpeedTest(debug);
//             if (downlink > 4) {
//                 setStep(1);
//                 setError(DEFAULT_ERROR_SETTINGS);
//             } else
//                 setError({
//                     title: check_phone_settings.slow_internet,
//                     text: check_phone_settings.slow_internet_text,
//                     value: true,
//                 });
//         }
//     } else {
//         log("Network information API not supported", "red", debug);
//         const downlink = await networkSpeedTest(debug);
//         if (downlink > 4) {
//             setStep(1);
//             setError(DEFAULT_ERROR_SETTINGS);
//         } else
//             setError({
//                 title: check_phone_settings.slow_internet,
//                 text: check_phone_settings.slow_internet_text,
//                 value: true,
//             });
//     }
// };

export const checkInternetConnection = async (
    setStep: React.Dispatch<React.SetStateAction<number>>,
    setError: React.Dispatch<React.SetStateAction<SettingsErrorType>>,
    check_phone_settings: WordsType["check_phone_settings"]
) => {
    if (window.navigator.onLine) {
        setStep(1);
        setError(DEFAULT_ERROR_SETTINGS);
    } else {
        setError({
            title: check_phone_settings.no_internet,
            text: check_phone_settings.no_internet_text,
            value: true,
        });
    }
};

export const cameraPermission = (
    dispatch: React.Dispatch<DataReducerAction>,
    setError: React.Dispatch<
        React.SetStateAction<{
            title: string;
            text: string;
            value: boolean;
        }>
    >,
    setDone: React.Dispatch<React.SetStateAction<boolean>>,
    check_phone_settings: WordsType["check_phone_settings"],
    goto: React.Dispatch<RouterReducerAction>,
    accessToken: string,
    id: string,
    correlationID: string,
) => {
    const successCallback = (stream: MediaStream) => {
        setError(DEFAULT_ERROR_SETTINGS);
        setDone(true);
        navigator.mediaDevices
            .enumerateDevices()
            .then((devices: MediaDeviceInfo[]) => {
                const videoDevices: MediaDeviceInfo[] = devices.filter(
                    (device: MediaDeviceInfo) => device.kind === "videoinput"
                );
                dispatch(addDevices(videoDevices));
                stream.getTracks().forEach((x: MediaStreamTrack) => x.stop());
            })
            .catch(() => {
                stream.getTracks().forEach((x: MediaStreamTrack) => x.stop());
            });
    };
    const errorCallback = async () => {
        try {
            dispatch(updateStatus("error"));
            await axios.request({
                baseURL: config.VAR_SHAREID_API_URL,
                url: `/v1/data/sdk/onboarding_demand/${id}/failed`,
                method: "post",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    // "X-CID": correlationID,
                    Authorization: `Bearer ${accessToken}`,
                },
                data: {
                    code: 700,
                    message: "CameraAccessError",
                    description: ["User has denied camera access"],
                },
            });
        } catch (error) {
            queryErrorHandling(error, goto, dispatch);
        }
        setError({
            title: "Camera access denied",
            text: check_phone_settings.no_camera,
            value: true,
        });
    };

    navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(successCallback, errorCallback);
};
