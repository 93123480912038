const darkModeManagement = (dark: boolean) => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        if (dark) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }
};

export default darkModeManagement;
