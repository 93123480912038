import { useEffect, useState } from "react";
import { useData } from "../../context/data";
import { useRouter } from "../../context/router";
import { getVideoDeviceID } from "../../utils";
import { Pages } from "../../types";
import VerificationIntroduction from "./Introduction";
import CaptureDocument from "./CaptureDocument";
import { useMultilangue } from "../../context/multilangue";
import { useLog, useNextPage } from "../../hooks";
import ErrorSettings from "../CheckPhoneSettings/ErrorSettings";
import { SlowWifi } from "../../icons";
import { updateStatus } from "../../context/data/action";
import { LogLevels } from "../../hooks/useLog";

const DocumentVerification = () => {
    const { next, prev } = useNextPage();
    const { logRequest } = useLog();
    const [deviceId, setDeviceId] = useState("");
    const [step, setStep] = useState(0);
    const { goto } = useRouter();
    const {
        data: {
            devices,
            data: { documentType },
            desktopTest,
        },
        dispatch,
    } = useData();
    const {
        t: {
            words: {
                check_phone_settings: { slow_internet, no_internet_text },
            },
        },
    } = useMultilangue();

    const isPassport: boolean = documentType === "passport";

    useEffect(() => {
        getVideoDeviceID({
            devices,
            side: "back",
            desktopTest,
            onError: () => {
                dispatch(updateStatus("error"));
                goto({
                    page: Pages.ERROR_PAGE,
                    props: {
                        message: "CameraSettingError",
                        code: 701,
                        description: [
                            "Something went wrong during the setting of camera or record buffer.",
                        ],
                    },
                });
                logRequest({
                    level: LogLevels.ERROR,
                    payload: { devices: devices },
                });
            },
            onSuccess: (id) => setDeviceId(id),
        });
    }, [devices, goto]);

    const steps = [
        <VerificationIntroduction
            side="Front"
            next={() => setStep((prev) => prev + 1)}
            prev={prev}
        />,
        <CaptureDocument
            deviceId={deviceId}
            next={() => (isPassport ? next() : setStep((prev) => prev + 1))}
            side="front"
            setStep={setStep}
        />,
        <VerificationIntroduction
            side="Back"
            next={() => setStep((prev) => prev + 1)}
            prev={() => setStep((prev) => prev - 2)}
        />,
        <CaptureDocument
            deviceId={deviceId}
            next={next}
            side="back"
            setStep={setStep}
        />,
        <ErrorSettings
            title={slow_internet}
            icon={<SlowWifi />}
            text={no_internet_text}
            onClick={() => setStep(0)}
        />,
    ];

    return steps[step];
};

export default DocumentVerification;
