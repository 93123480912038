import { useState, useEffect, useRef, useCallback } from "react";
import useStateRef from "../useStateRef";
import useTimer from "../useTimer";
import Webcam from "react-webcam";
import useTorchLight from "../useTorchLight";
import { isTypeSupported } from "../../utils";
import { useRouter } from "../../context/router";
import { Pages } from "../../types";

type Response = {
  webcamRef: React.MutableRefObject<Webcam>;
  webcamStarted: boolean;
  recording: boolean;
  done: boolean;
  recordedChunks: Blob[];
  hasTimeout: any;
  recordingTimer: any;
  nextPageTimer: any;
  aiTimer: number | null;
  timeoutTimer: number | null;
  setRecording: React.Dispatch<React.SetStateAction<boolean>>;
  setWebcamStarted: React.Dispatch<React.SetStateAction<boolean>>;
  setHasTimeout: any;
  setNextPageTimer: any;
  startTimeoutTimer: () => void;
  stopTimeoutTimer: () => void;
  startAiTimer: () => void;
  stopAiTimer: () => void;
  resetValues: () => void;
  callbackEndTimeout: () => void;
  handleStopCapture: () => void;
};

const useRecording = ({
  step,
  delay,
  delayedRecord = false,
  torch = false,
  isPassport = false,
}: {
  step: "doc" | "liveness";
  delay: number;
  delayedRecord?: boolean;
  torch?: boolean;
  isPassport?: boolean;
}): Response => {
  const { goto } = useRouter();
  // Refs
  const webcamRef = useRef<Webcam | null>(
    null
  ) as React.MutableRefObject<Webcam>;
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  // Torch hook
  const [turnOn, _, available] = useTorchLight(webcamRef.current?.stream);

  // Webcam & recording status
  const [webcamStarted, setWebcamStarted] = useState<boolean>(false);
  const [recording, setRecording] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  // Blob video frames
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);

  // Constants
  const QUERY_INTERVAL = delay ?? 500;
  const DETECTION_TIMER = 15000;
  const RECORDING_TIME = step === "liveness" ? 2000 : isPassport ? 6000 : 5000;

  // Timers
  const [hasTimeout, setHasTimeout] = useStateRef(false);
  const [recordingTimer, setRecordingTimer] = useStateRef(null);
  const [nextPageTimer, setNextPageTimer] = useStateRef(null);
  const {
    timer: aiTimer,
    start: startAiTimer,
    stop: stopAiTimer,
  } = useTimer(QUERY_INTERVAL);
  const {
    timer: timeoutTimer,
    start: startTimeoutTimer,
    stop: stopTimeoutTimer,
  } = useTimer(DETECTION_TIMER);

  // Reset states values
  const resetValues = () => {
    setWebcamStarted(false);
    setRecording(false);
    setDone(false);
    setRecordedChunks([]);
    setHasTimeout(false);
    setHasTimeout(null);
  };

  // Start the timer
  const callbackEndTimeout = () => {
    setHasTimeout(true);
    stopAiTimer();
    stopTimeoutTimer();
  };

  const handleDataAvailable = useCallback(
    ({ data: frameData }: { data: Blob }) => {
      if (frameData.size > 0) {
        setRecordedChunks((prev: Blob[]) => prev.concat(frameData));
      }
    },
    [setRecordedChunks]
  );

  const handleStartRecording = useCallback(() => {
    if (torch && available) turnOn();

    const mimeType = isTypeSupported();
    const mediaRecorderOptions: MediaRecorderOptions = {
      bitsPerSecond: 6000000,
      ...(mimeType && { mimeType }),
    };

    // Create a canvas for downscaling
    const downscaleCanvas = document.createElement("canvas");
    downscaleCanvas.width = 720;
    downscaleCanvas.height = 1280;
    const downscaleCtx = downscaleCanvas.getContext("2d");

    const video = webcamRef.current.video;

    mediaRecorderRef.current = new MediaRecorder(
      downscaleCanvas.captureStream(),
      mediaRecorderOptions
    );

    // Record each frame from the 4K video, but downscale to 720p
    const frameInterval = setInterval(() => {
      if (video && downscaleCtx) {
        downscaleCtx.drawImage(
          video,
          0,
          0,
          downscaleCanvas.width,
          downscaleCanvas.height
        );
      }
    }, 1000 / 30);

    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start(50);
    setRecordingTimer(RECORDING_TIME);

    // Stop interval when recording stops
    mediaRecorderRef.current.addEventListener("stop", () => {
      clearInterval(frameInterval);
    });
  }, [
    mediaRecorderRef,
    webcamRef,
    handleDataAvailable,
    setRecordingTimer,
    torch,
    available,
  ]);

  const handleStopCapture = useCallback(() => {
    mediaRecorderRef.current!.stop();
    setRecording(false);
    setDone(true);
  }, [mediaRecorderRef, setDone]);

  // Start capturing the video when the value of recording is true
  useEffect(() => {
    if (recording) {
      setTimeout(
        () => {
          handleStartRecording();
        },
        delayedRecord ? 3000 : 0
      );
    }
  }, [recording, handleStartRecording, delayedRecord]);

  return {
    // states
    webcamRef,
    webcamStarted,
    recording,
    done,
    recordedChunks,
    hasTimeout,
    recordingTimer,
    nextPageTimer,
    aiTimer,
    timeoutTimer,
    // setStates
    setRecording,
    setWebcamStarted,
    setHasTimeout,
    setNextPageTimer,
    // functions
    startTimeoutTimer,
    stopTimeoutTimer,
    startAiTimer,
    stopAiTimer,
    resetValues,
    callbackEndTimeout,
    handleStopCapture,
  };
};

export default useRecording;
