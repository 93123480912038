import { createContext, useContext, useReducer } from "react";
import { DataInterface } from "../../types";
import { initialState, reducer } from "./reducer";

const DataContext = createContext<DataInterface>({
    data: initialState,
    dispatch: () => {},
});

const DataProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { data: state, dispatch };

    return (
        <DataContext.Provider value={value}>{children}</DataContext.Provider>
    );
};

const useData = (): DataInterface => {
    const context = useContext<DataInterface>(DataContext);
    if (context === undefined) {
        throw new Error("useData must be used within a DataProvider");
    }
    return context;
};

export { DataProvider, useData };
