import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const retryRequest = async <T>({
    maxRetries,
    config,
    onSuccess,
    onFail,
}: {
    maxRetries: number;
    config: AxiosRequestConfig;
    onSuccess: (response: AxiosResponse<T>) => void;
    onFail: (error: any) => void;
}) => {
    let retries = 0;
    let axiosError = null;

    while (retries < maxRetries) {
        try {
            const response = await axios.request<T>(config);
            onSuccess(response);
            break;
        } catch (error) {
            retries++;
            axiosError = error;
        }
    }
    if (retries === maxRetries) {
        onFail(axiosError);
    }
};

export default retryRequest;
