const SlowWifi = () => {
    return (
        <div>
            <svg
                width="74"
                height="54"
                viewBox="0 0 74 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_6445_26184)">
                    <path
                        d="M72.8005 17.7296L71.4548 19.0708C70.3797 20.1423 68.6503 20.1997 67.5139 19.1924C59.4002 11.9999 48.7111 7.63032 36.9988 7.63032C25.2864 7.63032 14.5977 11.9995 6.484 19.192C5.34761 20.1993 3.61828 20.1419 2.54312 19.07L1.19827 17.7292C0.0265874 16.5614 0.0870166 14.6547 1.32318 13.5549C10.7976 5.1238 23.2979 0 36.9992 0C50.7004 0 63.2003 5.1238 72.676 13.5549C73.9121 14.6547 73.9722 16.5618 72.8005 17.7296Z"
                        fill="#8C8C8C"
                    />
                    <path
                        d="M61.9489 28.5451L60.6012 29.8883C59.5475 30.9385 57.8599 31.0181 56.7203 30.0609C51.3928 25.5859 44.512 22.8906 36.9993 22.8906C29.4866 22.8906 22.6061 25.5859 17.2786 30.0609C16.139 31.0181 14.451 30.9385 13.3974 29.8879L12.0505 28.5451C10.863 27.3612 10.9388 25.4173 12.2184 24.3328C18.896 18.6742 27.5476 15.2603 36.9989 15.2603C46.4502 15.2603 55.1029 18.6742 61.7805 24.3324C63.0605 25.4169 63.1363 27.3608 61.9484 28.5447L61.9489 28.5451Z"
                        fill="#8C8C8C"
                    />
                    <path
                        d="M51.058 39.399L49.6969 40.7555C48.6931 41.7559 47.113 41.8667 45.9629 41.0372C43.4435 39.2207 40.347 38.1507 36.9991 38.1507C33.6511 38.1507 30.5558 39.2211 28.0361 41.0372C26.8855 41.8667 25.305 41.7559 24.3012 40.7547L22.9405 39.3981C21.7011 38.1625 21.8483 36.1188 23.252 35.0719C27.0846 32.2133 31.8435 30.52 36.9991 30.52C42.1546 30.52 46.9135 32.2133 50.7469 35.0715C52.151 36.1184 52.2982 38.1625 51.0584 39.3981L51.058 39.399Z"
                        fill="black"
                    />
                    <path
                        d="M40.6591 52.5297C42.626 50.5693 42.626 47.3909 40.6591 45.4305C38.6921 43.4701 35.503 43.4701 33.5361 45.4305C31.5691 47.3909 31.5691 50.5693 33.5361 52.5297C35.503 54.4901 38.6921 54.4901 40.6591 52.5297Z"
                        fill="black"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_6445_26184">
                        <rect
                            width="73.2857"
                            height="54"
                            fill="white"
                            transform="translate(0.357422)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default SlowWifi;
