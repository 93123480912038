import { useRef, useState } from "react";
import { TimerResponseType } from "../../types";

const useTimer = (initTimer: number | null): TimerResponseType => {
    const [timer, setTimer] = useState(initTimer);
    const timeRef = useRef(timer);
    timeRef.current = timer;

    const start = () => setTimer(initTimer);

    const stop = () => setTimer(null);

    return {timer: timeRef.current, start, stop};
};

export default useTimer;
