import { memo } from "react";
import { FaceAICodes, WordsType } from "../../../../types";

interface HeaderProps {
    title: string;
    display: boolean;
    icon?: JSX.Element;
    code: FaceAICodes;
}

const equalProps = (
    prevProps: Readonly<HeaderProps>,
    nextprops: Readonly<HeaderProps>
): boolean => {
    return (
        prevProps.title === nextprops.title &&
        prevProps.display === nextprops.display
    );
};

export const handleHeaderTitle = (
    defaultMsg: string,
    errors: WordsType["scan_liveness"]["errors"],
    code: FaceAICodes
) => {
    const codes = [
        { code: 0, label: defaultMsg },
        { code: 1, label: errors.face_to_far },
        { code: 2, label: errors.face_too_close },
        { code: 3, label: errors.look_towards },
        { code: 4, label: errors.no_face },
        { code: 5, label: errors.loading },
    ];
    const label = codes.find((c) => c.code === code)?.label;
    return label || defaultMsg;
};

const Header: React.FC<HeaderProps> = ({ title, icon, display, code }) => {
    const titleParts = title.split(" - ");
    const isCodeError = [1, 2, 3].includes(code);
    return (
        <div className="flex justify-start items-center flex-col gap-2 h-[15%] md:h-[10%] w-full z-20">
            {display && (
                <div
                    className={`bg-primary-bg dark:bg-secondary-bg w-full md:w-96 min-h-[4rem] rounded-lg flex-center gap-2 p-2 ${
                        isCodeError && "ring ring-red-500"
                    } ${code === 7 && "ring ring-green-500"}`}
                >
                    {icon && (
                        <div className="h-full flex-center">{icon}</div>
                    )}
                    <div
                        className={`h-full flex-center flex-col text-center`}
                    >
                        {titleParts.map((part, index) => (
                            <p className="text-subtitle" key={index}>
                                {part}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(Header, equalProps);
