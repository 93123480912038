import React from "react";

/**
 * Format a text string with asterisks to make text between asterisks bold.
 * If a "$" sign is found just before an asterisk, the text is also colored blue.
 * Line breaks are added for text portions separated by a "-" character.
 *
 * @param text - The text to format.
 * @returns React components with formatted text.
 */
const formatTexts = (text: string) => {
    const parts = text.split("*");
    const formattedText = parts.map((part, index) => {
        if (index % 2 === 1) {
            return (
                <span
                    className={`font-semibold ${
                        part.startsWith("$") && "text-primary-active"
                    }`}
                    key={index}
                >
                    {part.replace(/^\$/, "")}
                </span>
            );
        }
        return part.split("-").map((subpart, subIndex) => (
            <React.Fragment key={subIndex}>
                {subIndex > 0 && <br />}
                {subpart}
            </React.Fragment>
        ));
    });
    return formattedText;
};

export default formatTexts;
