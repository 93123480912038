import { useEffect, useState } from "react";
import { WithBackNavigation } from "../../components";
import { useData } from "../../context/data";
import { useMultilangue } from "../../context/multilangue";
import { cameraPermission, checkInternetConnection } from "./helper";
import { Camera, Network, NoWifi } from "../../icons";
import { useNextPage } from "../../hooks";
import CheckingSettings from "./CheckingSettings";
import ErrorSettings from "./ErrorSettings";
import { useRouter } from "../../context/router";

export const DEFAULT_ERROR_SETTINGS = {
    title: "",
    text: "",
    value: false,
};

export type SettingsErrorType = {
    title: string;
    text: string;
    value: boolean;
};

const CheckPhoneSettings = (): JSX.Element => {
    const { next, prev } = useNextPage();
    const [step, setStep] = useState(0);
    const [error, setError] = useState<SettingsErrorType>(
        DEFAULT_ERROR_SETTINGS
    );
    const [done, setDone] = useState(false);
    const {
        dispatch,
        data: { id, accessToken, correlationID },
    } = useData();
    const { goto } = useRouter();

    const {
        t: {
            words: { check_phone_settings },
        },
    } = useMultilangue();

    useEffect(() => {
        if (step === 0) {
            setTimeout(
                () =>
                    checkInternetConnection(
                        setStep,
                        setError,
                        check_phone_settings
                    ),
                1000
            );
        }
        if (step === 1) {
            setTimeout(
                () =>
                    cameraPermission(
                        dispatch,
                        setError,
                        setDone,
                        check_phone_settings,
                        goto,
                        accessToken,
                        id,
                        correlationID
                    ),
                1000
            );
        }
        if (done) setTimeout(next, 500);
    }, [dispatch, step, done]);

    const steps = [
        <CheckingSettings
            icon={<Network />}
            text={check_phone_settings.check_internet as string}
        />,
        <CheckingSettings
            icon={<Camera />}
            text={check_phone_settings.check_camera as string}
        />,
    ];

    const errors = [
        <ErrorSettings
            title={error.title}
            icon={<NoWifi />}
            text={error.text}
            onClick={() => {
                setError(DEFAULT_ERROR_SETTINGS);
                setTimeout(
                    () =>
                        checkInternetConnection(
                            setStep,
                            setError,
                            check_phone_settings
                        ),
                    1000
                );
            }}
        />,
        <ErrorSettings
            title={error.title}
            icon={<img src="/icons/no_camera.svg" alt="no camera" />}
            text={error.text}
        />,
    ];

    return (
        <WithBackNavigation onClick={prev}>
            {!error.value && steps[step]}
            {error.value && errors[step]}
        </WithBackNavigation>
    );
};

export default CheckPhoneSettings;
