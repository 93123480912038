const ArrowsRight = () => {
    const color = [1, 1, 1, 1];
    return {
        v: "5.7.1",
        fr: 25,
        ip: 0,
        op: 56,
        w: 140,
        h: 80,
        nm: "Arrows - Right",
        ddd: 0,
        assets: [
            {
                id: "comp_0",
                layers: [
                    {
                        ddd: 0,
                        ind: 1,
                        ty: 4,
                        nm: "Shape Layer 2",
                        sr: 1,
                        ks: {
                            o: { a: 0, k: 33, ix: 11 },
                            r: { a: 0, k: 0, ix: 10 },
                            p: {
                                s: true,
                                x: {
                                    a: 1,
                                    k: [
                                        {
                                            i: { x: [0.508], y: [1] },
                                            o: { x: [0.477], y: [0] },
                                            t: 0,
                                            s: [73],
                                        },
                                        {
                                            i: { x: [0.308], y: [1] },
                                            o: { x: [0.342], y: [0] },
                                            t: 23,
                                            s: [145],
                                        },
                                        { t: 50, s: [73] },
                                    ],
                                    ix: 3,
                                },
                                y: { a: 0, k: 313, ix: 4 },
                            },
                            a: { a: 0, k: [0, 0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100, 100], ix: 6 },
                        },
                        ao: 0,
                        shapes: [
                            {
                                ty: "gr",
                                it: [
                                    {
                                        ind: 0,
                                        ty: "sh",
                                        ix: 1,
                                        ks: {
                                            a: 0,
                                            k: {
                                                i: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                o: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                v: [
                                                    [92.5, -86.5],
                                                    [188.5, 13],
                                                    [87.5, 118],
                                                ],
                                                c: false,
                                            },
                                            ix: 2,
                                        },
                                        nm: "Path 1",
                                        mn: "ADBE Vector Shape - Group",
                                        hd: false,
                                    },
                                    {
                                        ty: "st",
                                        c: { a: 0, k: color, ix: 3 },
                                        o: { a: 0, k: 100, ix: 4 },
                                        w: { a: 0, k: 50, ix: 5 },
                                        lc: 2,
                                        lj: 2,
                                        bm: 0,
                                        nm: "Stroke 1",
                                        mn: "ADBE Vector Graphic - Stroke",
                                        hd: false,
                                    },
                                    {
                                        ty: "tr",
                                        p: { a: 0, k: [0, 0], ix: 2 },
                                        a: { a: 0, k: [0, 0], ix: 1 },
                                        s: { a: 0, k: [100, 100], ix: 3 },
                                        r: { a: 0, k: 0, ix: 6 },
                                        o: { a: 0, k: 100, ix: 7 },
                                        sk: { a: 0, k: 0, ix: 4 },
                                        sa: { a: 0, k: 0, ix: 5 },
                                        nm: "Transform",
                                    },
                                ],
                                nm: "Shape 1",
                                np: 2,
                                cix: 2,
                                bm: 0,
                                ix: 1,
                                mn: "ADBE Vector Group",
                                hd: false,
                            },
                        ],
                        ip: 0,
                        op: 1503,
                        st: 0,
                        bm: 0,
                    },
                    {
                        ddd: 0,
                        ind: 2,
                        ty: 4,
                        nm: "Shape Layer 3",
                        sr: 1,
                        ks: {
                            o: { a: 0, k: 66, ix: 11 },
                            r: { a: 0, k: 0, ix: 10 },
                            p: {
                                s: true,
                                x: {
                                    a: 1,
                                    k: [
                                        {
                                            i: { x: [0.508], y: [1] },
                                            o: { x: [0.477], y: [0] },
                                            t: 2,
                                            s: [194],
                                        },
                                        {
                                            i: { x: [0.308], y: [1] },
                                            o: { x: [0.342], y: [0] },
                                            t: 25,
                                            s: [266],
                                        },
                                        { t: 50, s: [194] },
                                    ],
                                    ix: 3,
                                },
                                y: { a: 0, k: 313, ix: 4 },
                            },
                            a: { a: 0, k: [0, 0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100, 100], ix: 6 },
                        },
                        ao: 0,
                        shapes: [
                            {
                                ty: "gr",
                                it: [
                                    {
                                        ind: 0,
                                        ty: "sh",
                                        ix: 1,
                                        ks: {
                                            a: 0,
                                            k: {
                                                i: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                o: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                v: [
                                                    [92.5, -86.5],
                                                    [188.5, 13],
                                                    [87.5, 118],
                                                ],
                                                c: false,
                                            },
                                            ix: 2,
                                        },
                                        nm: "Path 1",
                                        mn: "ADBE Vector Shape - Group",
                                        hd: false,
                                    },
                                    {
                                        ty: "st",
                                        c: { a: 0, k: color, ix: 3 },
                                        o: { a: 0, k: 100, ix: 4 },
                                        w: { a: 0, k: 50, ix: 5 },
                                        lc: 2,
                                        lj: 2,
                                        bm: 0,
                                        nm: "Stroke 1",
                                        mn: "ADBE Vector Graphic - Stroke",
                                        hd: false,
                                    },
                                    {
                                        ty: "tr",
                                        p: { a: 0, k: [0, 0], ix: 2 },
                                        a: { a: 0, k: [0, 0], ix: 1 },
                                        s: { a: 0, k: [100, 100], ix: 3 },
                                        r: { a: 0, k: 0, ix: 6 },
                                        o: { a: 0, k: 100, ix: 7 },
                                        sk: { a: 0, k: 0, ix: 4 },
                                        sa: { a: 0, k: 0, ix: 5 },
                                        nm: "Transform",
                                    },
                                ],
                                nm: "Shape 1",
                                np: 2,
                                cix: 2,
                                bm: 0,
                                ix: 1,
                                mn: "ADBE Vector Group",
                                hd: false,
                            },
                        ],
                        ip: 0,
                        op: 1503,
                        st: 0,
                        bm: 0,
                    },
                    {
                        ddd: 0,
                        ind: 3,
                        ty: 4,
                        nm: "Shape Layer 1",
                        sr: 1,
                        ks: {
                            o: { a: 0, k: 100, ix: 11 },
                            r: { a: 0, k: 0, ix: 10 },
                            p: {
                                s: true,
                                x: {
                                    a: 1,
                                    k: [
                                        {
                                            i: { x: [0.508], y: [1] },
                                            o: { x: [0.477], y: [0] },
                                            t: 4,
                                            s: [313],
                                        },
                                        {
                                            i: { x: [0.308], y: [1] },
                                            o: { x: [0.342], y: [0] },
                                            t: 27,
                                            s: [385],
                                        },
                                        { t: 50, s: [313] },
                                    ],
                                    ix: 3,
                                },
                                y: { a: 0, k: 313, ix: 4 },
                            },
                            a: { a: 0, k: [0, 0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100, 100], ix: 6 },
                        },
                        ao: 0,
                        shapes: [
                            {
                                ty: "gr",
                                it: [
                                    {
                                        ind: 0,
                                        ty: "sh",
                                        ix: 1,
                                        ks: {
                                            a: 0,
                                            k: {
                                                i: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                o: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                v: [
                                                    [92.5, -86.5],
                                                    [188.5, 13],
                                                    [87.5, 118],
                                                ],
                                                c: false,
                                            },
                                            ix: 2,
                                        },
                                        nm: "Path 1",
                                        mn: "ADBE Vector Shape - Group",
                                        hd: false,
                                    },
                                    {
                                        ty: "st",
                                        c: { a: 0, k: color, ix: 3 },
                                        o: { a: 0, k: 100, ix: 4 },
                                        w: { a: 0, k: 50, ix: 5 },
                                        lc: 2,
                                        lj: 2,
                                        bm: 0,
                                        nm: "Stroke 1",
                                        mn: "ADBE Vector Graphic - Stroke",
                                        hd: false,
                                    },
                                    {
                                        ty: "tr",
                                        p: { a: 0, k: [0, 0], ix: 2 },
                                        a: { a: 0, k: [0, 0], ix: 1 },
                                        s: { a: 0, k: [100, 100], ix: 3 },
                                        r: { a: 0, k: 0, ix: 6 },
                                        o: { a: 0, k: 100, ix: 7 },
                                        sk: { a: 0, k: 0, ix: 4 },
                                        sa: { a: 0, k: 0, ix: 5 },
                                        nm: "Transform",
                                    },
                                ],
                                nm: "Shape 1",
                                np: 2,
                                cix: 2,
                                bm: 0,
                                ix: 1,
                                mn: "ADBE Vector Group",
                                hd: false,
                            },
                        ],
                        ip: 0,
                        op: 1503,
                        st: 0,
                        bm: 0,
                    },
                ],
            },
        ],
        layers: [
            {
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "Arrow 1 (Stroke) Outlines",
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [41, 39, 0], ix: 2 },
                    a: { a: 0, k: [35, 15, 0], ix: 1 },
                    s: { a: 0, k: [-100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0.904, 0.904],
                                            [0, 0],
                                            [-0.904, 0.904],
                                            [0, 0],
                                            [-0.904, -0.904],
                                            [0.905, -0.904],
                                            [0, 0],
                                            [0, 0],
                                            [0, -1.279],
                                            [1.279, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0.904, -0.904],
                                        ],
                                        o: [
                                            [0, 0],
                                            [-0.904, -0.904],
                                            [0, 0],
                                            [0.904, -0.904],
                                            [0.905, 0.904],
                                            [0, 0],
                                            [0, 0],
                                            [1.279, 0],
                                            [0, 1.279],
                                            [0, 0],
                                            [0, 0],
                                            [0.904, 0.904],
                                            [-0.904, 0.904],
                                        ],
                                        v: [
                                            [-22.591, 13.935],
                                            [-34.051, 1.847],
                                            [-34.051, -1.427],
                                            [-22.591, -14.161],
                                            [-19.317, -14.161],
                                            [-19.317, -10.887],
                                            [-26.824, -2.105],
                                            [32.412, -2.105],
                                            [34.728, 0.21],
                                            [32.412, 2.525],
                                            [-26.824, 2.525],
                                            [-19.317, 10.661],
                                            [-19.317, 13.935],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 1",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: color, ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill 1",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [34.728, 14.839], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group 1",
                        np: 4,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 56,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 2,
                ty: 0,
                nm: "Arrows",
                refId: "comp_0",
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [99.31, 38.5, 0], ix: 2 },
                    a: { a: 0, k: [313, 313, 0], ix: 1 },
                    s: { a: 0, k: [13, 13, 100], ix: 6 },
                },
                ao: 0,
                w: 626,
                h: 626,
                ip: 0,
                op: 56,
                st: 0,
                bm: 0,
            },
        ],
        markers: [],
    };
};

export default ArrowsRight;
