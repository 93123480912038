const Check = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24"
                height="24"
                viewBox="0,0,256,256"
            >
                <g
                    className={`fill-primary-active dark:fill-secondary-active`}
                    fillRule="nonzero"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                >
                    <g transform="scale(10.66667,10.66667)">
                        <path d="M20.29297,5.29297l-11.29297,11.29297l-4.29297,-4.29297l-1.41406,1.41406l5.70703,5.70703l12.70703,-12.70703z"></path>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default Check;
