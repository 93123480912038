import { useEffect, useMemo, useState } from "react";
import { WithBackNavigation } from "../../components";
import { useData } from "../../context/data";
import { useMultilangue } from "../../context/multilangue";
import { useNextPage } from "../../hooks";
import { manageCountryCode } from "../SelectDocument/helper";
import SearchWrapper from "./SearchWrapper";
import StatesRenderer from "./StatesRenderer";
import CountriesRenderer from "./CountriesRenderer";

const SelectCountry = (): JSX.Element => {
  const { prev } = useNextPage();
  const {
    data: {
      scopes,
      data: { documentCountry },
    },
  } = useData();

  const isUSSelected = Boolean(
    documentCountry && documentCountry?.startsWith("us-")
  );

  const [countryCode, setCountryCode] = useState(
    manageCountryCode(documentCountry || "")
  );

  const [search, setSearch] = useState("");

  const [displayStates, setDisplayStates] = useState(isUSSelected);
  const [resetValue, setResetValue] = useState(false);

  const [stateCode, setStateCode] = useState(documentCountry || "");

  const {
    t: {
      words: { select_country },
    },
  } = useMultilangue();

  useEffect(() => {
    if (resetValue) {
      setTimeout(() => {
        setResetValue(false);
      }, 1000);
    }
  }, [resetValue]);

  const scopesWithoutUsStates = useMemo(
    () => scopes.filter((scope) => !scope.country_code.startsWith("us-")),
    [scopes]
  );
  const scopesWithUsStates = useMemo(
    () => scopes.filter((scope) => scope.country_code.startsWith("us-")),
    [scopes]
  );

  return (
    <WithBackNavigation
      onClick={() => {
        if (displayStates) {
          if (search) {
            setSearch("");
          } else {
            setResetValue(true);
            setDisplayStates(false);
          }
        } else if (search) {
          setSearch("");
        } else {
          prev();
        }
      }}
    >
      {displayStates ? (
        <SearchWrapper
          title={select_country.select_state}
          placeholder={select_country.placeholder_state}
          selectedValue={stateCode}
          setValue={setStateCode}
          items={scopesWithUsStates.map((scope) => ({
            name: scope.country,
            code: scope.country_code,
          }))}
          resetValue={resetValue}
          search={search}
          setSearch={setSearch}
        >
          <StatesRenderer stateCode={stateCode} setStateCode={setStateCode} />
        </SearchWrapper>
      ) : (
        <SearchWrapper
          title={select_country.title}
          placeholder={select_country.placeholder_country}
          selectedValue={countryCode}
          setValue={setCountryCode}
          resetValue={resetValue}
          items={scopesWithoutUsStates.map((scope) => ({
            name: scope.country,
            code: scope.country_code,
          }))}
          search={search}
          setSearch={setSearch}
        >
          <CountriesRenderer
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            setDisplayStates={setDisplayStates}
          />
        </SearchWrapper>
      )}
    </WithBackNavigation>
  );
};

export default SelectCountry;
