import {
    ActionTypes,
    DataReducerAction,
    DataReducerState,
    Pages,
} from "../../types";

export const initialState: DataReducerState = {
    accessToken: "",
    id: "",
    correlationID: "",
    challengeOrders: [],
    scopes: [],
    devices: [],
    status: "checks",
    redirectUrl: "",
    data: {
        documentCountry: null,
        documentType: null,
        frontDocumentFile: null,
        pictureFrontDocument: null,
        streamFrontImg: null,
        backDocumentFile: null,
        pictureBackDocument: null,
        streamBackImg: null,
        livenessFiles: {
            applicantVideo1: null,
            applicantVideo2: null,
            applicantVideo3: null,
            applicantVideo4: null,
        },
    },
    language: "en",
    debug: false,
    onboardingMethod: null,
    onboardingSteps: [
        { page: Pages.LETS_VERIFY_PAGE, onboardingMethod: "all" },
        { page: Pages.SELECT_COUNTRY_PAGE, onboardingMethod: "document" },
        { page: Pages.SELECT_DOCUMENT_PAGE, onboardingMethod: "document" },
        { page: Pages.CHECK_PHONE_SETTINGS_PAGE, onboardingMethod: "all" },
        { page: Pages.SCAN_DOCUMENT_PAGE, onboardingMethod: "document" },
        { page: Pages.SCAN_LIVENESS_PAGE, onboardingMethod: "liveness" },
        { page: Pages.MEDIA_SUMMARY_PAGE, onboardingMethod: "all" },
        { page: Pages.SEND_INFORMATION_PAGE, onboardingMethod: "all" },
        { page: Pages.FINAL_PAGE, onboardingMethod: "all" },
    ],
    desktopTest: false,
    theme: {
        colors: {
            primary: {
                active: "#3253d1",
                hover: "#2d4bbd",
                disabled: "#bbc9ff",
                bg: "#ffffff",
            },
            secondary: {
                active: "#3253d1",
                hover: "#2d4bbd",
                disabled: "#bbc9ff",
                bg: "#0f172a",
            },
        },
        text: {
            title: {
                size: "1.5rem",
                lineHeight: "2rem",
                weight: "700",
                primaryColor: "black",
                secondaryColor: "white",
            },
            subtitle: {
                size: "1rem",
                lineHeight: "1.5rem",
                weight: "400",
                primaryColor: "black",
                secondaryColor: "white",
            },
            paragraph: {
                size: "0.75rem",
                lineHeight: "1rem",
                weight: "400",
                primaryColor: "black",
                secondaryColor: "white",
            },
        },
    },
};

export const reducer = (
    state: DataReducerState,
    action: DataReducerAction
): DataReducerState => {
    switch (action.type) {
        case ActionTypes.INITIALIZATION: {
            return {
                ...state,
                ...action.payload,
            };
        }

        case ActionTypes.CLEAR_DOCUMENTS: {
            return {
                ...state,
                data: {
                    documentCountry: null,
                    documentType: null,
                    frontDocumentFile: null,
                    pictureFrontDocument: null,
                    streamFrontImg: null,
                    backDocumentFile: null,
                    pictureBackDocument: null,
                    streamBackImg: null,
                    livenessFiles: {
                        applicantVideo1: null,
                        applicantVideo2: null,
                        applicantVideo3: null,
                        applicantVideo4: null,
                    },
                },
            };
        }

        case ActionTypes.UPDATE_STATUS: {
            return {
                ...state,
                status: action.payload.status,
            };
        }

        case ActionTypes.UPDATE_DOCUMENT_TYPE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        }

        case ActionTypes.UPDATE_DOCUMENT_COUNTRY: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        }

        case ActionTypes.ADD_FRONT_DOCUMENT: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        }

        case ActionTypes.ADD_BACK_DOCUMENT: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        }

        case ActionTypes.ADD_STREAM_IMAGE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.key]: action.payload.data,
                },
            };
        }

        case ActionTypes.ADD_LIVENESS_VIDEO: {
            return {
                ...state,
                data: {
                    ...state.data,
                    livenessFiles: {
                        ...state.data.livenessFiles,
                        [`applicantVideo${action.payload.index + 1}`]:
                            action.payload.file,
                    },
                },
            };
        }

        case ActionTypes.ADD_DEVICES: {
            return {
                ...state,
                devices: action.payload.devices,
            };
        }

        default:
            return state;
    }
};
