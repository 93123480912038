import { Button, Container } from "../../components";
import { ShineSuccess } from "../../icons";
import { useMultilangue } from "../../context/multilangue";
import { formatTexts } from "../../utils";
import { useData } from "../../context/data";

const FinalPage = () => {
    const {
        t: {
            words: {
                final_page: {
                    thanks,
                    success_submit,
                    back_to_website,
                    verif_delay,
                    notification,
                },
            },
        },
    } = useMultilangue();
    const {
        data: { redirectUrl },
    } = useData();

    return (
        <Container className={"h-full"}>
            <div className="flex-center flex-col gap-2 text-center h-1/5">
                <h1 className="text-title text-primary-active">{thanks}</h1>
                <p className="text-subtitle">{success_submit}</p>
            </div>
            <div className="flex-center h-2/5">
                <ShineSuccess />
            </div>
            <div className="flex-center text-center h-[30%] flex-col gap-2">
                <p className="text-subtitle">{formatTexts(verif_delay)}</p>
                <p className="text-subtitle">{formatTexts(notification)}</p>
            </div>
            <Button
                text={back_to_website}
                active={Boolean(redirectUrl)}
                onClick={() =>
                    redirectUrl && window.location.replace(redirectUrl)
                }
            />
        </Container>
    );
};

export default FinalPage;
