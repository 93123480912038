import log from "../log";

const parseJwt = (token: string, debug: boolean) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        log("Can't decode JWT token", "red", debug);
        return null;
    }
};

export default parseJwt;
