import React from "react";
import { useMultilangue } from "../../context/multilangue";
import { Search } from "../../icons";

const InputSearch = ({
    value,
    updateValue,
    placeholder,
}: {
    value: string;
    updateValue: React.Dispatch<React.SetStateAction<string>>;
    placeholder: string;
}) => {
    const {
        t: {
            words: { common },
        },
    } = useMultilangue();

    return (
        <div className="flex-center gap-2 h-[10%] w-full md:w-1/2">
            <div className="flex gap-3 p-3 ring-1 ring-gray-300 rounded-md w-full h-12">
                <Search />
                <input
                    type="text"
                    value={value}
                    onChange={(e) => updateValue(e.target.value)}
                    placeholder={placeholder}
                    className="focus:outline-none w-full h-full text-subtitle placeholder:text-subtitle bg-primary-bg dark:bg-secondary-bg"
                />
            </div>
            {value && (
                <div className="flex-center h-full w-20">
                    <button
                        className="text-subtitle"
                        onClick={() => updateValue("")}
                    >
                        {common.cancel}
                    </button>
                </div>
            )}
        </div>
    );
};

export default InputSearch;
