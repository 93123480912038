import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useData } from "../../context/data";
import { useRouter } from "../../context/router";
import useInterval from "../useInterval";
import config from "../../config.json";
import { getScreenshot, queryErrorHandling } from "../../utils";
import {
    ChallengeOrdersType,
    LivenessRequestDetectionType,
    RequestResponseType,
    RequestReturnType,
    UseLivenessDetectionType,
} from "../../types";
import useLog, { LogLevels } from "../useLog";

/**
 * @remarks
 * The purpose of this method is to make a POST request to a url of our backend
 * which will be defined according to the challenge.
 *
 * @param data - The data that will be sent to the backend.
 * @param accessToken - Allow access to a protected resource.
 * @param goto - Send the user to the error page if an error was detected during the request.
 * @param challenge - One of the 4 challenges that the user will have to perform in front of his webcam (blink, smile, right, left).
 * @param setCode - Store the code returned by the AI service.
 * @param setStep - To redirects to step 5 if the timeout is exceeded.
 * @param dispatch - A function to dispatch data state actions.
 * @param correlationID - The ID that must be passed in the header of each request.
 * @param logRequest - Function that performs a query to log certain information.
 * @returns an object with "user_has_blinked" which is a boolean or "turn" which is a string.
 *
 */
const requestDetection = async ({
    data,
    accessToken,
    goto,
    challenge,
    setCode,
    setStep,
    dispatch,
    correlationID,
    logRequest,
}: LivenessRequestDetectionType): Promise<RequestReturnType> => {
    const isBlink = challenge === ChallengeOrdersType.BLINK;
    const isRight = challenge === ChallengeOrdersType.RIGHT;
    const isLeft = challenge === ChallengeOrdersType.LEFT;
    const isSmile = challenge === ChallengeOrdersType.SMILE;
    const urls: Record<ChallengeOrdersType, string> = {
        blink: "/stream/blink",
        left: "/stream/turn",
        right: "/stream/turn",
        smile: "/stream/smile",
    };
    const url = urls[challenge];

    // Initialize a variable to track whether the request has completed
    let requestCompleted = false;

    // Set a timeout to return code 5 after 1 second
    setTimeout(() => {
        if (!requestCompleted) {
            // If the request is still pending after 1 second, return code 5
            setCode && setCode(7);
        }
    }, 1000);

    try {
        const result: AxiosResponse<RequestResponseType> = await axios.request({
            baseURL: config.VAR_SHAREID_API_URL,
            url,
            headers: {
                "content-type": "application/json",
                accept: "application/json",
                // "X-CID": correlationID,
                Authorization: `Bearer ${accessToken}`,
            },
            method: "post",
            timeout: 10000,
            data,
        });

        // Mark the request as completed
        requestCompleted = true;

        setCode && setCode(result.data?.code || 0);

        if (isBlink) return { response: Boolean(result.data.user_has_blinked) };
        if (isRight) return { response: result.data?.turn === "head_right" };
        if (isLeft) return { response: result.data?.turn === "head_left" };
        if (isSmile) return { response: Boolean(result.data.user_has_smiled) };
        return { response: false };
    } catch (error: any) {
        if (error.code === "ECONNABORTED") {
            setStep(5);
            logRequest({
                level: LogLevels.WARNING,
                payload: {
                    message:
                        "The user is experiencing connection issues that prevent him from completing the process correctly.",
                },
            });
        } else {
            queryErrorHandling(error, goto, dispatch);
        }
        return {
            response: false,
        };
    }
};

/**
 * @remarks
 * A hooks that will allow to perform a request at a regular interval if certain conditions are met.
 *
 * @param webcamRef - Ref to the user's webcam.
 * @param hasTimeout - A boolean that indicates whether the timeout is exceeded or not.
 * @param delay - The time that must be respected between each request.
 * @param challenge - One of the 4 challenges that the user will have to perform in front of his webcam (blink, smile, right, left).
 * @returns a boolean indicating whether the AI service detected that the challenge was successful or not.
 *
 */
const useLivenessDetection = ({
    webcamRef,
    hasTimeout,
    delay,
    challenge,
    setCode,
    setStep,
}: UseLivenessDetectionType): boolean => {
    const { logRequest } = useLog();
    const [detected, setDetected] = useState<boolean>(false);
    const [hasResponse, setHasResponse] = useState<boolean>(true);
    const {
        data: { accessToken, desktopTest, correlationID },
        dispatch,
    } = useData();
    const { goto } = useRouter();

    useEffect(() => {
        if (desktopTest) {
            setTimeout(() => {
                setDetected(true);
            }, 5000);
        }
    }, []);

    useEffect(() => {
        if (hasTimeout) setDetected(false);
    }, [hasTimeout]);

    /*
        !hasTimeout = the timer has not yet expired
        hasResponse = wait for a response from the previous request to make a new one, and if we get a positive response, we leave the value at false to stop the request process
        webcamRef.current = check that the webcam is started
    */
    useInterval(async () => {
        if (!hasTimeout && hasResponse && webcamRef?.current && !desktopTest) {
            const streamImg = await getScreenshot({
                webcamRef,
                resolution: window.mobileAndTabletCheck()
                    ? { w: 270, h: 480 }
                    : { w: 480, h: 270 },
                imageQuality: 0.9,
                imageType: "image/jpeg",
                mirrored: true,
            });
            if (streamImg) {
                const image = streamImg.split(",")[1];
                setHasResponse(false);
                requestDetection({
                    data: { image },
                    accessToken,
                    goto,
                    challenge,
                    setCode,
                    setStep,
                    dispatch,
                    correlationID,
                    logRequest,
                }).then(({ response }) => {
                    setHasResponse(!response);
                    !hasTimeout && setDetected(response);
                });
            } else {
                setHasResponse(true);
            }
        }
    }, delay);

    return detected;
};

export default useLivenessDetection;
