const LightBulb = () => (
    <div>
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.3642 9.08411C21.8282 9.08411 24.0667 10.1148 25.6933 11.8058C27.3037 13.4807 28.3022 15.7836 28.3022 18.3281C28.3022 21.549 27.2876 22.7569 26.2086 24.0452C25.0974 25.3658 23.8896 26.8152 23.8896 31.4211C23.8896 31.7593 23.6158 32.017 23.2937 32.017H15.4508C15.1126 32.017 14.8549 31.7432 14.8549 31.4211C14.8549 26.8152 13.6471 25.3658 12.5359 24.0452C11.4568 22.7569 10.4423 21.5651 10.4423 18.3281C10.4423 15.7836 11.4407 13.4807 13.0512 11.8058C14.6617 10.1148 16.9002 9.08411 19.3642 9.08411Z"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5142 35.0623H23.1961H15.5142V35.0623Z"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.1542 38.0383L20.8638 38.0383"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.28524 18.3123H4.06433"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.8281 9.7445L8.55737 7.47375"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.3955 6.2015V2.98059"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.9639 9.7445L30.2346 7.47375"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.5063 18.3123H34.7273"
                className="stroke-primary-active dark:stroke-secondary-active"
                strokeWidth="1.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </div>
);

export default LightBulb;
