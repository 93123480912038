const PassportInHand = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                viewBox="0 0 30 30"
            >
                <g>
                    <g>
                        <path
                            className="fill-secondary-bg dark:fill-primary-bg"
                            fillRule="evenodd"
                            d="M17.236 16.054c0 .572.463 1.035 1.035 1.035h1.797c.572 0 1.035-.463 1.035-1.035v-1.797c0-.571-.463-1.035-1.035-1.035h-1.797c-.572 0-1.035.464-1.035 1.035v1.797Zm1.695-.936a.571.571 0 1 0-1.143 0 .571.571 0 0 0 1.143 0Zm.114 0a1.144 1.144 0 0 0 1.142 1.142.114.114 0 0 0 .115-.114V14.09a.115.115 0 0 0-.114-.114 1.144 1.144 0 0 0-1.143 1.143Zm.058-2.67a.32.32 0 0 1-.32-.32V8.532a.32.32 0 1 1 .64 0v3.598a.32.32 0 0 1-.32.32Zm.64-.32a.32.32 0 0 0 .64 0V6.612a.32.32 0 1 0-.64 0v5.518Z"
                            clipRule="evenodd"
                        />
                        <path
                            className="fill-secondary-bg dark:fill-primary-bg"
                            d="m22.445 6.733.49-.442h.19l-.578.494h-.122l.02-.052Zm-.437-.442.496.443.016.051h-.123l-.58-.494h.191Zm.437 1.995.49-.442h.19l-.578.494h-.122l.02-.052Zm-.437-.442.496.443.016.051h-.123l-.58-.494h.191Zm.437 1.996.49-.443h.19l-.578.494h-.122l.02-.052Zm-.437-.443.496.444.016.05h-.123l-.58-.494h.191Zm.437 1.999.49-.442h.19l-.578.494h-.122l.02-.052Zm-.437-.442.496.444.016.05h-.123l-.58-.494h.191Zm.437 1.996.49-.443h.19l-.578.494h-.122l.02-.052Zm-.437-.443.496.444.016.05h-.123l-.58-.494h.191Zm.437 1.995.49-.442h.19l-.578.494h-.122l.02-.052Zm-.437-.442.496.444.016.05h-.123l-.58-.494h.191Zm.437 1.995.49-.441h.19l-.578.494h-.122l.02-.053Zm-.437-.441.496.443.016.05h-.123l-.58-.493h.191Z"
                        />
                        <path
                            className="fill-secondary-bg dark:fill-primary-bg"
                            fillRule="evenodd"
                            d="M23.411 10.85a.304.304 0 0 1-.088.336l-.578.494a.304.304 0 0 1-.198.072h-.15a.304.304 0 0 1-.197-.072l-.58-.494a.304.304 0 0 1 .197-.536h.191c.075 0 .147.028.203.078l.262.234.258-.233a.304.304 0 0 1 .204-.079h.19c.128 0 .242.08.286.2Zm0 1.552a.304.304 0 0 1-.088.337l-.578.494a.304.304 0 0 1-.198.072h-.15a.304.304 0 0 1-.197-.072l-.58-.494a.304.304 0 0 1 .197-.536h.191c.075 0 .147.028.203.078l.262.234.258-.233a.304.304 0 0 1 .204-.079h.19c.128 0 .242.08.286.2Zm0 1.553a.304.304 0 0 1-.088.337l-.578.494a.304.304 0 0 1-.198.072h-.15a.304.304 0 0 1-.197-.072l-.58-.494a.304.304 0 0 1 .197-.535h.191c.075 0 .147.027.203.077l.262.234.258-.233a.304.304 0 0 1 .204-.079h.19c.128 0 .242.08.286.2Zm0 1.553a.304.304 0 0 1-.088.337l-.578.494a.304.304 0 0 1-.198.073h-.15a.304.304 0 0 1-.197-.073l-.58-.494a.304.304 0 0 1 .197-.535h.191c.075 0 .147.027.203.077l.262.234.258-.233a.304.304 0 0 1 .204-.078h.19c.128 0 .242.079.286.198Zm0-9.322c.044.12.01.254-.088.336l-.578.494a.304.304 0 0 1-.198.073h-.15a.304.304 0 0 1-.197-.072l-.58-.494a.304.304 0 0 1 .197-.536h.191c.075 0 .147.028.203.078l.262.234.258-.233a.304.304 0 0 1 .203-.079h.192c.127 0 .241.08.285.2Zm0 1.553c.044.12.01.254-.088.337l-.578.494a.304.304 0 0 1-.198.072h-.15a.304.304 0 0 1-.197-.072l-.58-.494a.304.304 0 0 1 .197-.536h.191c.075 0 .147.028.203.078l.262.234.258-.233a.304.304 0 0 1 .203-.079h.192c.127 0 .241.08.285.2Zm0 1.553c.044.12.01.254-.088.337l-.578.494a.304.304 0 0 1-.198.072h-.15a.304.304 0 0 1-.197-.072l-.58-.494a.304.304 0 0 1 .197-.536h.191c.075 0 .147.028.203.078l.262.234.258-.233a.304.304 0 0 1 .203-.079h.192c.127 0 .241.08.285.2Z"
                            clipRule="evenodd"
                        />
                        <path
                            className="fill-secondary-bg dark:fill-primary-bg"
                            d="M13.232 18.716c.028.543.206 1.079-.223 1.514-.42.424-.94.354-1.486.28.032.367.045.716.096 1.06.196 1.335-.152 2.53-.929 3.608-.248.345-.385.695-.37 1.123.014.406-.27.637-.598.495-.122-.053-.263-.208-.279-.331-.074-.584.02-1.138.387-1.625.448-.599.825-1.244.893-1.997.049-.538 0-1.089-.05-1.63-.076-.85-.187-1.696-.286-2.543-.07-.604.103-1.165.292-1.726.159-.472.382-.93.346-1.45a1.627 1.627 0 0 0-.605-1.186c-.18-.15-.371-.13-.466.07-.426.895-.886 1.779-1.236 2.703-.175.46-.192 1-.192 1.506 0 1.184-.276 2.278-.917 3.277-.198.31-.419.406-.669.267-.247-.136-.292-.42-.1-.732.357-.583.634-1.198.72-1.88.038-.306.044-.615.042-.923 0-.077-.066-.18-.132-.229-.536-.39-.768-.92-.766-1.57v-1.206c-.705.75-1.394 1.418-1.712 2.334-.119.342-.084.746-.074 1.121.053 2.108.354 4.183.843 6.235.09.384.038.807.025 1.21-.007.215-.179.318-.377.341-.199.023-.365-.052-.456-.241a.423.423 0 0 1-.049-.204c.078-.85-.21-1.65-.36-2.466A32.428 32.428 0 0 1 4 18.329c-.004-.5.186-.944.434-1.364.533-.905 1.245-1.662 2.005-2.372a.779.779 0 0 0 .27-.639 1441.5 1441.5 0 0 1-.005-8.008c.002-.933.484-1.625 1.304-1.858.2-.057.415-.084.624-.085C13.662 4 18.692 4 23.722 4c1.164 0 1.934.773 1.935 1.938.002 2.624.002 8.237 0 10.862 0 1.155-.765 1.915-1.923 1.915H13.232Zm-5.568-1.473c.022 0 .043-.002.064-.003.041-.153.061-.314.126-.454.448-.982.871-1.976 1.37-2.932.313-.602 1.127-.713 1.681-.316.886.637 1.242 1.81.885 2.926-.14.441-.287.882-.44 1.352h12.362c.686 0 1.044-.354 1.044-1.039V5.943c0-.674-.368-1.04-1.048-1.04H8.649c-.671 0-1.037.354-1.038 1.02a7418.35 7418.35 0 0 0 0 10.86c0 .153.034.306.052.459h.001Zm3.647 1.475c.012.13.022.218.028.305.04.488.175.618.666.64.07.002.154.021.209-.008.072-.04.171-.12.172-.182a4.258 4.258 0 0 0-.059-.656c-.005-.038-.081-.094-.126-.095-.289-.008-.577-.004-.89-.004Z"
                        />
                        <path
                            className="fill-secondary-bg dark:fill-primary-bg"
                            fillRule="evenodd"
                            d="M15.146 18.134V4.2h.697v13.933h-.697Z"
                            clipRule="evenodd"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default PassportInHand;
