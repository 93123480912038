const NoWifi = () => {
    return (
        <div>
            <svg
                width="74"
                height="54"
                viewBox="0 0 74 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_4905_25418)">
                    <path
                        d="M72.8005 17.7296L71.4548 19.0708C70.3797 20.1423 68.6503 20.1997 67.5139 19.1924C59.4002 11.9999 48.7111 7.63032 36.9988 7.63032C25.2864 7.63032 14.5977 11.9995 6.484 19.192C5.34761 20.1993 3.61828 20.1419 2.54312 19.07L1.19827 17.7292C0.0265874 16.5614 0.0870167 14.6547 1.32318 13.5549C10.7976 5.1238 23.2979 0 36.9992 0C50.7004 0 63.2003 5.1238 72.676 13.5549C73.9121 14.6547 73.9726 16.5618 72.8009 17.7296H72.8005Z"
                        fill="black"
                    />
                    <path
                        d="M61.9489 28.5451L60.6012 29.8883C59.5475 30.9385 57.8599 31.0181 56.7203 30.0609C51.3928 25.5859 44.512 22.8906 36.9993 22.8906C29.4866 22.8906 22.6061 25.5859 17.2786 30.0609C16.139 31.0181 14.451 30.9385 13.3974 29.8879L12.0505 28.5451C10.863 27.3612 10.9388 25.4173 12.2184 24.3328C18.896 18.6742 27.5476 15.2603 36.9989 15.2603C46.4502 15.2603 55.1029 18.6742 61.7805 24.3324C63.0605 25.4169 63.1363 27.3608 61.9484 28.5447L61.9489 28.5451Z"
                        fill="black"
                    />
                    <path
                        d="M51.058 39.399L49.6969 40.7555C48.6931 41.7559 47.113 41.8667 45.9629 41.0372C43.4435 39.2207 40.347 38.1507 36.9991 38.1507C33.6511 38.1507 30.5558 39.2211 28.0361 41.0372C26.8855 41.8667 25.305 41.7559 24.3012 40.7547L22.9405 39.3981C21.7011 38.1625 21.8483 36.1188 23.252 35.0719C27.0846 32.2133 31.8435 30.52 36.9991 30.52C42.1546 30.52 46.9135 32.2133 50.7469 35.0715C52.151 36.1184 52.2982 38.1625 51.0584 39.3981L51.058 39.399Z"
                        fill="black"
                    />
                    <path
                        d="M40.6591 52.5297C42.6261 50.5693 42.6261 47.3909 40.6591 45.4305C38.6921 43.4701 35.5031 43.4701 33.5361 45.4305C31.5691 47.3909 31.5691 50.5693 33.5361 52.5297C35.5031 54.4901 38.6921 54.4901 40.6591 52.5297Z"
                        fill="black"
                    />
                    <g clipPath="url(#clip1_4905_25418)">
                        <path
                            d="M52 34C61.3888 34 69 26.3888 69 17C69 7.61116 61.3888 0 52 0C42.6112 0 35 7.61116 35 17C35 26.3888 42.6112 34 52 34Z"
                            fill="#FF0000"
                        />
                        <path
                            d="M51.5256 20.7825C52.1672 20.7805 52.7846 20.5366 53.2544 20.0996C53.7242 19.6625 54.0119 19.0644 54.0603 18.4246L54.7981 8.28578C54.8213 7.93817 54.7731 7.5895 54.6563 7.26126C54.5396 6.93302 54.3568 6.63217 54.1193 6.3773C53.8818 6.12243 53.5946 5.91893 53.2754 5.77936C52.9562 5.63978 52.6118 5.56711 52.2634 5.5658H50.7861C50.4373 5.56587 50.0923 5.63769 49.7726 5.77684C49.4528 5.91599 49.165 6.11949 48.9273 6.37463C48.6895 6.62977 48.5068 6.93111 48.3905 7.25991C48.2743 7.5887 48.2269 7.93789 48.2514 8.28578L48.9892 18.4246C49.0376 19.0647 49.3256 19.663 49.7958 20.1001C50.2659 20.5372 50.8836 20.7809 51.5256 20.7825Z"
                            fill="white"
                        />
                        <path
                            d="M51.5275 27.1048C52.0463 27.1048 52.5535 26.951 52.9848 26.6628C53.4162 26.3745 53.7524 25.9649 53.9509 25.4855C54.1495 25.0062 54.2014 24.4788 54.1002 23.97C53.999 23.4611 53.7492 22.9938 53.3823 22.6269C53.0155 22.2601 52.5481 22.0103 52.0393 21.909C51.5304 21.8078 51.003 21.8598 50.5237 22.0583C50.0444 22.2568 49.6347 22.593 49.3465 23.0244C49.0583 23.4558 48.9044 23.9629 48.9044 24.4817C48.9053 25.1771 49.182 25.8438 49.6737 26.3355C50.1654 26.8273 50.8321 27.1039 51.5275 27.1048Z"
                            fill="white"
                        />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_4905_25418">
                        <rect
                            width="73.2857"
                            height="54"
                            fill="white"
                            transform="translate(0.357422)"
                        />
                    </clipPath>
                    <clipPath id="clip1_4905_25418">
                        <rect
                            width="34"
                            height="34"
                            fill="white"
                            transform="translate(35)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default NoWifi;
